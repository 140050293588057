import { Checkbox, Form, Input, Select, Slider } from "antd"
import { csSkillsSet, itSkillsSet, softSkillsSet } from "../../Utils"

export const Filters = ( {
	form,
	rsSkillsGroup,
	onChange,
	initialValues,
	filters,
} ) => {
	return <Form
		onValuesChange={ ( _v, allVals ) => onChange( allVals ) }
		size={ "small" }
		preserve
		form={ form }
		layout={ "horizontal" }
		name={ "trainings-filter-form" }
		labelCol={ { span: 7 } }
		wrapperCol={ { span: 15 } }
		labelWrap
		colon={ false }
		className={ "content-scroll" }
		initialValues={ initialValues }
	>
		<Form.Item name={ "countries" } label={ "Countries" }>
			<Select
				showSearch
				showArrow
				allowClear
				mode={ "multiple" }
				options={ filters.countries }
			/>
		</Form.Item>

		<Form.Item name={ "languages" } label={ "Languages" }>
			<Select
				showSearch
				showArrow
				allowClear
				mode={ "multiple" }
				options={ filters.languages }
			/>
		</Form.Item>

		<Form.Item name={ "organizers" } label={ "Organizers" }>
			<Select
				showSearch
				showArrow
				allowClear
				mode={ "multiple" }
				options={ filters.organizers }
			/>
		</Form.Item>

		<Form.Item name={ "typeFormats" } label={ "Types/Formats" }>
			<Select
				showSearch
				showArrow
				allowClear
				mode={ "multiple" }
				options={ filters.typeFormats }
			/>
		</Form.Item>

		<Form.Item name={ "contentTypes" } label={ "Content Types" }>
			<Select
				showSearch
				showArrow
				allowClear
				mode={ "multiple" }
				options={ filters.contentTypes }
			/>
		</Form.Item>

		<Form.Item name={ "canLeadToCertifications" } label={ "Can lead to certifications" }>
			<Select
				showSearch
				showArrow
				allowClear
				mode={ "multiple" }
				options={ filters.canLeadToCertifications }
			/>
		</Form.Item>

		<Form.Item name={ "includesExamsForCertifications" } label={ "Includes exams for certifications" }>
			<Select
				showSearch
				showArrow
				allowClear
				mode={ "multiple" }
				options={ filters.includesExamsForCertifications }
			/>
		</Form.Item>

		<Form.Item name={ "itSkillsGroup" } label={ "IT Skills" }>
			<Select
				showSearch
				showArrow
				allowClear
				mode={ "multiple" }
				optionFilterProp={ "label" }
				options={ rsSkillsGroup
					.sort( ( a, b ) => ( a.name > b.name ? 1 : b.name > a.name ? -1 : 0 ) )
					.filter( ( e ) => itSkillsSet.includes( e.id ) )
					.map( ( skill ) => ( { value: skill.id, label: skill.name } ) ) }
			/>
		</Form.Item>

		<Form.Item name={ "csSkillsGroup" } label={ "CS Skills" }>
			<Select
				showSearch
				showArrow
				allowClear
				mode={ "multiple" }
				optionFilterProp={ "label" }
				options={ rsSkillsGroup
					.sort( ( a, b ) => ( a.name > b.name ? 1 : b.name > a.name ? -1 : 0 ) )
					.filter( ( e ) => csSkillsSet.includes( e.id ) )
					.map( ( skill ) => ( { value: skill.id, label: skill.name } ) ) }
			/>
		</Form.Item>

		<Form.Item name={ "sfSkillsGroup" } label={ "Soft Skills" }>
			<Select
				showSearch
				showArrow
				allowClear
				mode={ "multiple" }
				optionFilterProp={ "label" }
				options={ rsSkillsGroup
					.sort( ( a, b ) => ( a.name > b.name ? 1 : b.name > a.name ? -1 : 0 ) )
					.filter( ( e ) => softSkillsSet.includes( e.id ) )
					.map( ( skill ) => ( { value: skill.id, label: skill.name } ) ) }
			/>
		</Form.Item>

		<Form.Item name={ "trainingFocus" } label={ "Training Focus" }>
			<Select
				showSearch
				showArrow
				allowClear
				mode={ "multiple" }
				optionFilterProp={ "label" }
				options={ [
					{ value: "IT", label: "IT" },
					{ value: "CS", label: "Cybersecurity" },
					{ value: "Soft", label: "Soft Skills" },
				] }
			/>
		</Form.Item>

		<Form.Item
			name={ "price" }
			label={ "Price" }
		>
			<Slider
				range
				tooltip={ { formatter: ( value ) => `${value} €` } }
				marks={ {
					[filters.priceMin]: {
						style: {
							left: 0,
							right: "unset",
							transform: "unset",
						},
						label: `${filters.priceMin} €`,
					},
					[filters.priceMax]: {
						style: {
							right: 0,
							left: "unset",
							transform: "unset",
						},
						label: `${filters.priceMax} €`,
					},
				} }
				min={ filters.priceMin }
				max={ filters.priceMax }
			/>
		</Form.Item>
		<Form.Item
			name={ "duration" }
			label={ "Duration" }
		>
			<Slider
				range
				tooltip={ { formatter: ( value ) => `${value} h` } }
				marks={ {
					[filters.durationMin]: {
						style: {
							left: 0,
							right: "unset",
							transform: "unset",
						},
						label: `${filters.durationMin} h`,
					},
					[filters.durationMax]: {
						style: {
							// color: "#f50",
							right: 0,
							left: "unset",
							transform: "unset",
						},
						label: `${filters.durationMax} h`,
					},
				} }
				min={ filters.durationMin }
				max={ filters.durationMax }
			/>
		</Form.Item>
		<Form.Item valuePropName={ "checked" } name={ "showOutdated" } label={ "Show outdated trainings" }>
			<Checkbox />
		</Form.Item>
		<Form.Item valuePropName={ "checked" } name={ "disableSuggestions" } label={ "Disable suggestions" }>
			<Checkbox />
		</Form.Item>
		<Form.Item name={ "id" } label={ "Id" } hidden>
			<Input />
		</Form.Item>
	</Form>
}
