// Transform title into a key title pair
// const title = 'Chief Information Security Officer (CISO)';

const title = 'Chief Information Security Officer (CISO)';

// Transform alternativeTitles into a key title pair

// const alternativeTitles = [
//     'Cybersecurity Programme Director',
//     'Information Security Officer (ISO)',
//     'Information Security Manager',
//     'Head of Information Security',
//     'IT/ICT Security Officer'
// ];

const alternativeTitles = [
    {
        key: 1, title: 'Cybersecurity Programme Director'
    },
    {
        key: 2, title: 'Information Security Officer (ISO)'
    },
    {
        key: 3, title: 'Information Security Manager'
    },
    {
        key: 4, title: 'Head of Information Security'
    },
    {
        key: 5, title: 'IT/ICT Security Officer'
    }
];

// const summaryStatement = [
//     'Manages an organisation’s cybersecurity strategy and its ' +
//     'implementation to ensure that digital systems, services and assets ' +
//     'are adequately secure and protected.'
// ];

// const summaryStatement = [
//     { key: 1, summary: 'Manages an organisation’s cybersecurity strategy and its implementation to ensure that digital systems, services and assets are adequately secure and protected.'}
// ];

const summaryStatement = [{ key: 1, summary: 'Manages an organisation’s cybersecurity strategy and its implementation to ensure that digital systems, services and assets are adequately secure and protected.'}];

// Same for mission and deliverables
//
// const mission = [
//     'Defines, maintains and communicates the cybersecurity vision, strategy, policies and procedures. ',
//     'Manages the implementation of the cybersecurity policy across the organisation. Assures information exchange with external authorities and professional bodies.'
// ];
//
// const deliverables = [
//     'Cybersecurity strategy',
//     'Cybersecurity policy'
// ];

const mission = [
    { key : 1, mission: 'Defines, maintains and communicates the cybersecurity vision, strategy, policies and procedures.'},
    { key : 2, mission: 'Manages the implementation of the cybersecurity policy across the organisation. Assures information exchange with external authorities and professional bodies.'}
]

const deliverables = [
    { key : 1, deliverable: 'Cybersecurity strategy', description: 'Cybersecurity Strategy is a plan of actions designed to improve the security and resilience of an organisation’s infrastructures and services.'},
    { key : 2, deliverable: 'Cybersecurity policy', description: 'A policy listing rules to ensure the organisation’s cybersecurity.'}
]



const mainTasks = [
    {
        key: 1,
        task: 'Define, implement, communicate and maintain cybersecurity goals, requirements, strategies, policies, aligned with the business strategy to support the organisational objectives'
    },
    {
        key: 2,
        task: 'Prepare and present cybersecurity vision, strategies and policies for approval by the senior management of the organisation and ensure their execution'
    },
    {
        key: 3,
        task: 'Supervise the application and improvement of the Information Security Management System (ISMS)'
    },
    {
        key: 4,
        task: 'Educate senior management about cybersecurity risks, threats and their impact to the organisation'
    },
    {
        key: 5,
        task: 'Ensure the senior management approves the cybersecurity risks of the organisation'
    },
    {
        key: 6,
        task: 'Develop cybersecurity plans'
    },
    {
        key: 7,
        task: 'Develop relationships with cybersecurity-related authorities and communities'
    },
    {
        key: 8,
        task: 'Report cybersecurity incidents, risks, findings to the senior management'
    },
    {
        key: 9,
        task: 'Monitor advancement in cybersecurity'
    },
    {
        key: 10,
        task: 'Secure resources to implement the cybersecurity strategy'
    },
    {
        key: 11,
        task: 'Negotiate the cybersecurity budget with the senior management'
    },
    {
        key: 12,
        task: 'Ensure the organisation’s resiliency to cyber incidents'
    },
    {
        key: 13,
        task: 'Manage continuous capacity building within the organisation'
    },
    {
        key: 14,
        task: 'Review, plan and allocate appropriate cybersecurity resources'
    }
];

const keySkills = [
    {
        key: 1,
        skill: 'Assess and enhance an organisation’s cybersecurity posture'
    },
    {
        key: 2,
        skill: 'Analyse and implement cybersecurity policies, certifications, standards, methodologies and frameworks'
    },
    {
        key: 3,
        skill: 'Analyse and comply with cybersecurity-related laws, regulations and legislations'
    },
    {
        key: 4,
        skill: 'Implement cybersecurity recommendations and best practices'
    },
    {
        key: 5,
        skill: 'Manage cybersecurity resources'
    },
    {
        key: 6,
        skill: 'Develop, champion and lead the execution of a cybersecurity strategy'
    },
    {
        key: 7,
        skill: 'Influence an organisation’s cybersecurity culture'
    },
    {
        key: 8,
        skill: 'Design, apply, monitor and review Information Security Management System (ISMS) either directly or by leading its outsourcing'
    },
    {
        key: 9,
        skill: 'Review and enhance security documents, reports, SLAs and ensure the security objectives'
    },
    {
        key: 10,
        skill: 'Identify and solve cybersecurity-related issues'
    },
    {
        key: 11,
        skill: 'Establish a cybersecurity plan'
    },
    {
        key: 12,
        skill: 'Communicate, coordinate and cooperate with internal and external stakeholders'
    },
    {
        key: 13,
        skill: 'Anticipate required changes to the organisation’s information security strategy and formulate new plans'
    },
    {
        key: 14,
        skill: 'Define and apply maturity models for cybersecurity management'
    },
    {
        key: 15,
        skill: 'Anticipate cybersecurity threats, needs and upcoming challenges'
    },
    {
        key: 16,
        skill: 'Motivate and encourage people'
    }
];

const keyKnowledge = [
    {
        key: 1,
        knowledge: 'Cybersecurity policies'
    },
    {
        key: 2,
        knowledge: 'Cybersecurity standards, methodologies and frameworks'
    },
    {
        key: 3,
        knowledge: 'Cybersecurity recommendations and best practices'
    },
    {
        key: 4,
        knowledge: 'Cybersecurity related laws, regulations and legislations'
    },
    {
        key: 5,
        knowledge: 'Cybersecurity-related certifications'
    },
    {
        key: 6,
        knowledge: 'Ethical cybersecurity organisation requirements'
    },
    {
        key: 7,
        knowledge: 'Cybersecurity maturity models'
    },
    {
        key: 8,
        knowledge: 'Cybersecurity procedures'
    },
    {
        key: 9,
        knowledge: 'Resource management'
    },
    {
        key: 10,
        knowledge: 'Management practices'
    },
    {
        key: 11,
        knowledge: 'Risk management standards, methodologies and frameworks'
    }
];

const eCompetences = [
    {
        key: '1',
        competence: 'A.7. Technology Trend Monitoring',
        level: ['Level 4']
    },
    {
        key: '2',
        competence: 'D.1. Information Security Strategy Development',
        level: ['Level 5']
    },
    {
        key: '3',
        competence: 'E.3. Risk Management',
        level: ['Level 4']
    },
    {
        key: '4',
        competence: 'E.8. Information Security Management',
        level: ['Level 4']
    },
    {
        key: '5',
        competence: 'E.9. Information Systems Governance',
        level: ['Level 5']
    }
];


export const CISOData = {
    title,
    alternativeTitles,
    summaryStatement,
    mission,
    deliverables,
    mainTasks,
    keySkills,
    keyKnowledge,
    eCompetences
};