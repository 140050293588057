import React from "react";
import { Config } from "../config_section.js";
import Axios from "axios";
import { Button, Form, Input, Modal, Space, Typography } from "antd";
import "../Admin.scss";

const AddEditSkillFrameworkModalForm = ({ editRecord, refresh, onCancel, showMessage, authData, rsSkillsGroup, rsProfiles, usersData, rsSpecificSkills, rsSpecificKnowledge }) => {
  const [modalLoading, setModalLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [isNew, setIsNew] = React.useState(false);
  const [previewColor, setPreviewColor] = React.useState("");
  const [previewSpecificSkills, setPreviewSpecificSkills] = React.useState([]);
  const [previewSpecificKnowledge, setPreviewSpecificKnowledge] = React.useState([]);
  const { Text } = Typography;

  React.useEffect(() => {
    if (typeof editRecord === "object" && editRecord !== null && form.getFieldValue("id") !== editRecord.id) {
      let initialData = JSON.parse(JSON.stringify(editRecord));
      delete initialData.key;
      form.setFieldsValue(initialData);
      setIsNew(false);
      setPreviewColor(editRecord.color);
      setPreviewSpecificSkills(
        editRecord.specific_skills.map((skillId) => {
          return { ...rsSpecificSkills.find((i) => i.id === skillId), action: "none" };
        })
      );
      setPreviewSpecificKnowledge(
        editRecord.specific_knowledge.map((knowledgeId) => {
          return { ...rsSpecificKnowledge.find((i) => i.id === knowledgeId), action: "none" };
        })
      );
    } else if (editRecord === "new") {
      setIsNew(true);
      setPreviewColor("");
      setPreviewSpecificKnowledge([]);
      setPreviewSpecificSkills([]);
    }
  }, [editRecord, form, rsSpecificSkills, rsSpecificKnowledge]);

  React.useEffect(() => {
    form.setFieldsValue({ specific_knowledge: previewSpecificKnowledge });
  }, [previewSpecificKnowledge, form]);
  React.useEffect(() => {
    form.setFieldsValue({ specific_skills: previewSpecificSkills });
  }, [previewSpecificSkills, form]);

  function getUserName(id) {
    let allUsers = [...usersData.newUsers, ...usersData.adminUsers, ...usersData.blockedUsers, ...usersData.registeredUsers];
    let user = allUsers.find((u) => u.id === id);
    let output = user.admin_level > 1 ? "ADMIN " : "";
    output += user.name + " (ID " + user.id + ")";
    return output;
  }
  function nameExists(value) {
    return rsSkillsGroup.find((e) => e.name === value) ? true : false;
  }
  const handleColorChange = (e) => {
    const selectedColor = e.target.value;
    setPreviewColor(selectedColor);
    form.setFieldsValue({ color: selectedColor });
  };

  const addNewPreviewSpecificKnowledge = () => {
    const newKnowledge = { id: Date.now(), name: "", key: Date.now(), action: "add" };
    setPreviewSpecificKnowledge([...previewSpecificKnowledge, newKnowledge]);
    form.setFieldsValue({ specific_knowledge: previewSpecificKnowledge });
  };

  const removePreviewSpecificKnowledge = (e, index) => {
    const updatedKnowledge = [...previewSpecificKnowledge];
    updatedKnowledge[index] = { ...updatedKnowledge[index], action: "delete" };
    setPreviewSpecificKnowledge(updatedKnowledge);
    form.setFieldsValue({ specific_knowledge: updatedKnowledge });
  };

  const onChangeSpecificKnowledge = (e, index) => {
    const updatedKnowledge = [...previewSpecificKnowledge];
    updatedKnowledge[index] = { ...updatedKnowledge[index], name: e.target.value, action: updatedKnowledge[index].action === "add" ? "add" : "edit" };
    setPreviewSpecificKnowledge(updatedKnowledge);
    form.setFieldsValue({ specific_knowledge: updatedKnowledge });
  };

  const addNewPreviewSpecificSkill = () => {
    const newSkill = { id: Date.now(), name: "", key: Date.now(), action: "add" };
    setPreviewSpecificSkills([...previewSpecificSkills, newSkill]);
    form.setFieldsValue({ specific_knowledge: previewSpecificSkills });
  };

  const removePreviewSpecificSkill = (e, index) => {
    const updatedSkill = [...previewSpecificSkills];
    updatedSkill[index] = { ...updatedSkill[index], action: "delete" };
    setPreviewSpecificSkills(updatedSkill);
    form.setFieldsValue({ specific_knowledge: updatedSkill });
  };

  const onChangeSpecificSkill = (e, index) => {
    const updatedSkill = [...previewSpecificSkills];
    updatedSkill[index] = { ...updatedSkill[index], name: e.target.value, action: updatedSkill[index].action === "add" ? "add" : "edit" };
    setPreviewSpecificSkills(updatedSkill);
    form.setFieldsValue({ specific_knowledge: updatedSkill });
  };

  return (
    <Modal
      getContainer={false}
      centered
      open={editRecord !== null}
      title={isNew ? "Add a new skill group" : "Edit skill group"}
      width={850}
      okText="Save"
      confirmLoading={modalLoading}
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      className={isNew ? "add-skillframework-modal" : "edit-skillframework-modal"}
      onOk={() => {
        form.validateFields().then((values) => {
          setModalLoading(true);
          delete values.created;
          delete values.created_by;
          delete values.updated;
          delete values.updated_by;

          if (isNew) {
            const reqData = {
              name: values.name,
              color: values.color || "#000000",
              specific_knowledge: previewSpecificKnowledge || [],
              specific_skills: previewSpecificSkills || [],
            };

            Axios.post(Config.server.addSkillsGroup, { ...authData, ...reqData }, { headers: { "Content-Type": "application/json" } })
              .then((response) => {
                setModalLoading(false);
                if (response && response.data.error === false) {
                  form.resetFields();
                  showMessage({ type: "success", content: "New skill group successfully added." });
                  refresh();
                } else {
                  console.log("error: " + response.data.message);
                  showMessage({ type: "error", content: response.data.message.includes("exists") ? response.data.message : "Something went wrong!" });
                }
              })
              .catch((error) => {
                setModalLoading(false);
                console.log(error);
                showMessage({ type: "error", content: "Something went wrong!" });
              });
          } else {
            Axios.post(Config.server.editSkillsGroup, { ...authData, ...values }, { headers: { "Content-Type": "application/json" } })
              .then((response) => {
                setModalLoading(false);
                if (response && response.data.error === false) {
                  form.resetFields();
                  showMessage({ type: "success", content: "Updated successfully." });
                  refresh();
                } else {
                  console.log("error: " + response.data.message);
                  showMessage({ type: "error", content: "Something went wrong!" });
                }
              })
              .catch((error) => {
                setModalLoading(false);
                console.log(error);
                showMessage({ type: "error", content: "Something went wrong!" });
              });
          }
        });
      }}
    >
      <Form form={form} layout="horizontal" name="edit_skill_modal" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }} labelWrap colon={false} className="content-scroll">
        <Form.Item
          name="name"
          label="Skill group name"
          rules={[
            { required: true, message: "Please enter the name of the skill group." },
            { type: "string", min: 2, max: 1023 },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || !nameExists(value) || !isNew) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("This skill group is already stored in the database!"));
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="color" label="CSS color" rules={[{ message: "Please enter a valid 6-digit hex color code", type: "string" }]}>
          <Space>
            <Input value={previewColor} onChange={handleColorChange} />
            <div className="color-box" style={{ backgroundColor: previewColor }}>
              <span>preview</span>
            </div>
          </Space>
          <Space>
            <Text type="secondary">Use name, html, hex, rgb, rgba, hsl, hsla, hwb, lab or lch values.</Text>
          </Space>
        </Form.Item>
        <Form.Item name="specific_skills" label="Specific skills">
          <Space direction="vertical">
            {previewSpecificSkills
              .filter((value) => value.action !== "delete")
              .map((value, index) => (
                <Space key={index} align="baseline">
                  <Input value={value ? value.name : ""} onChange={(newName) => onChangeSpecificSkill(newName, index)} />
                  <Button onClick={(e) => removePreviewSpecificSkill(e, index)}>Remove</Button>
                </Space>
              ))}
            <Button onClick={addNewPreviewSpecificSkill}>Add skill</Button>
          </Space>
        </Form.Item>
        <Form.Item name="specific_knowledge" label="Specific knowledge">
          <Space direction="vertical">
            {previewSpecificKnowledge
              .filter((value) => value.action !== "delete")
              .map((value, index) => (
                <Space key={index} align="baseline">
                  <Input value={value ? value.name : ""} onChange={(e) => onChangeSpecificKnowledge(e, index)} />
                  <Button onClick={(e) => removePreviewSpecificKnowledge(e, index)}>Remove</Button>
                </Space>
              ))}
            <Button onClick={addNewPreviewSpecificKnowledge}>Add knowledge</Button>
          </Space>
        </Form.Item>
        {typeof editRecord === "object" && editRecord !== null ? (
          <>
            <Form.Item label="Updated">
              {editRecord.updated} [CET] by {getUserName(editRecord.updated_by)}
            </Form.Item>
            <Form.Item label="Created">
              {editRecord.created} [CET] by {getUserName(editRecord.created_by)}
            </Form.Item>
          </>
        ) : null}
        <Form.Item name="id" label="Id" hidden>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditSkillFrameworkModalForm;
