const title = "Digital Forensics Investigator";

// const alternativeTitles = [
//     "Digital Forensics Examiner",
//     "Computer Forensics Investigator"
// ];

const alternativeTitles = [
    { key: 1, title: "Digital Forensics Examiner" },
    { key: 2, title: "Computer Forensics Investigator" }
]

// const summaryStatement = [
//     "Collect, preserve and analyse digital evidence from electronic devices. Provide expert testimony in legal proceedings."
// ];

const summaryStatement = [{ key: 1, statement: "Collect, preserve and analyse digital evidence from electronic devices. Provide expert testimony in legal proceedings." }];

// const mission = [
//     "Collect, preserve and analyse digital evidence from electronic devices.",
//     "Provide expert testimony in legal proceedings."
// ];

const mission = [
    { key: 1, mission: "Collect, preserve and analyse digital evidence from electronic devices." },
    { key: 2, mission: "Provide expert testimony in legal proceedings." }
];

// const deliverables = [
//     "Digital evidence collection and preservation reports",
//     "Digital evidence analysis reports",
//     "Expert testimony in legal proceedings"
// ];

const deliverables = [
    {key: 1, deliverable: "Digital Forensics Analysis Results", description: "Results of the analysis of digital data uncovering potential evidence of malicious incidents and identifying possible threat actors."},
    {key: 2, deliverable: "Electronic Evidence", description: "Potential evidence derived from data contained in or produced by any device, the functioning of which depends on a software program or data stored on or transmitted over a computer system or network. (e.g. accurate collection of logs)"},
    { key: 3, deliverable: "Digital evidence collection and preservation reports" },
    { key: 4, deliverable: "Digital evidence analysis reports" },
    { key: 5, deliverable: "Expert testimony in legal proceedings" }
];

const mainTasks = [
    {key:1, task:"Develop digital forensics investigation policy, plans and procedures"},
    {key:2, task:"Identify, recover, extract, document and analyse digital evidence"},
    {key:3, task:"Preserve and protect digital evidence and make it available to authorised stakeholders"},
    {key:4, task:"Inspect environments for evidence of unauthorised and unlawful actions"},
    {key:5, task:"Systematically and deterministic document, report and present digital forensic analysis findings and results"},
    {key:6, task:"Select and customise forensics testing, analysing and reporting techniques"}
];

const keySkills = [
    { key: 1, skill: "Work ethically and independently; not influenced and biased by internal or external actors" },
    { key: 2, skill: "Collect information while preserving its integrity" },
    { key: 3, skill: "Identify, analyse and correlate cybersecurity events" },
    { key: 4, skill: "Explain and present digital evidence in a simple, straightforward and easy to understand way" },
    { key: 5, skill: "Develop and communicate, detailed and reasoned investigation reports" }
];

// • Digital forensics recommendations and best practices
// • Digital forensics standards, methodologies and frameworks
// • Digital forensics analysis procedures
// • Testing procedures
// • Criminal investigation procedures, standards, methodologies and frameworks
// • Cybersecurity related laws, regulations and legislations
// • Malware analysis tools
// • Cyber threats
// • Computer systems vulnerabilities
// • Cybersecurity attack procedures
// • Operating systems security
// • Computer networks security
// • Cybersecurity-related certifications

const keyKnowledge = [
    { key: 1, knowledge: "Digital forensics recommendations and best practices" },
    { key: 2, knowledge: "Digital forensics standards, methodologies and frameworks" },
    { key: 3, knowledge: "Digital forensics analysis procedures" },
    { key: 4, knowledge: "Testing procedures" },
    { key: 5, knowledge: "Criminal investigation procedures, standards, methodologies and frameworks" },
    { key: 6, knowledge: "Cybersecurity related laws, regulations and legislations" },
    { key: 7, knowledge: "Malware analysis tools" },
    { key: 8, knowledge: "Cyber threats" },
    { key: 9, knowledge: "Computer systems vulnerabilities" },
    { key: 10, knowledge: "Cybersecurity attack procedures" },
    { key: 11, knowledge: "Operating systems security" },
    { key: 12, knowledge: "Computer networks security" },
    { key: 13, knowledge: "Cybersecurity-related certifications" }
];

const eCompetences = [
    { key: 1, competence: "A.7. Technology Trend Monitoring", level: ["Level 3"] },
    { key: 2, competence: "B.3. Testing", level: ["Level 4"] },
    { key: 3, competence: "B.5. Documentation Production", level: ["Level 3"] },
    { key: 4, competence: "E.3. Risk Management", level: ["Level 3"] }
];

export const FORENData = {
    title,
    alternativeTitles,
    summaryStatement,
    mission,
    deliverables,
    mainTasks,
    keySkills,
    keyKnowledge,
    eCompetences
}