import React from 'react'
import Axios from 'axios'
import {Alert, Button, Spinner } from 'react-bootstrap'

import {Config} from './config_section.js'
import './CSProfiler.scss'
import 'bootstrap/scss/bootstrap.scss'
import Curricula from './curricula/Curricula.js'
import StudyProfiler from './study-profiler/StudyProfiler.js'
import Trainings from './trainings/Trainings.js'
import Certifications from './certifications/Certifications.js'
import Statistics from './statistics/Statistics.js'
import CSPIntersection from './intersection/CSPIntersection.js'


const CSProfiler = ({ section, showMessage }) => {
  const [dbCertifications, setDbCertifications] = React.useState(null)
  const [dbStudyPrograms, setDbStudyPrograms] = React.useState(null)
  const [dbTrainings, setDbTrainings] = React.useState(null)
  const [rsSkillsGroup, setRsSkillsGroup] = React.useState(null)
  const [rsSpecificKnowledge, setRsSpecificKnowledge] = React.useState(null)
  const [rsSpecificSkills, setRsSpecificSkills] = React.useState(null)
  const [rsProfiles, setRsProfiles] = React.useState(null)
  const [dbDomains, setDbDomains] = React.useState(null)
  const [dbECSFRoles, setDbECSFRoles] = React.useState(null)

  /*
  stavy dat:
    init loading = null
    chyba pri loadingu = 0
    prazdne data = []
    plne data = [data]
  */

  React.useEffect(() => {
     getData()
  }, [])

  function getData () {
    // loading data state
    setDbCertifications(null)
    setDbStudyPrograms(null)
    setDbTrainings(null)
    setRsSkillsGroup(null)
    setRsSpecificKnowledge(null)
    setRsSpecificSkills(null)
    setRsProfiles(null)
    setDbDomains(null)
    setDbECSFRoles(null)
    Axios.get( Config.server.getData )
      .then((response) => {
        if (response && response.data.error === false){
          // success data state
          setDbCertifications(response.data.data.certifications)
          setDbStudyPrograms(response.data.data.study_programs)
          setDbTrainings(response.data.data.trainings)
          setRsSkillsGroup(response.data.data.rs_skill_group)
          setRsSpecificKnowledge(response.data.data.rs_specific_knowledge)
          setRsSpecificSkills(response.data.data.rs_specific_skills)
          setRsProfiles(response.data.data.rs_profiles)
          setDbDomains(response.data.data.csp_domains)
          setDbECSFRoles(response.data.data.csp_ecsf_roles)
        } else {
          console.log(response.data.message)
          // error data state
          setDbCertifications(0)
          setDbStudyPrograms(0)
          setDbTrainings(0)
          setRsSkillsGroup(0)
          setRsSpecificKnowledge(0)
          setRsSpecificSkills(0)
          setRsProfiles(0)
          setDbDomains(0)
          setDbECSFRoles(0)
        }
      })
      .catch((error) => {
        console.log("Server is unavailable")
        console.log(error)
        setDbCertifications(0)
        setDbStudyPrograms(0)
        setDbTrainings(0)
        setRsSkillsGroup(0)
        setRsSpecificKnowledge(0)
        setRsSpecificSkills(0)
        setRsProfiles(0)
        setDbDomains(0)
        setDbECSFRoles(0)
      })
  }

  function dataStatus() {
    if (
      dbCertifications === null &&
      dbStudyPrograms === null &&
      dbTrainings === null &&
      rsSkillsGroup === null &&
      rsSpecificKnowledge === null &&
      rsSpecificSkills === null &&
      rsProfiles === null &&
      dbDomains === null &&
      dbECSFRoles === null
    ) {
      return "loading"
    } else if (
      Array.isArray(dbCertifications) &&
      Array.isArray(dbStudyPrograms) &&
      Array.isArray(dbTrainings) &&
      Array.isArray(rsSkillsGroup) &&
      Array.isArray(rsSpecificKnowledge) &&
      Array.isArray(rsSpecificSkills) &&
      Array.isArray(rsProfiles) &&
      Array.isArray(dbDomains) &&
      Array.isArray(dbECSFRoles)
    ) {
      return "ok"
    } else {
      return "error"
    }
  }

  return (
    <section id="csprofiler">
    {
      dataStatus() === "loading" ? (
        <div className="main">
          <div className="main_loading">
            <Spinner animation="border" variant="primary" />
            <br/>
            Loading...
          </div>
        </div>
      ) : dataStatus() === "error" ? (
        <div className="main">
          <div className="main_loading">
            <Alert variant="danger">We are sorry. The database connection failed.</Alert>
            <div>
              <Button variant="link" onClick={()=>window.location.reload()}>Go back</Button>
            </div>
          </div>
        </div>
      ) : section === "csprofiler-statistics" ? (
        <Statistics dbCertifications={dbCertifications} dbTrainings={dbTrainings} dbStudyPrograms={dbStudyPrograms} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} rsProfiles={rsProfiles}/>
      ) : section === "csprofiler-certs" ? (
        <Certifications dbCertifications={dbCertifications} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} rsProfiles={rsProfiles} dbDomains={dbDomains} dbECSFRoles={dbECSFRoles}/>
      ) : section === "csprofiler-trainings" ? (
        <Trainings dbTrainings={dbTrainings} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} rsProfiles={rsProfiles}/>
      ) : section === "csprofiler-study-profiler" ? (
        <StudyProfiler showMessage={showMessage} dbStudyPrograms={dbStudyPrograms} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} rsProfiles={rsProfiles}/>
      ) : section === "csprofiler-intersection" ? (
        <CSPIntersection />
      ) : (
        <Curricula showMessage={showMessage} dbStudyPrograms={dbStudyPrograms} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} rsProfiles={rsProfiles}/>
      ) 
    }
    </section>
  )
}

export default CSProfiler
