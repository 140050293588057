import { useState, useEffect } from "react";
import { Config } from "../config_section.js";
import Axios from "axios";
import { Button, DatePicker, Form, Input, InputNumber, Modal, Select, Space, Badge } from "antd";
import "../Admin.scss";
import moment from "moment";
import { countries, languages } from "countries-list";
import REWIREskillSelectionModalForm from "./REWIREskillSelectionModalForm";

const { TextArea } = Input;

const AddEditTrainingModalForm = ({ editRecord, refresh, onCancel, showMessage, authData, rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, usersData, dbTrainings }) => {
  const [modalLoading, setModalLoading] = useState(false);
  const [form] = Form.useForm();
  const [isNew, setIsNew] = useState(false);
  const [formDates, setFormDates] = useState([]);
  const [skillModalShown, setSkillModalShown] = useState(false);
  const [skillFromData, setSkillFromData] = useState();

  useEffect(() => {
    if (typeof editRecord === "object" && editRecord !== null && form.getFieldValue("id") !== editRecord.id) {
      let initialData = JSON.parse(JSON.stringify(editRecord));
      delete initialData.key;
      form.setFieldsValue(initialData);
      setFormDates(initialData.dates ? initialData.dates : []);
      setIsNew(false);
      let initialSkillFormData = {
        skills_group: editRecord.skills_group || [],
        specific_skills: editRecord.specific_skills || [],
        specific_knowledge: editRecord.specific_knowledge || [],
      };
      setSkillFromData(initialSkillFormData);
    } else if (editRecord === "new") {
      setIsNew(true);
      setFormDates([]);
      setSkillFromData({
        skills_group: [],
        specific_skills: [],
        specific_knowledge: [],
      });
    }
  }, [editRecord, form]);
  function getUserName(id) {
    let allUsers = [...usersData.newUsers, ...usersData.adminUsers, ...usersData.blockedUsers, ...usersData.registeredUsers];
    let user = allUsers.find((u) => u.id === id);
    let output = user.admin_level > 1 ? "ADMIN " : "";
    output += user.name + " (ID " + user.id + ")";
    return output;
  }
  function nameExists(value) {
    return dbTrainings.find((e) => e.name === value) ? true : false;
  }

  const onDateChange = (date, index) => {
    const updatedDates = [...formDates];
    updatedDates[index] = date ? date.format("YYYY-MM-DD") : null;
    setFormDates(updatedDates);
    form.setFieldsValue({ dates: updatedDates });
  };

  const removeDatePicker = (index) => {
    const updatedDates = formDates.filter((_, i) => i !== index);
    setFormDates(updatedDates);
    form.setFieldsValue({ dates: updatedDates });
  };

  const addDatePicker = () => {
    setFormDates([...formDates, null]);
  };
  return (
    <Modal
      getContainer={false}
      centered
      open={editRecord !== null}
      title={isNew ? "Add a new training" : "Edit training"}
      width={850}
      okText="Save"
      confirmLoading={modalLoading}
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      className={isNew ? "add-training-modal" : "edit-training-modal"}
      onOk={() => {
        form.validateFields().then((values) => {
          setModalLoading(true);
          delete values.created;
          delete values.created_by;
          delete values.updated;
          delete values.updated_by;

          values.specific_skills = skillFromData.specific_skills || [];
          values.specific_knowledge = skillFromData.specific_knowledge || [];
          values.skills_group = skillFromData.skills_group || [];

          if (isNew) {
            const reqData = {
              name: values.name,
              organizer: values.organizer || "",
              description: values.description || "",
              country: values.country || "",
              duration: values.duration || "",
              type_format: values.type_format || "",
              timing: values.timing || "",
              dates: values.dates || [],
              content_type: values.content_type || "",
              prerequisites: values.prerequisites || "",
              can_lead_to_certification: values.can_lead_to_certification || "",
              includes_exams_for_certification: values.includes_exams_for_certification || "",
              language: values.language || "",
              price: values.price || "",
              skills_group: values.skills_group || [],
              specific_skills: [],
              specific_knowledge: [],
              link: values.link ? values.link : "",
            };

            Axios.post(Config.server.addTraining, { ...authData, ...reqData }, { headers: { "Content-Type": "application/json" } })
              .then((response) => {
                setModalLoading(false);
                if (response && response.data.error === false) {
                  form.resetFields();
                  showMessage({
                    type: "success",
                    content: "New training successfully added.",
                  });
                  refresh();
                } else {
                  console.log("error: " + response.data.message);
                  showMessage({
                    type: "error",
                    content: response.data.message.includes("exists") ? response.data.message : "Something went wrong!",
                  });
                }
              })
              .catch((error) => {
                setModalLoading(false);
                console.log(error);
                showMessage({
                  type: "error",
                  content: "Something went wrong!",
                });
              });
          } else {
            Axios.post(Config.server.editTraining, { ...authData, ...values }, { headers: { "Content-Type": "application/json" } })
              .then((response) => {
                setModalLoading(false);
                if (response && response.data.error === false) {
                  form.resetFields();
                  showMessage({
                    type: "success",
                    content: "Updated successfully.",
                  });
                  refresh();
                } else {
                  console.log("error: " + response.data.message);
                  showMessage({
                    type: "error",
                    content: "Something went wrong!",
                  });
                }
              })
              .catch((error) => {
                setModalLoading(false);
                console.log(error);
                showMessage({
                  type: "error",
                  content: "Something went wrong!",
                });
              });
          }
        });
      }}
    >
      <Form form={form} layout="horizontal" name="addedit-training-form" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }} labelWrap colon={false} className="content-scroll">
        <Form.Item
          name="name"
          label="Training name"
          rules={[
            { required: true, message: "Please enter the title" },
            { type: "string", min: 2, max: 1023 },
            () => ({
              validator(_, value) {
                if (!value || !nameExists(value) || !isNew) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("This training is already stored in the database!"));
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="organizer"
          label="Organizer"
          rules={[
            {
              required: true,
              message: "Please enter the organizer",
            },
            { type: "string", min: 2, max: 1023 },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: "Please enter the description",
            },
            { type: "string", min: 2, max: 1023 },
          ]}
        >
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item name="country" label="Country" rules={[{ required: true, message: "Please select country." }]}>
          <Select showSearch showArrow allowClear>
            {Object.values(countries).map((country, index) => (
              <Select.Option key={index} value={country.name}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="duration" label="Duration (hours)" rules={[{ required: true, message: "Please enter the title" }]}>
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="type_format"
          label="Format"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please select one option",
            },
          ]}
        >
          <Select showSearch showArrow allowClear>
            <Select.Option value="Face-to-face" label="Face-to-face" />
            <Select.Option value="Online" label="Online" />
            <Select.Option value="Hybrid" label="Hybrid" />
          </Select>
        </Form.Item>
        <Form.Item
          name="timing"
          label="Timing"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please select one option",
            },
          ]}
        >
          <Select showSearch showArrow allowClear>
            <Select.Option value="Fixed dates" label="Fixed dates" />
            <Select.Option value="Available online" label="Available online" />
            <Select.Option value="On demand" label="On demand" />
          </Select>
        </Form.Item>
        <Form.Item
          name="dates"
          label="Dates"
          dependencies={["timing"]}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue("timing") === "Fixed dates") {
                  if (!value || !Array.isArray(value) || value.length === 0 || value.every(date => date === null || date === undefined)) {
                    return Promise.reject(new Error("Please add at least one date."));
                  }
                  if (value.some(date => date === null || date === undefined)) {
                    return Promise.reject(new Error("All date fields must be filled."));
                  }
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Space direction="vertical">
            {formDates.map((date, index) => (
              <Space key={index} align="baseline">
                <DatePicker value={date ? moment(date, "YYYY-MM-DD") : null} onChange={(date) => onDateChange(date, index)} />
                <Button onClick={() => removeDatePicker(index)}>Remove</Button>
              </Space>
            ))}
            <Button type="dashed" onClick={addDatePicker}>
              Add Date
            </Button>
          </Space>
        </Form.Item>
        <Form.Item
          name="content_type"
          label="Content type"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please select one option",
            },
          ]}
        >
          <Select showSearch showArrow allowClear>
            <Select.Option value="Theoretical only" label="Theoretical only" />
            <Select.Option value="Hands on" label="Hands on" />
            <Select.Option value="Theoretical and hands on" label="Theoretical and hands on" />
          </Select>
        </Form.Item>
        <Form.Item name="prerequisites" label="Prerequisites" rules={[{ type: "string", max: 1023 }]}>
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item
          name="can_lead_to_certification"
          label="Can lead to certification"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please select one option",
            },
            { type: "string", min: 2, max: 1023 },
          ]}
        >
          <Select showSearch showArrow allowClear>
            <Select.Option
              value="Yes (based on specific standarts, recognized by national/internation organizations)"
              label="Yes (based on specific standarts, recognized by national/internation organizations)"
            />
            <Select.Option value="No" label="No" />
            <Select.Option value="Other (eg. certificate of attendance / participation)" label="Other (eg. certificate of attendance / participation)" />
          </Select>
        </Form.Item>
        <Form.Item
          name="includes_exams_for_certification"
          label="Includes exams for certification"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please select one option",
            },
            { type: "string", min: 2, max: 1023 },
          ]}
        >
          <Select showSearch showArrow allowClear>
            <Select.Option value="Yes" label="Yes" />
            <Select.Option value="No" label="No" />
          </Select>
        </Form.Item>
        <Form.Item name="language" label="Language" rules={[{ required: true, message: "Please select at least one language." }]}>
          <Select showSearch showArrow allowClear mode="multiple">
            {Object.values(languages).map((language, index) => (
              <Select.Option key={index} value={language.name}>
                {language.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="price"
          label="Price"
          rules={[
            { required: true, message: "Please provide price." },
            { type: "int", min: 0 },
          ]}
        >
          <InputNumber addonAfter="€" min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="skills_group" label="REWIRE Skills">
          <Space direction="vertical" size={16}>
            <Space wrap className="skillBadges">
              {skillFromData?.skills_group.map((skillGroup) => (
                <Badge count={rsSkillsGroup.find((x) => x.id === skillGroup).name} color={rsSkillsGroup.find((x) => x.id === skillGroup).color || "white"} key={skillGroup} className="skillBadge" />
              ))}
            </Space>
            <Button onClick={() => setSkillModalShown(true)}>Choose</Button>
          </Space>
        </Form.Item>
        <Form.Item name="link" label="Link" rules={[{ type: "string", required: true, message: "Please enter link." }]}>
          <Input />
        </Form.Item>

        {typeof editRecord === "object" && editRecord !== null ? (
          <>
            <Form.Item label="Updated">
              {editRecord.updated} [CET] by {getUserName(editRecord.updated_by)}
            </Form.Item>

            <Form.Item label="Created">
              {editRecord.created} [CET] by {getUserName(editRecord.created_by)}
            </Form.Item>
          </>
        ) : null}

        <Form.Item name="id" label="Id" hidden>
          <Input />
        </Form.Item>
      </Form>
      <REWIREskillSelectionModalForm
        skillModalShown={skillModalShown}
        initialData={skillFromData}
        onCancel={() => setSkillModalShown(false)}
        saveFormData={(data) => setSkillFromData(data)}
        rsSkillsGroup={rsSkillsGroup}
        rsSpecificKnowledge={rsSpecificKnowledge}
        rsSpecificSkills={rsSpecificSkills}
      />
    </Modal>
  );
};

export default AddEditTrainingModalForm;
