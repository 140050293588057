import { Form, Modal } from "antd"
import { useEffect, useMemo, useState } from "react"
import { csSkillsSet, itSkillsSet, softSkillsSet } from "../../Utils"
import { Filters } from "./Filters"
import { Trainings } from "./Trainings"
import { getFilters } from "./utils"

export const TrainingsBrowserModal = ( {
	rsSkillsGroup,
	trainings,
	open,
	initialSkillsGroup = [],
	onClose,
	showMessage,
} ) => {
	const filters = useMemo( () => getFilters( trainings ), [ trainings ] )

	const [ filter, setFilter ] = useState( () => ( {
		countries: [],
		languages: [],
		typeFormats: [],
		organizers: [],
		canLeadToCertifications: [],
		includesExamsForCertifications: [],
		contentTypes: [],
		duration: [ filters.durationMin, filters.durationMax ],
		price: [ filters.priceMin, filters.priceMax ],
		itSkillsGroup: ( initialSkillsGroup ?? [] ).filter( skillGr => itSkillsSet.includes( skillGr ) ),
		csSkillsGroup: ( initialSkillsGroup ?? [] ).filter( skillGr => csSkillsSet.includes( skillGr ) ),
		sfSkillsGroup: ( initialSkillsGroup ?? [] ).filter( skillGr => softSkillsSet.includes( skillGr ) ),
		showOutdated: false,
		disableSuggestions: false,
		trainingFocus: [],
	} ) )
	const [ form ] = Form.useForm()

	useEffect( () => {
		if ( initialSkillsGroup?.length ) {
			const newSkillGroup = {
				itSkillsGroup: initialSkillsGroup.filter( skillGr => itSkillsSet.includes( skillGr ) ),
				csSkillsGroup: initialSkillsGroup.filter( skillGr => csSkillsSet.includes( skillGr ) ),
				sfSkillsGroup: initialSkillsGroup.filter( skillGr => softSkillsSet.includes( skillGr ) ),
			}
			setFilter( oldFilter => ( { ...oldFilter, ...newSkillGroup } ) )
			form.setFieldsValue( newSkillGroup )
		}
	}, [ initialSkillsGroup, form ] )

	return (
		<Modal
			getContainer={ false }
			centered
			open={ open }
			title={ "Trainings Browser" }
			width={ 950 }
			cancelText={ "Close" }
			okButtonProps={ { className: "trainings-browser-ok-button" } }
			className={ "trainings-browser-modal" }
			onCancel={ () => {
				form.resetFields()
				onClose()
			} }
		>
			<div className={ "trainings-browser" }>
				<div className={ "trainings-browser-filter" }>
					<Filters
						form={ form }
						rsSkillsGroup={ rsSkillsGroup }
						onChange={ setFilter }
						filters={ filters }
						initialValues={ filter }
					/>
				</div>
				<div className={ "trainings-browser-list" }>
					<Trainings
						showMessage={ showMessage }
						filter={ filter }
						form={ form }
						rsSkillsGroup={ rsSkillsGroup }
						trainings={ trainings }
					/>
				</div>
			</div>
		</Modal>
	)
}
