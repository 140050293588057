import {
	Button,
	Col,
	Form,
	Input,
	Modal,
	Row,
	Select,
	Tooltip,
} from "antd"
import Axios from "axios"
import { useState, useEffect } from "react"
import { itSkillsSet, csSkillsSet, softSkillsSet } from "../Utils"
import { Config } from "./config_section.js"

const { TextArea } = Input

const AddEditSkillModalForm = ( {
	showMessage,
	editRecord,
	onCancel,
	onModalSubmit,
	rsSkillsGroup,
} ) => {
	const [ form ] = Form.useForm()
	const [ isNew, setIsNew ] = useState( false )
	const [ descAnalysisLoading, setDescAnalysisLoading ] = useState( false )

	useEffect( () => {
		if (
			typeof editRecord === "object"
			&& editRecord !== null
			&& form.getFieldValue( "id" ) !== editRecord.id
		) {
			let initialData = JSON.parse( JSON.stringify( editRecord ) )
			initialData.it_skills_group = initialData.skills_group.filter( ( e ) => itSkillsSet.includes( e ) )
			initialData.cs_skills_group = initialData.skills_group.filter( ( e ) => csSkillsSet.includes( e ) )
			initialData.sf_skills_group = initialData.skills_group.filter( ( e ) => softSkillsSet.includes( e ) )
			delete initialData.skills_group
			delete initialData.enabled
			form.setFieldsValue( initialData )
			setIsNew( false )
		} else if ( editRecord === "new" ) {
			setIsNew( true )
		}
	}, [ editRecord, form ] )

	const analyzeDescription = () => {
		setDescAnalysisLoading( true )
		Axios.post( Config.server.analyzeCourseDescription, { description: form.getFieldValue( "description" ) }, { headers: { "Content-Type": "application/json" } } )
			.then( ( response ) => {
				setDescAnalysisLoading( false )
				if ( response && response.data.error === false ) {
					form.setFieldValue( "it_skills_group", response.data.data.prediction.skills_groups.filter( ( e ) => itSkillsSet.includes( e ) ) )
					form.setFieldValue( "cs_skills_group", response.data.data.prediction.skills_groups.filter( ( e ) => csSkillsSet.includes( e ) ) )
					form.setFieldValue( "sf_skills_group", response.data.data.prediction.skills_groups.filter( ( e ) => softSkillsSet.includes( e ) ) )
					showMessage( { type: "success", content: "Description analysis done." } )
				} else {
					showMessage( { type: "error", content: "Something went wrong!" } )
				}
			} )
			.catch( ( error ) => {
				setDescAnalysisLoading( false )
				console.log( error )
				showMessage( { type: "error", content: "Something went wrong!" } )
			} )
	}

	return (
		<Modal
			getContainer={ false }
			centered
			open={ editRecord !== null }
			title={ isNew ? "Add a new skill" : "Edit skill" }
			width={ 850 }
			okText={ "Save" }
			cancelText={ "Cancel" }
			onCancel={ () => {
				if ( descAnalysisLoading ) {
					showMessage( { type: "error", content: "Please wait for the description analysis to finish." } )
					return
				}
				form.resetFields()
				onCancel()
			} }
			className={ isNew ? "add-skill-modal" : "edit-skill-modal" }
			onOk={ () => {
				form.validateFields().then( ( values ) => {
					if ( descAnalysisLoading ) {
						showMessage( { type: "error", content: "Please wait for the description analysis to finish." } )
						return
					}
					values.skills_group = values.it_skills_group.concat(
						values.cs_skills_group,
						values.sf_skills_group,
					)
					delete values.it_skills_group
					delete values.cs_skills_group
					delete values.sf_skills_group
					onModalSubmit( JSON.parse( JSON.stringify( values ) ) )
					form.resetFields()
				} )
			} }
		>
			<Form
				preserve
				form={ form }
				layout={ "horizontal" }
				name={ isNew ? "add-skill-modal-form" : "edit-skill-modal-form" }
				labelCol={ { span: 7 } }
				wrapperCol={ { span: 15 } }
				labelWrap
				colon={ false }
				className={ "content-scroll" }
				initialValues={ {
					name: "",
					description: "",
					it_skills_group: [],
					cs_skills_group: [],
					sf_skills_group: [],
				} }
			>
				<Form.Item
					name={ "name" }
					label={ "Title" }
					rules={ [
						{ required: true, message: "Please enter the title" },
						{ type: "string", min: 2, max: 1023 },
					] }
				>
					<Input />
				</Form.Item>

				<Form.Item name={ "description" } label={ "Description" }>
					<TextArea rows={ 4 } />
				</Form.Item>

				<Row className={ "analyze-button-row" }>
					<Col xs={ 7 }>
						{ "REWIRE Skills Analysis" }
					</Col>
					<Col>
						{ /*todo: disable button when decription is empty*/ }
						<Button disabled={ false } loading={ descAnalysisLoading } onClick={ analyzeDescription }>
							{ "Analyze Description" }
						</Button>
					</Col>
					<Col>
						<Tooltip title={ "In development" }>
							<Button disabled={ true }>{ "Analyze PDF" }</Button>
						</Tooltip>
					</Col>
					<Col>
						<Tooltip title={ "In development" }>
							<Button disabled={ true }>{ "Analyze Trainings" }</Button>
						</Tooltip>
					</Col>
				</Row>

				<Form.Item name={ "it_skills_group" } label={ "IT Skills" }>
					<Select
						showSearch
						showArrow
						allowClear
						mode={ "multiple" }
						optionFilterProp={ "label" }
						options={ rsSkillsGroup
							.sort( ( a, b ) => ( a.name > b.name ? 1 : b.name > a.name ? -1 : 0 ) )
							.filter( ( e ) => itSkillsSet.includes( e.id ) )
							.map( ( skill ) => ( { value: skill.id, label: skill.name } ) ) }
					/>
				</Form.Item>

				<Form.Item name={ "cs_skills_group" } label={ "CS Skills" }>
					<Select
						showSearch
						showArrow
						allowClear
						mode={ "multiple" }
						optionFilterProp={ "label" }
						options={ rsSkillsGroup
							.sort( ( a, b ) => ( a.name > b.name ? 1 : b.name > a.name ? -1 : 0 ) )
							.filter( ( e ) => csSkillsSet.includes( e.id ) )
							.map( ( skill ) => ( { value: skill.id, label: skill.name } ) ) }
					/>
				</Form.Item>

				<Form.Item name={ "sf_skills_group" } label={ "Soft Skills" }>
					<Select
						showSearch
						showArrow
						allowClear
						mode={ "multiple" }
						optionFilterProp={ "label" }
						options={ rsSkillsGroup
							.sort( ( a, b ) => ( a.name > b.name ? 1 : b.name > a.name ? -1 : 0 ) )
							.filter( ( e ) => softSkillsSet.includes( e.id ) )
							.map( ( skill ) => ( { value: skill.id, label: skill.name } ) ) }
					/>
				</Form.Item>

				<Form.Item name={ "id" } label={ "Id" } hidden>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default AddEditSkillModalForm
