import { useEffect, useState } from "react";
import { Config } from "../config_section.js";
import Axios from "axios";
import { Form, Input, InputNumber, Modal, Select, Space, Badge, Button } from "antd";
import "../Admin.scss";
import { countries, languages } from "countries-list";
import REWIREskillSelectionModalForm from "./REWIREskillSelectionModalForm";

const { TextArea } = Input;

const AddEditStudyProgramModalForm = ({ editRecord, refresh, onCancel, showMessage, authData, rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, usersData, dbStudyPrograms }) => {
  const [modalLoading, setModalLoading] = useState(false);
  const [form] = Form.useForm();
  const [isNew, setIsNew] = useState(false);
  const [skillModalShown, setSkillModalShown] = useState(false);
  const [skillFromData, setSkillFromData] = useState();

  useEffect(() => {
    if (typeof editRecord === "object" && editRecord !== null && form.getFieldValue("id") !== editRecord.id) {
      let initialData = JSON.parse(JSON.stringify(editRecord));
      delete initialData.key;
      form.setFieldsValue(initialData);
      setIsNew(false);
      let initialSkillFormData = {
        skills_group: editRecord.skills_group || [],
        specific_skills: editRecord.specific_skills || [],
        specific_knowledge: editRecord.specific_knowledge || [],
      };
      setSkillFromData(initialSkillFormData);
    } else if (editRecord === "new") {
      setIsNew(true);
      setSkillFromData({
        skills_group: [],
        specific_skills: [],
        specific_knowledge: [],
      });
    }
  }, [editRecord, form]);

  function getUserName(id) {
    let allUsers = [...usersData.newUsers, ...usersData.adminUsers, ...usersData.blockedUsers, ...usersData.registeredUsers];
    let user = allUsers.find((u) => u.id === id);
    let output = user.admin_level > 1 ? "ADMIN " : "";
    output += user.name + " (ID " + user.id + ")";
    return output;
  }
  function nameExists(value) {
    return dbStudyPrograms.find((e) => e.name === value) ? true : false;
  }

  return (
    <Modal
      getContainer={false}
      centered
      open={editRecord !== null}
      //open = {editRecord === "new"}
      title={isNew ? "Add a new study program" : "Edit study program"}
      width={850}
      okText="Save"
      confirmLoading={modalLoading}
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      className={isNew ? "add-studyprogram-modal" : "edit-studyprogram-modal"}
      onOk={() => {
        form.validateFields().then((values) => {
          setModalLoading(true);
          delete values.created;
          delete values.created_by;
          delete values.updated;
          delete values.updated_by;


          values.specific_skills = skillFromData.specific_skills || [];
          values.specific_knowledge = skillFromData.specific_knowledge || [];
          values.skills_group = skillFromData.skills_group || [];

          if (isNew) {
            const reqData = {
              name: values.name,
              university: values.university || "",
              department: values.department || "",
              country: values.country || [],
              degree: values.degree || "",
              degree_title: values.degree_title || "",
              language: values.language || [],
              duration: values.duration || "",
              cost: values.cost || "",
              practical_lectures: values.practical_lectures || "",
              description: values.description || "",
              skills_group: values.skills_group || [],
              specific_skills: [],
              specific_knowledge: [],
              link: values.link || "",
            };

            Axios.post(Config.server.addStudyProgram, { ...authData, ...reqData }, { headers: { "Content-Type": "application/json" } })
              .then((response) => {
                setModalLoading(false);
                if (response && response.data.error === false) {
                  form.resetFields();
                  showMessage({
                    type: "success",
                    content: "New study program successfully added.",
                  });
                  refresh();
                } else {
                  console.log("error: " + response.data.message);
                  showMessage({
                    type: "error",
                    content: response.data.message.includes("exists") ? response.data.message : "Something went wrong!",
                  });
                }
              })
              .catch((error) => {
                setModalLoading(false);
                console.log(error);
                showMessage({
                  type: "error",
                  content: "Something went wrong!",
                });
              });
          } else {
            Axios.post(Config.server.editStudyProgram, { ...authData, ...values }, { headers: { "Content-Type": "application/json" } })
              .then((response) => {
                setModalLoading(false);
                if (response && response.data.error === false) {
                  form.resetFields();
                  showMessage({
                    type: "success",
                    content: "Updated successfully.",
                  });
                  refresh();
                } else {
                  console.log("error: " + response.data.message);
                  showMessage({
                    type: "error",
                    content: "Something went wrong!",
                  });
                }
              })
              .catch((error) => {
                setModalLoading(false);
                console.log(error);
                showMessage({
                  type: "error",
                  content: "Something went wrong!",
                });
              });
          }
        });
      }}
    >
      <Form form={form} layout="horizontal" name="addedit-studyprogram-form" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }} labelWrap colon={false} className="content-scroll">
        <Form.Item
          name="name"
          label="Study program name"
          rules={[
            { required: true, message: "Please enter the title" },
            { type: "string", min: 2, max: 1023 },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || !nameExists(value) || !isNew) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("This study program is already stored in the database!"));
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="university"
          label="University:"
          rules={[
            {
              required: true,
              message: "Please enter name of the university.",
            },
            { type: "string", min: 2, max: 1023 },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="department" label="Department" rules={[{ required: false, message: "Please enter name of the department.", type: "string", min: 2, max: 1023 }]}>
          <Input />
        </Form.Item>
        <Form.Item name="country" label="Country" rules={[{ required: true, message: "Please select country." }]}>
          <Select showSearch showArrow allowClear>
            {Object.values(countries).map((country, index) => (
              <Select.Option key={index} value={country.name}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="degree" label="Degree" rules={[{ type: "string", required: true, message: "Please select the degree." }]}>
          <Select showSearch showArrow allowClear>
            <Select.Option value="Bachelor" label="Bachelor" />
            <Select.Option value="Master" label="Master" />
            <Select.Option value="Doctoral" label="Doctoral" />
            <Select.Option value="Post-graduate" label="Post-graduate" />
          </Select>
        </Form.Item>
        <Form.Item name="degree_title" label="Degree title" rules={[{ required: false, message: "Please enter the degree title.", type: "string", min: 2, max: 1023 }]}>
          <Input />
        </Form.Item>
        <Form.Item name="language" label="Language">
          <Select showSearch showArrow allowClear mode="multiple">
            {Object.values(languages).map((language, index) => (
              <Select.Option key={index} value={language.name}>
                {language.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="duration" label="Duration">
          <Select showSearch showArrow allowClear>
            <Select.Option value="1 year" label="1 year" />
            <Select.Option value="2 years" label="2 years" />
            <Select.Option value="3 years" label="3 years" />
            <Select.Option value="4 years" label="4 years" />
            <Select.Option value="Other" label="Other" />
          </Select>
        </Form.Item>
        <Form.Item name="cost" label="Cost per year" rules={[{ required: false, message: "Please provide price.", type: "int", min: 0 }]}>
          <InputNumber addonAfter="€" min={0} />
        </Form.Item>
        <Form.Item name="practical_lectures" label="Practical lectures" rules={[{ required: false, message: "Please provide percentage of practical lectures", type: "int", min: 0 }]}>
          <InputNumber addonAfter="%" min={0} />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ type: "string", min: 2, max: 1023 }]}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="skills_group" label="Skills Group">
          <Space direction="vertical" size={16}>
            <Space wrap className="skillBadges">
              {skillFromData?.skills_group.map((skillGroup) => (
                <Badge count={rsSkillsGroup.find((x) => x.id === skillGroup).name} color={rsSkillsGroup.find((x) => x.id === skillGroup).color || "white"} key={skillGroup} className="skillBadge" />
              ))}
            </Space>
            <Button onClick={() => setSkillModalShown(true)}>
              Choose
            </Button>
          </Space>
        </Form.Item>
        <Form.Item name="link" label="Link" rules={[{ type: "string" }]}>
          <Input />
        </Form.Item>
        {typeof editRecord === "object" && editRecord !== null ? (
          <>
            <Form.Item label="Updated">
              {editRecord.updated} [CET] by {getUserName(editRecord.updated_by)}
            </Form.Item>

            <Form.Item label="Created">
              {editRecord.created} [CET] by {getUserName(editRecord.created_by)}
            </Form.Item>
          </>
        ) : null}

        <Form.Item name="id" label="Id" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="specific_skills" label="specific_skills" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="specific_knowledge" label="specific_knowledge" hidden>
          <Input />
        </Form.Item>
      </Form>
      <REWIREskillSelectionModalForm
        skillModalShown={skillModalShown}
        initialData={skillFromData}
        onCancel={() => setSkillModalShown(false)}
        saveFormData={(data) => setSkillFromData(data)}
        rsSkillsGroup={rsSkillsGroup}
        rsSpecificKnowledge={rsSpecificKnowledge}
        rsSpecificSkills={rsSpecificSkills}
      />
    </Modal>
  );
};

export default AddEditStudyProgramModalForm;
