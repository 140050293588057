import { useState, useEffect, React } from "react";
import { Config } from "../config_section.js";
import Axios from "axios";
import "../Admin.scss";
import { Form, Input, Modal, Space, Badge, Button } from "antd";
import REWIREskillSelectionModalForm from "./REWIREskillSelectionModalForm";

const AddEditProfileModalForm = ({ editRecord, refresh, onCancel, showMessage, authData, rsSkillsGroup, rsProfiles, rsSpecificKnowledge, rsSpecificSkills, usersData }) => {
  const [modalLoading, setModalLoading] = useState(false);
  const [form] = Form.useForm();
  const [isNew, setIsNew] = useState(false);
  const [skillModalShown, setSkillModalShown] = useState(false);
  const [skillFromData, setSkillFromData] = useState();

  useEffect(() => {
    if (typeof editRecord === "object" && editRecord !== null && form.getFieldValue("id") !== editRecord.id) {
      let initialData = JSON.parse(JSON.stringify(editRecord));
      delete initialData.key;
      form.setFieldsValue(initialData);
      setIsNew(false);
      let initialSkillFormData = {
        skills_group: editRecord.skills_group || [],
        specific_skills: editRecord.specific_skills || [],
        specific_knowledge: editRecord.specific_knowledge || [],
      };
      setSkillFromData(initialSkillFormData);
    } else if (editRecord === "new") {
      setIsNew(true);
      setSkillFromData({
        skills_group: [],
        specific_skills: [],
        specific_knowledge: [],
      });
    }
  }, [editRecord, form]);

  function getUserName(id) {
    let allUsers = [...usersData.newUsers, ...usersData.adminUsers, ...usersData.blockedUsers, ...usersData.registeredUsers];
    let user = allUsers.find((u) => u.id === id);
    let output = user.admin_level > 1 ? "ADMIN " : "";
    output += user.name + " (ID " + user.id + ")";
    return output;
  }
  function nameExists(value) {
    return rsProfiles.find((e) => e.name === value) ? true : false;
  }

  return (
    <Modal
      getContainer={false}
      centered
      open={editRecord !== null}
      title={isNew ? "Add a new profile" : "Edit profile"}
      width={850}
      okText="Save"
      confirmLoading={modalLoading}
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      className={isNew ? "add-profile-modal" : "edit-profile-modal"}
      onOk={() => {
        form.validateFields().then((values) => {
          setModalLoading(true);
          delete values.created;
          delete values.created_by;
          delete values.updated;
          delete values.updated_by;

          values.skills_group = skillFromData.skills_group || [];

          const reqData = {
            name: values.name,
            skills_group: values.skills_group || [],
          };
          if (isNew) {
            Axios.post(Config.server.addProfile, { ...authData, ...reqData }, { headers: { "Content-Type": "application/json" } })
              .then((response) => {
                setModalLoading(false);
                if (response && response.data.error === false) {
                  form.resetFields();
                  showMessage({ type: "success", content: "New profile successfully added." });
                  refresh();
                } else {
                  console.log("error: " + response.data.message);
                  showMessage({ type: "error", content: response.data.message.includes("exists") ? response.data.message : "Something went wrong!" });
                }
              })
              .catch((error) => {
                setModalLoading(false);
                console.log(error);
                showMessage({ type: "error", content: "Something went wrong!" });
              });
          } else {
            Axios.post(Config.server.editProfile, { ...authData, ...values }, { headers: { "Content-Type": "application/json" } })
              .then((response) => {
                setModalLoading(false);
                if (response && response.data.error === false) {
                  form.resetFields();
                  showMessage({ type: "success", content: "Updated successfully." });
                  refresh();
                } else {
                  console.log("error: " + response.data.message);
                  showMessage({ type: "error", content: "Something went wrong!" });
                }
              })
              .catch((error) => {
                setModalLoading(false);
                console.log(error);
                showMessage({ type: "error", content: "Something went wrong!" });
              });
          }
        });
      }}
    >
      <Form form={form} layout="horizontal" name="add_job_modal" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }} labelWrap colon={false} className="content-scroll">
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: "Please fill in the name!" },
            { type: "string", min: 2, max: 1023 },
            () => ({
              validator(_, value) {
                if (!value || !nameExists(value) || !isNew) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("This profile is already stored in the database!"));
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="skills_group" label="Skills Group">
          <Space direction="vertical" size={16}>
            <Space wrap className="skillBadges">
              {skillFromData?.skills_group.map((skillGroup) => (
                <Badge count={rsSkillsGroup.find((x) => x.id === skillGroup).name} color={rsSkillsGroup.find((x) => x.id === skillGroup).color || "white"} key={skillGroup} className="skillBadge" />
              ))}
            </Space>
            <Button onClick={() => setSkillModalShown(true)}>
              Choose
            </Button>
          </Space>
        </Form.Item>
        {typeof editRecord === "object" && editRecord !== null ? (
          <>
            <Form.Item label="Updated">
              {editRecord.updated} [CET] by {getUserName(editRecord.updated_by)}
            </Form.Item>

            <Form.Item label="Created">
              {editRecord.created} [CET] by {getUserName(editRecord.created_by)}
            </Form.Item>
          </>
        ) : null}

        <Form.Item name="id" label="Id" hidden>
          <Input />
        </Form.Item>
      </Form>

      <REWIREskillSelectionModalForm
        skillModalShown={skillModalShown}
        initialData={skillFromData}
        onCancel={() => setSkillModalShown(false)}
        saveFormData={(data) => setSkillFromData(data)}
        rsSkillsGroup={rsSkillsGroup}
        rsSpecificKnowledge={rsSpecificKnowledge}
        rsSpecificSkills={rsSpecificSkills}
      />
    </Modal>
  );
};
export default AddEditProfileModalForm;
