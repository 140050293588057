import React from 'react'
import {Alert, Badge, Button, Col, Container, Form, Modal, Row, Spinner, Tab, Tabs, Table, ListGroup} from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import { DragDropContext } from 'react-beautiful-dnd'
import FilterList from '@material-ui/icons/FilterList'
import ListAlt from '@material-ui/icons/ListAlt'
import Launch from '@material-ui/icons/Launch'
import Close from '@material-ui/icons/Close'

import Filter from './Filter'
import TimeTable from './TimeTable.js'
import ListOfCertifications from './ListOfCertifications.js'
import Statistics from './Statistics.js'
import dndStructure from './initStructure/dndStructure'
import sampleCertifications from './initStructure/SampleCertifications'
import CertificationForm from './CertificationForm'

import { checkColumnStructure, checkCertsStructure } from "./utils"

class Certifications extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      certifications: {},
      columns: dndStructure.columns,
      error: null,
      homeIndex: null,
      draggedCertification: null,
      sampleCertificationsLoaded: false,
      showAddModal: false,
      showBrowseModal: false,
      addModalValidated: false,
      addModalData: null,
      modalCertificationId: null,
      dataToShow: props.dbCertifications,
      checkedList: []
    }
  }

  /* Close modal data dialog */
  closeAddModal = () => this.setState({
    showAddModal: false,
    addModalValidated: false
  })

  /* Open modal data dialog */
  openAddModal = (certificationId) => {
    let modalData = null
    if (certificationId !== null) {
      modalData = this.state.certifications[certificationId]
    }
    this.setState({showAddModal: true, modalCertificationId: certificationId, addModalData: modalData})
  }

  /* Open modal for available certifications */
  openBrowseModal = () => this.setState({
    showBrowseModal: true
  })

  /* Close modal */
  closeBrowseModal = () => this.setState({
    showBrowseModal: false
  })

  /* Manage list of checked certifications */
  onCheckBoxChange = (e,id) => {
    let resultArray = this.state.checkedList
    if (!this.state.checkedList.includes(id)) {
      resultArray.push(id)
    } else {
      resultArray = this.state.checkedList.filter(checkedId => checkedId !== id)
    }
    this.setState({
      checkedList: resultArray
    })
  }

  createCertificationDnDId = (name, prefix, pool) => {
    // Create base part of item ID in DnD structure
    let newIdBase = prefix + "_" + name.replace(/ /g,"_").toLowerCase()
    var counter = 0
    while (pool.includes(newIdBase+"_"+counter)) {
      counter++
    }
    return newIdBase+"_"+counter
  }

  /* Write all selected certifications to the state */
  submitBrowseModal = () => {
    /* Prepare new empty state variables */
    let newColumns = JSON.parse(JSON.stringify(this.state.columns))
    let newCertifications = JSON.parse(JSON.stringify(this.state.certifications))

    /* Add selected certifications */
    this.state.checkedList.forEach((id) => {
      /* Create new certification object with data from selected certification */
      let newCertification = JSON.parse(JSON.stringify(this.state.dataToShow.find(i => i.id === id)))
      /* Create new unique ID of certification for use in DnD structure */
      newCertification.id = this.createCertificationDnDId(newCertification.name, "db", Object.keys(newCertifications))
      /* Add certification ID to DnD structure */
      newColumns['available'].certificationIds.push(newCertification.id)
      /* Push object containing certification data to certifications array */
      newCertifications = {
        ...newCertifications,
        [newCertification.id]: newCertification
      }
    })

    this.setState({columns: newColumns, certifications: newCertifications, checkedList: []})
    this.closeBrowseModal()
  }

  submitModal = () =>{
    if (this.state.modalCertificationId === null) {
      this.addCustomCertification(this.state.addModalData)
    } else {
      this.updateCertification(this.state.addModalData)
    }
    this.closeAddModal()
  }

  /* Write new certification data to the state */
  addCustomCertification = (input) => {
    let newCert = JSON.parse(JSON.stringify(input))
    let newState = {
      certifications: JSON.parse(JSON.stringify(this.state.certifications)),
      columns: JSON.parse(JSON.stringify(this.state.columns))
    }
    // generate local certification id
    newCert.id = this.createCertificationDnDId(newCert.name, "user", Object.keys(newState.certifications))
    // add to available columns
    newState.columns['available'].certificationIds.push(newCert.id)
    newState.certifications[newCert.id] = newCert

    this.setState(newState)
  }

  updateCertification = (input) => {
    let newCertification = JSON.parse(JSON.stringify(input))
    let oldId = this.state.modalCertificationId
    let newState = {
      certifications: JSON.parse(JSON.stringify(this.state.certifications)),
      columns: JSON.parse(JSON.stringify(this.state.columns))
    }
    newCertification.id = this.createCertificationDnDId(newCertification.name, "user", Object.keys(newState.certifications))
    let oldPosition = Object.keys(this.state.columns).find(columnId => this.state.columns[columnId].certificationIds.includes(oldId))

    // update courses
    delete newState.certifications[oldId]
    newState.certifications[newCertification.id] = newCertification

    // update columns
    let certIndex = newState.columns[oldPosition].certificationIds.indexOf(oldId)
    newState.columns[oldPosition].certificationIds[certIndex] = newCertification.id

    this.setState(newState)
  }
/*
  duplicateCertification = () => {
    let newCertification = JSON.parse(JSON.stringify(this.state.certifications[this.state.modalCertificationId]))
    let newColumns = JSON.parse(JSON.stringify(this.state.columns))
    let newPosition = null
    Object.values(this.state.columns).some(item => {
      if(item.certificationIds.includes(newCertification.id)){
        newPosition = item.id
        return true
      } else {
        return null
      }
    })
    newCertification.name += " (copy)"
    newCertification.id = this.createCertificationDnDId(newCertification.name, "user", Object.keys(this.state.certifications))
    newColumns[newPosition].certificationIds.push(newCertification.id)

     this.setState({
       certifications: {...this.state.certifications, [newCertification.id]: newCertification},
       columns: newColumns
     })
    this.closeAddModal()
  }
*/
  removeForeverCertification = (certification) => {
    let newState = JSON.parse(JSON.stringify(this.state))
    newState.columns[certification.position].certificationIds.splice( newState.columns[certification.position].certificationIds.indexOf(certification.id), 1 )
    delete newState.certifications[certification.id]
    this.setState({columns: newState.columns, certifications: newState.certifications})
  }

  removeCertification = (certification) => {
    let newPosition = 'available'
    let newColumns = JSON.parse(JSON.stringify(this.state.columns))
    newColumns[certification.position].certificationIds.splice( newColumns[certification.position].certificationIds.indexOf(certification.id), 1 )
    newColumns[newPosition].certificationIds.push(certification.id)
    this.setState({columns: newColumns})
  }

  importData = (input) => {
    try {
      const newData = JSON.parse(input)
      if (!newData.hasOwnProperty("certifications")) {
        this.setState({error:{message: "Wrong input file format!"}})
        return
      }
      // check valid position and item structure
      const columnTest = checkColumnStructure(newData.certifications, dndStructure.columns)
      const certificationsTest = checkCertsStructure(newData.certifications)
      if (columnTest && certificationsTest) {

        // prepare of writing to the state
        let newState = {
          certifications: JSON.parse(JSON.stringify(this.state.certifications)),
          columns: JSON.parse(JSON.stringify(this.state.columns))
        }

        // testing id duplicity and generating new id
        newData.certifications.forEach(newCert => {

          /* Create new unique ID of certification for use in DnD structure */
          newCert.id = this.createCertificationDnDId(newCert.name, "import", Object.keys(newState.certifications))

          // saving to the new state object
          newState.columns[newCert.position].certificationIds.push(newCert.id)
          delete newCert.position
          newState.certifications[newCert.id] = newCert

        })
        // write to the state
        this.setState(newState)
      } else {
        this.setState({error:{message: "Wrong input file format!"}})
      }
    } catch(e) {
      console.log(e)
      this.setState({error:{message: "Sorry, something went wrong!"}})
    }
  }

  loadSamples = () => {
  // slouzi pro upload sample courses
    if (typeof(sampleCertifications) !== "undefined" && sampleCertifications !== null && sampleCertifications.length !== 0){
      let sortedSampleCertifications = sampleCertifications.sort((a,b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0))

      /* Prepare new empty state variables */
      let newColumns = JSON.parse(JSON.stringify(this.state.columns))
      let newCertifications = JSON.parse(JSON.stringify(this.state.certifications))

      /* Add selected certifications */
      sortedSampleCertifications.forEach((newCertification) => {
        /* Create new unique ID of certification for use in DnD structure */
        newCertification.id = this.createCertificationDnDId(newCertification.name, "sample", Object.keys(newCertifications))
        /* Add training ID to DnD structure */
        newColumns['available'].certificationIds.push(newCertification.id)
        /* Push object containing training data to trainings array */
        newCertifications[newCertification.id] = newCertification
      })

      this.setState({columns: newColumns, certifications: newCertifications, sampleCertificationsLoaded: true})
    } else {
      this.setState({error:{message: "There are no sample certifications available!"}})
    }
  }

  onDragStart = (start) => {
    const homeIndex = start.source.droppableId
    const draggedCertification = this.state.certifications[start.draggableId].id
    this.setState({
      homeIndex,
      draggedCertification,
    })
  }

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result
    if (!destination) {
      this.setState({
        homeIndex: null,
        draggedCertification: null
      })
      return
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      this.setState({
        homeIndex: null,
        draggedCertification: null
      })
      return
    }
    const start = this.state.columns[source.droppableId]
    const finish = this.state.columns[destination.droppableId]
    if (start === finish) {
      const newCertificationIds = Array.from(start.certificationIds)
      newCertificationIds.splice(source.index, 1)
      newCertificationIds.splice(destination.index, 0, draggableId)
      const newColumn = {
        ...start,
        certificationIds: newCertificationIds
      }
      const newState = {
        ...this.state,
        homeIndex: null,
        draggedCertification: null,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn
        }
      }
      this.setState(newState)
      return
    }

    // Moving from one list to another
    const startCertificationIds = Array.from(start.certificationIds)
    startCertificationIds.splice(source.index, 1)
    const newStart = {
      ...start,
      certificationIds: startCertificationIds
    }

    const finishCertificationIds = Array.from(finish.certificationIds)
    finishCertificationIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      certificationIds: finishCertificationIds
    }

    const newState = {
      ...this.state,
      homeIndex: null,
      draggedCertification: null,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }
    this.setState(newState)
  }

  handleFilterChange = (newData) => {
    this.setState({dataToShow: newData})
  }

  resetFilter = () => {
    /* Show all certifications */
    this.setState({dataToShow: this.props.dbCertifications})
    /* Unselect all filter options */
    let items = document.getElementsByName('filter-checkbox')
    for (let i=0; i<items.length; i++) {
      if(items[i].type === 'checkbox') {
        items[i].checked = false
      }
    }
  }

  render(){
    const { columns, error, certifications, addModalData, draggedCertification, showAddModal, showBrowseModal, addModalValidated, modalCertificationId, sampleCertificationsLoaded, dataToShow, checkedList } = this.state
    const { dbCertifications, rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, rsProfiles, dbDomains, dbECSFRoles } = this.props

    // const cutLongString = (longString, newLength) => {
    //   if (longString.length > newLength) {
    //     return longString.substr(0, newLength) + "..."
    //   } else {
    //     return longString
    //   }
    // }

    // helper for display selected skills/knowledge by each skill group
    const selectedSkillsList = (formData, category, groupId) => {
      let output = []
      if (category === "skills") {
      rsSkillsGroup.find(i => i.id === groupId).specific_skills.forEach((skillId, index) => {
          if (formData.specific_skills && formData.specific_skills.includes(skillId)) {
          output.push(<li key={index}>{rsSpecificSkills.find(x => x.id === skillId).name}</li>)
          }
      })
      } else {
      rsSkillsGroup.find(i => i.id === groupId).specific_knowledge.forEach((knowledgeId, index) => {
          if (formData.specific_skills && formData.specific_knowledge.includes(knowledgeId)) {
          output.push(<li key={index}>{rsSpecificKnowledge.find(x => x.id === knowledgeId).name}</li>)
          }
      })
      }
      return output.length ? <ul>{output}</ul> : <div className="empty">none</div>
  }

    // skryva již vybrané topicy ze select nabidky
    //const reducedTopics = () => {
      // let output = topcs.filter((topic, index) => {
      //   return addModalData.topics.find(o => o.topicId === index) === undefined
      // })
      // return output
      //return topics
    //}

    return (
      <div className="main">
        {error ? (
          <div className="main_loading">
            <Alert variant="danger">{error.message}</Alert>
            <div><Button variant="link" onClick={()=>this.setState({error:null})}>Go back</Button></div>
          </div>
        ) : (
          <Container fluid>
            <Row>
              <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                <ListOfCertifications columns={columns} certifications={certifications}
                  draggedCertification={draggedCertification}
                  sampleCertificationsLoaded={sampleCertificationsLoaded}
                  addCustomCertification={this.addCustomCertification}
                  removeCertification={this.removeForeverCertification}
                  loadSamples={this.loadSamples}
                  openAddModal={this.openAddModal}
                  openBrowseModal={this.openBrowseModal}/>
                <TimeTable columns={columns} certifications={certifications}
                  draggedCertification={draggedCertification}
                  removeCertification={this.removeCertification}
                  importData={this.importData}
                  openAddModal={this.openAddModal}/>
                <Statistics rsSkillsGroup={rsSkillsGroup}
                  rsProfiles={rsProfiles}
                  certifications={columns['selected'].certificationIds.map(certificationId => certifications[certificationId])}/>
              </DragDropContext>
            </Row>
          </Container>
        )}

        <Modal show={showAddModal} onHide={this.closeAddModal} backdrop="static" size="xl" animation={false} centered className={"modal-add-course"} >

          {/* <Form noValidate validated={addModalValidated} onSubmit={this.submitModal}> */}

            <Modal.Header closeButton className={modalCertificationId === null ? "" : " edit"}>
              <Modal.Title>{modalCertificationId === null ? "Add new certification" : "Edit certification"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <CertificationForm saveLocally={(newData)=>{this.setState({addModalData: newData}, () => {this.submitModal()})}} formData={addModalData} userDataMerged={null} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} dbDomains={dbDomains} dbECSFRoles={dbECSFRoles} authData={null} hideForm={()=>this.closeAddModal()} showMessage={null} getPublicData={null}></CertificationForm>

            </Modal.Body>
            {/* <Modal.Footer>
              {modalCertificationId === null ? "" : <Button variant="outline-dark" className="left" onClick={this.duplicateCertification}>Duplicate</Button>}
              <Button variant="secondary" onClick={this.closeAddModal}>Close</Button>
              <Button variant="primary" type="submit">Save</Button>
            </Modal.Footer>
          </Form> */}
        </Modal>

        <Modal show={showBrowseModal} onHide={this.closeBrowseModal} backdrop="static" size="xl" animation={false} centered className={"modal-add-course"} >
          <Form noValidate validated={addModalValidated} onSubmit={this.submitBrowseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Browse available certifications</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tab.Container defaultActiveKey="#item0">
              <Row>
                <Col className="no-padding-right" sm={3}>
                  <div className="col-header">
                    <h5 className="text"><FilterList/>Filter by</h5>
                    <Button variant="outline-secondary" className="browse-header-button" size="sm" onClick={this.resetFilter}><div className="material-icon-wrapper"><Close fontSize='small'/>Reset</div></Button>
                  </div>
                  <div className="content-scroll">
                    <Filter data={dbCertifications} skillsGroup={rsSkillsGroup} toShow={this.handleFilterChange}/>
                  </div>
                </Col>
                <Col sm={3} className="min-padding-left">
                  <div className="col-header">
                    <h5 className='text'><ListAlt/>Certifications</h5>
                    <Button variant="link" className="browse-header-button" disabled size="sm">{dataToShow.filter((certification) => certification.archived === null).length}/{dbCertifications.filter((certification) => certification.archived === null).length}</Button>
                  </div>
                  <ListGroup className=" content-scroll">
                    {!dataToShow.length ? (
                      <div className="placeholder">
                        <Spinner animation="grow" /><br/>No certification matches the selected filter!
                      </div>
                    ) : (
                      dataToShow.filter((certification) => certification.archived === null).map((item) => (
                        <ListGroup.Item action href={"#item" + item.id} key={item.id} className={checkedList.includes(item.id) ? "selected" : ""} >
                          <Form.Check
                            inline
                            label={item.name}
                            name="certification-checkbox"
                            type="checkbox"
                            checked={checkedList.includes(item.id) ? true : false}
                            id={item.id}
                            onChange={(e)=>this.onCheckBoxChange(e, item.id)}
                          />
                        </ListGroup.Item>
                      ))
                    )}
                  </ListGroup>
                </Col>
                <Col sm={6}>
                  <h5 className="col-header">&nbsp;</h5>
                  <Tab.Content className="content-scroll">
                    <Tab.Pane eventKey="#item0">
                      <div className="placeholder" style={{paddingTop: "40px"}}>
                        Nothing selected
                      </div>
                    </Tab.Pane>
                    {dataToShow.map((item) => (
                      <Tab.Pane eventKey={"#item" + item.id} key={item.id}>
                        <h1>{item.name}</h1>
                        <Tabs
                          defaultActiveKey="overview"
                          id="uncontrolled-tab-certifications"
                          className="mb-3"
                        >
                          <Tab eventKey="overview" title="Overview">
                            <h5 variant="secondary">Description</h5>
                            <p>{item.description}</p>
                            <Button variant="info" href={item.link} target="_blank"><div className="material-icon-wrapper"><Launch fontSize="small"/>Website</div></Button>
                            <p>&nbsp;</p>
                            <h5>Rewire skill groups</h5>
                              <div className="skills-list">
                              {item.skills_group.sort((a, b) => rsSkillsGroup.find(i => i.id === a).name.localeCompare(rsSkillsGroup.find(i => i.id === b).name)).map((groupId) => {
                              let color = rsSkillsGroup.find(i => i.id === groupId).color
                              return (
                                  <span key={groupId}>
                                  <Badge data-tip data-for={"Tooltip-skillgroup-" + groupId} style={{backgroundColor: color === "transparent" ? "#fafafa" : color}}>
                                      {rsSkillsGroup.find(i => i.id === groupId).name}
                                  </Badge>
                                  <ReactTooltip className="tooltip" id={"Tooltip-skillgroup-" + groupId} place="top" effect="solid">
                                      <h5>{rsSkillsGroup.find(i => i.id === groupId).name}</h5>
                                      <h6>Included skills:</h6>
                                      {selectedSkillsList(item, "skills", groupId)}
                                      <h6>Included knowledge:</h6>
                                      {selectedSkillsList(item, "knowledge", groupId)}
                                  </ReactTooltip>
                                  </span>
                              )
                              })}
                              </div>
                          </Tab>
                          <Tab eventKey="details" title="Details">
                            <Table striped bordered hover className="details">
                              <tbody>
                                <tr>
                                  <td>Organization</td>
                                  <td>{item.organization}</td>
                                </tr>
                                <tr>
                                  <td>Main Topics</td>
                                  <td>{item.main_topics}</td>
                                </tr>
                                <tr>
                                  <td>Language</td>
                                  <td>
                                    {item.language && item.language.length > 1 ? (
                                      <ul>
                                        {item.language.map((lang, index) => (
                                          <li key={index}>{lang}</li>
                                        ))}
                                      </ul>
                                    ) : (
                                      item.language || "N/A"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Level</td>
                                  <td>{item.level}</td>
                                </tr>
                                <tr>
                                  <td>Duration</td>
                                  <td>{item.duration}</td>
                                </tr>
                                <tr>
                                  <td>e-Competences (from e-CF)</td>
                                  <td>
                                    <ul>
                                    {item.role_of_the_ecsf.map((item, index) => (
                                      <li key={index}>
                                        {item}
                                      </li>
                                    ))}
                                    </ul>
                                  </td>
                                </tr>
                                <tr>
                                  <td>ISO 17024</td>
                                  <td>
                                  {/* Print 0 or 1 as No or Yes */}
                                  {item.iso_17024 === 0 ? "No" : "Yes"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Domain</td>
                                  <td>
                                    {item.domain.length > 1 ? (
                                      <ul>
                                      {item.domain.map((item, index) => (
                                        <li key={index}>
                                          {item}
                                        </li>
                                      ))}
                                      </ul>
                                    ) : (item.domain.toString())}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Prerequisites</td>
                                  <td>{item.prerequisites}</td>
                                </tr>
                                <tr>
                                  <td>Type/Format</td>
                                  <td>{item.type_format}</td>
                                </tr>
                                <tr>
                                  <td>Price</td>
                                  <td>{item.price} €</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Tab>
                        </Tabs>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
          <Modal.Footer>
            {checkedList.length} certifications selected &nbsp;&nbsp;
            <Button variant="secondary" onClick={this.closeBrowseModal}>Close</Button>
            <Button variant="primary" type="submit" disabled={!checkedList.length}>Load</Button>
          </Modal.Footer>
          </Form>
        </Modal>
      </div>
    )
  }

}

export default Certifications
