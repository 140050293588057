import React from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'


class SkillSelectionModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        skills_group: this.props.formData.skills_group,
        specific_skills: this.props.formData.specific_skills,
        specific_knowledge: this.props.formData.specific_knowledge
      },
      opened: false
    }
  }

  hideModal = () => {
    this.props.saveFormData(this.state.formData)
    this.props.hideSkillModal()
  }

  resetSkills = () => {
    let newData = JSON.parse(JSON.stringify(this.state.formData))
    newData.skills_group = []
    newData.specific_skills = []
    newData.specific_knowledge = []
    this.setState({formData: newData})
  }

  // helper function to update an array - add or remove element
  updateArray = (inputArray, value) => {
    if (inputArray.includes(value)) {
      // remove from array
      inputArray = inputArray.splice(inputArray.indexOf(value), 1)
    } else {
      // add to array and sort acs
      inputArray.push(value)
      inputArray.sort((a, b) => a - b)
    }
    return inputArray
  }

  // catch change in skills selection modal
  changeSkills = (target, id, groupId) => {
    //copy state formdata object to new temporary variable
    let newData = JSON.parse(JSON.stringify(this.state.formData))
    // update new state object
    if (target === "skills_group") {
      // if unchecking, uncheck specific items too
      if (newData.skills_group.includes(groupId)) {
        newData.specific_skills = []
        newData.specific_knowledge = []
      }
      this.updateArray(newData.skills_group, groupId)
    } else if (target === "spec_skill") {
      if (!newData.skills_group.includes(groupId)) {
        this.updateArray(newData.skills_group, groupId)
      }
      this.updateArray(newData.specific_skills, id)
    } else if (target === "spec_knowledge") {
      if (!newData.skills_group.includes(groupId)) {
        this.updateArray(newData.skills_group, groupId)
      }
      this.updateArray(newData.specific_knowledge, id)
    }
    // store updated state object to state
    this.setState({formData: newData})
  }


  render(){
    const { rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, showSkillsModal } = this.props
    const { formData, opened } = this.state

    // set up-to-date form data
    if (!opened && showSkillsModal) {

    }

    return (
      <Modal
        show={showSkillsModal}
        onHide={this.hideModal}
        centered
        dialogClassName="skill-selection-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>REWIRE skills selection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/*<h5>Soft skills</h5>*/}
          <Table size="sm" className="skills-group">
            <thead>
              <tr>
                <th>REWIRE skill group</th>
                <th>ENISA skills</th>
                <th>ENISA knowledge</th>
              </tr>
            </thead>
            <tbody>
              {rsSkillsGroup.filter((group) => group.archived === null).sort((a, b) => a.name.localeCompare(b.name)).map((skillGroup, index) => {
                let rows = Math.max(skillGroup.specific_knowledge.length, skillGroup.specific_skills.length)
                rows = rows ? rows : 1
                return Array.from(Array(rows)).map((e,indx) => {
                  return (
                    <tr key={index+"-"+indx} style={{backgroundColor: skillGroup.color}} className={rows === 1 ? "first-row last-row" : indx === 0 ? "first-row" : indx === rows-1 ? "last-row" : "" }>
                      {indx === 0 ? (
                        <td rowSpan={rows} >
                          <Form.Check id={index+"-skill_group"} label={skillGroup.name} onChange={()=>this.changeSkills("skills_group", null, skillGroup.id)} checked={formData.skills_group.includes(skillGroup.id)} />
                        </td>
                      ) : null}
                      <td className="middle-column">
                        {skillGroup.specific_skills.length > indx ? (
                          <Form.Check id={index+"-"+indx+"-spec_skill"} label={rsSpecificSkills.find(x => x.id === skillGroup.specific_skills[indx]).name} onChange={()=>this.changeSkills("spec_skill", rsSpecificSkills.find(x => x.id === skillGroup.specific_skills[indx]).id, skillGroup.id)} checked={formData.specific_skills.includes(skillGroup.specific_skills[indx])} />
                        ) : null}
                      </td>
                      <td >
                        {skillGroup.specific_knowledge.length > indx ? (
                          <Form.Check id={index+"-"+indx+"-spec_knowledge"} label={rsSpecificKnowledge.find(x => x.id === skillGroup.specific_knowledge[indx]).name} onChange={()=>this.changeSkills("spec_knowledge", rsSpecificKnowledge.find(x => x.id === skillGroup.specific_knowledge[indx]).id, skillGroup.id)} checked={formData.specific_knowledge.includes(skillGroup.specific_knowledge[indx])} />
                        ) : null}
                      </td>
                    </tr>
                  )
                })
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.resetSkills}>Clear</Button>
          <Button variant="primary" onClick={this.hideModal}>OK</Button>
        </Modal.Footer>
      </Modal>

    )

  }
}

export default SkillSelectionModal
