import { EditOutlined } from "@ant-design/icons"
import { Button, Card, Checkbox, Tooltip, Typography } from "antd"

export const SkillList = ( {
	skills,
	rsSkillsGroup,
	onSkillCheckboxChange,
	onSkillEdit,
} ) => {
	return <div className={ "skill-list" }>
		{ skills.map( skill =>
			<Card
				key={ skill.id }
				className={ `skill-list-item${!skill.enabled ? " disabled" : ""}` }
				size={ "default" }
				title={ 
					<div className={ "skill-list-item-title-container" }>
						<Tooltip
							title={ skill.enabled ? "Disable skill" : "Enable skill" }
						>
							<Checkbox
								className={ "skill-list-item-checkbox" }
								size={ "large" }
								checked={ skill.enabled }
								onChange={ e => onSkillCheckboxChange( e, skill ) }
							/>
						</Tooltip>
						<Typography.Title
							className={ "skill-list-item-title" }
							level={ 5 }
						>
							{ skill.name }
						</Typography.Title>
					</div>
				 }
				extra={ 
					<div className={ "skill-list-item-actions" }>
						<Tooltip
							title={ "Edit skill" }
						>
							<Button
								className={ "skill-list-item-action-edit" }
								icon={ <EditOutlined /> }
								size={ "small" }
								onClick={ e => onSkillEdit( e, skill ) }
							/>
						</Tooltip>
					</div>
				 }
			>
				<ul>
					{ skill.skills_group.map( skillGroupId =>
						<li
							key={ `skill_group${skillGroupId}` }
							className={ "skill-list-item-skill-group-name" }
						>
							{ rsSkillsGroup.find( skill => skill.id === skillGroupId ).name }
						</li>
					) }
				</ul>
			</Card>
		) }
	</div>
}
