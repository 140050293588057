import { LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import {
	Button,
	Col,
	Typography,
	Result,
	Row,
	Spin,
} from "antd"
import Axios from "axios"
import React, { useState } from "react"
import { Config } from "./config_section.js"

import "./CareerPathVut.scss"
import AddEditSkillModalForm from "./AddEditSkillModalForm"
import { SkillList } from "./SkillList"
import { Statistics } from "./Statistics"
import { TrainingsBrowserModal } from "./TrainingsBrowser/TrainingsBrowser"

const { Title } = Typography

const CareerPathVut = ( { showMessage } ) => {
	const [ skills, setSkills ] = React.useState( [] )
	const [ editRecord, setEditRecord ] = React.useState( null )
	const [ rsSkillsGroup, setRsSkillsGroup ] = React.useState( null )
	const [ rsSpecificKnowledge, setRsSpecificKnowledge ] = React.useState( null )
	const [ rsSpecificSkills, setRsSpecificSkills ] = React.useState( null )
	const [ rsProfiles, setRsProfiles ] = React.useState( null )
	const [ trainings, setTrainings ] = useState( null )
	React.useEffect( () => {
		getData()
	}, [] )

	function getData() {
		// loading data state
		setRsSkillsGroup( null )
		setRsSpecificKnowledge( null )
		setRsSpecificSkills( null )
		setRsProfiles( null )
		setTrainings( null )
		Axios.get( Config.server.getData )
			.then( ( response ) => {
				if ( response && response.data.error === false ) {
					// success data state
					setRsSkillsGroup( response.data.data.rs_skill_group )
					setRsSpecificKnowledge( response.data.data.rs_specific_knowledge )
					setRsSpecificSkills( response.data.data.rs_specific_skills )
					setRsProfiles( response.data.data.rs_profiles )
					setTrainings( response.data.data.trainings )
				} else {
					// error data state
					setRsSkillsGroup( 0 )
					setRsSpecificKnowledge( 0 )
					setRsSpecificSkills( 0 )
					setRsProfiles( 0 )
					setTrainings( 0 )
				}
			} )
			.catch( ( error ) => {
				console.log( error )
				setRsSkillsGroup( 0 )
				setRsSpecificKnowledge( 0 )
				setRsSpecificSkills( 0 )
				setRsProfiles( 0 )
				setTrainings( 0 )
			} )
	}

	const dataStatus = React.useMemo( () => {
		if (
			rsSkillsGroup === null
			&& rsSpecificKnowledge === null
			&& rsSpecificSkills === null
			&& rsProfiles === null
			&& trainings === null
		) {
			return "loading"
		} else if (
			Array.isArray( rsSkillsGroup )
			&& Array.isArray( rsSpecificKnowledge )
			&& Array.isArray( rsSpecificSkills )
			&& Array.isArray( rsProfiles )
			&& Array.isArray( trainings )
		) {
			return "ok"
		} else {
			return "error"
		}
	}, [ rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, rsProfiles, trainings ] )

	const onModalSubmit = ( values ) => {
		if ( values.id === undefined ) {
			// todo: create unique id for new record to prevent conficts
			values.id = skills.length + 1
			values.enabled = true
			setSkills( [ ...skills, values ] )
		} else {
			setSkills( skills.map( ( item ) => ( item.id === values.id ? { ...item, ...values } : item ) ) )
		}
		showMessage( { type: "success", content: "Skill saved successfully!" } )
		setEditRecord( null )
	}

	const handleSkillCheckboxChange = ( _e, skill ) => {
		setSkills( oldSkills =>
			oldSkills.map( ( oldSkill ) => {
				if ( oldSkill.id === skill.id ) {
					return { ...oldSkill, enabled: !oldSkill.enabled }
				}
				return oldSkill
			} )
		)
	}

	const handleSkillEdit = ( _e, skill ) => {
		setEditRecord( skill )
	}

	const [ missingSkillsGroup, setMissingSkillsGroup ] = useState( null )

	return (
		<section id={ "career-path-vut" }>
			<div className={ "section-container" }>
				<div className={ "text-content" }>
					<Title level={ 1 } className={ "pageTitle" }>
						{ "Career Path" }
					</Title>
					{ dataStatus === "loading"
						? (
							<div className={ "vertCenter" }>
								<Spin tip={ "Loading..." } indicator={ <LoadingOutlined style={ { fontSize: 24 } } spin /> } />
							</div>
						)
						: dataStatus === "error"
						? (
							<Result
								status={ "500" }
								title={ "500" }
								subTitle={ "Sorry, something went wrong." }
							/>
						)
						: (
							<>
								<Row>
									<Col span={ 12 } className={ "left-col" }>
										<div className={ "list-container-title" }>
											<Title level={ 2 } className={ "col-title" }>{ "Your Skills" }</Title>
										</div>
										<div className={ "list-container-actions" }>
											<Button onClick={ () => setEditRecord( "new", false ) }>
												<PlusOutlined className={ "icon-position-fix" } />
												{ "Add Skill" }
											</Button>
										</div>

										<div className={ "list-container" }>
											<SkillList
												skills={ skills }
												rsSkillsGroup={ rsSkillsGroup }
												onSkillCheckboxChange={ handleSkillCheckboxChange }
												onSkillEdit={ handleSkillEdit }
											/>
										</div>
									</Col>

									<Col span={ 12 } className={ "right-col" }>
										<div className={ "statistics-title" }>
											<Title level={ 2 } className={ "col-title" }>{ "Statistics" }</Title>
										</div>

										<Statistics
											skills={ skills.filter( skill => skill.enabled ) }
											rsSkillsGroup={ rsSkillsGroup }
											rsProfiles={ rsProfiles }
											onTrainingsSearchClick={ ( _e, missingSkillsGroup ) => {
												setMissingSkillsGroup( missingSkillsGroup )
											} }
										/>
									</Col>
								</Row>

								<AddEditSkillModalForm
									showMessage={ showMessage }
									editRecord={ editRecord }
									onCancel={ () => setEditRecord( null ) }
									onModalSubmit={ onModalSubmit }
									rsSkillsGroup={ rsSkillsGroup }
								/>
								<TrainingsBrowserModal
									showMessage={ showMessage }
									open={ missingSkillsGroup !== null }
									initialSkillsGroup={ missingSkillsGroup }
									rsSkillsGroup={ rsSkillsGroup }
									trainings={ trainings }
									onClose={ () => setMissingSkillsGroup( null ) }
								/>
							</>
						) }
				</div>
			</div>
		</section>
	)
}

export default CareerPathVut
