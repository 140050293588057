import React from 'react';
import Axios from 'axios';
import { Button, Modal, Space, Table, Tooltip, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, QuestionCircleOutlined, SyncOutlined } from '@ant-design/icons';

import { Config } from './config_section.js';

import AddEditProfileModalForm from './modals/AddEditProfileModalForm.js';

const { confirm } = Modal;
const { Title } = Typography;

const Profiles = ({ refresh, showMessage, authData, usersData, rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, rsProfiles }) => {
  const [editRecord, setEditRecord] = React.useState(null);

  function showConfirmAction(id, isArchived) {
    confirm({
      title: isArchived ? 'Are you sure you want to restore this profile?' : 'Are you sure you want to archive this profile?',
      content: (
        <div>
          <p>
            <b>Name:</b> {rsProfiles.find((obj) => obj.id === id).name}
          </p>
        </div>
      ),

      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          return await new Promise((resolve, reject) => {
            Axios.post(Config.server.archiveProfile, { ...authData, id: id }, { headers: { 'Content-Type': 'application/json' } })
              .then((response) => {
                if (response && response.data.error === false) {
                  showMessage({ type: 'success', content: `Record successfully ${isArchived ? 'restored' : 'archived'}.` });
                  refresh();
                  resolve();
                } else {
                  showMessage({ type: 'error', content: 'Something went wrong.' });
                  reject();
                }
              })
              .catch((error) => {
                console.log(error);
                showMessage({ type: 'error', content: 'Something went wrong.' });
                reject();
              });
          });
        } catch {
          showMessage({ type: 'error', content: 'Something went wrong.' });
        }
      },
    });
  }

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '48px',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Skills Group',
      dataIndex: 'skills_group',
      key: 'skills_group',
      render: (skills) => (
        <ul className="skills-group">
          {skills
            .map((skillId) => rsSkillsGroup.find((skgroup) => skgroup.id === skillId).name)
            .sort()
            .map((skillName, i) => (
              <li key={i}>{skillName}</li>
            ))}
        </ul>
      ),
    },
    {
      title: 'Updated [CET]',
      dataIndex: 'updated',
      key: 'updated',
      width: '160px',
      sorter: (a, b) => a.updated.localeCompare(b.updated),
    },
    {
      title: 'Created [CET]',
      dataIndex: 'created',
      key: 'created',
      width: '160px',
      sorter: (a, b) => a.created.localeCompare(b.created),
    },
    {
      title: 'Archived [CET]',
      dataIndex: 'archived',
      key: 'archived',
      width: '160px',
      sorter: (a, b) => a.archived.localeCompare(b.archived),
    },
    {
      title: 'Action',
      key: 'action',
      width: '60px',
      render: (record) => (
        <Space size="small">
          <Tooltip title="Edit row">
            <EditOutlined className="editIcon" onClick={() => setEditRecord(rsProfiles.find((obj) => obj.id === record.id))} />
          </Tooltip>
          <Tooltip title={record.archived ? 'Restore row' : 'Archive row'}>
            {record.archived ? (
              <SyncOutlined className="restoreIcon" onClick={() => showConfirmAction(record.id, true)} />
            ) : (
              <DeleteOutlined className="deleteIcon" onClick={() => showConfirmAction(record.id, false)} />
            )}
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div id="profiles">
      <Title className="pageTitle">ENISA Profiles</Title>

      <div className="dataHandleButtons">
        <Space>
          <Button onClick={() => setEditRecord('new')}>
            <PlusOutlined className="icon-position-fix" /> Add Profile
          </Button>
        </Space>
      </div>
      <Table size="small" className="data-table" dataSource={rsProfiles} pagination={false} columns={tableColumns} rowClassName={(profile) => (profile.archived ? "archived-row" : "")} />

      {/* modals */}
      <AddEditProfileModalForm
        editRecord={editRecord}
        refresh={refresh}
        showMessage={showMessage}
        onCancel={() => setEditRecord(null)}
        authData={authData}
        rsSkillsGroup={rsSkillsGroup}
        rsProfiles={rsProfiles}
        rsSpecificKnowledge={rsSpecificKnowledge}
        rsSpecificSkills={rsSpecificSkills}
        usersData={usersData}
      />
    </div>
  );
};

export default Profiles;
