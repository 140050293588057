import React from "react";
import {PageHeader, Tabs} from "antd";
import QueueAnim from "rc-queue-anim";

import { useEffect, useRef } from 'react';

const RoleInfo = ({onRendered, roleData, items}) => {

    const ref = useRef(null);

    const scrollToElement = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToElement();
    }, []);


    useEffect(() => {
        onRendered();
    }, [onRendered]);

    return (
        <div className={"spacingDiv"}>
            <QueueAnim className="queue-simple">
                <div key={"a"}>
                    <PageHeader title={roleData.title} />
                    <Tabs defaultActiveKey="8" items={items} className={"rolesTab"} centered={true}/>
                </div>
            </QueueAnim>
        </div>
    );
}

export default RoleInfo;