import React from 'react'
import { Typography } from 'antd'
import { Link } from 'react-router-dom'

import './CSPIntersection.scss'
import imgManualSelect from '../../../assets/manual-selection.png'
import imgAISelect from '../../../assets/ai-selection.png'

const { Paragraph, Title } = Typography


const CSPIntersection = () => {

  return (
    <section id="csp-intersection">
      <div className="section-container">
        <div className="text-content">
          <Title level={1} className="pageTitle">
            Curricula Designer
          </Title>

          <Paragraph>
            Welcome to the Curricula Designer! Here you can choose between two different ways to design your curricula.
          </Paragraph>

          <div className="button-container">

            <Link to="/csprofiler/curricula">
              <div className="link-button" id="manual-button">
                <div className="img-container"><img src={imgManualSelect} alt="Manual Design" /></div>
                <div className="description">Manual Design</div>
              </div>
            </Link>
            
            <Link to="/csprofiler/study-profiler">
              <div className="link-button" id="ai-button">
                <div className="img-container"><img src={imgAISelect} alt="AI-driven Design" /></div>
                <div className="description">AI-driven Design</div>
              </div>
            </Link>
            
          </div>

        </div>
      </div>

    </section>
  )
}

export default CSPIntersection
