import { ServerURL } from '../../config.js'

let subfolder = "admin"

export const Config = {
	server	: {
		"getData"							: ServerURL+subfolder+"/getData.php",
		"addCertification"					: ServerURL+subfolder+"/addCertification.php",
		"editCertification"					: ServerURL+subfolder+"/editCertification.php",
		"addStudyProgram"					: ServerURL+subfolder+"/addStudyProgram.php",
		"editStudyProgram"					: ServerURL+subfolder+"/editStudyProgram.php",
		"addTraining"						: ServerURL+subfolder+"/addTraining.php",
		"editTraining"						: ServerURL+subfolder+"/editTraining.php",
		"addSkillsGroup"					: ServerURL+subfolder+"/addSkillsGroup.php",
		"editSkillsGroup"					: ServerURL+subfolder+"/editSkillsGroup.php",
		"addProfile"						: ServerURL+subfolder+"/addProfile.php",
		"editProfile"						: ServerURL+subfolder+"/editProfile.php",
		"getUsersData"						: ServerURL+subfolder+"/getUsersData.php",
		"updateUsersData"					: ServerURL+subfolder+"/updateUsersData.php",
		"getVisitorsStats"					: ServerURL+subfolder+"/getVisitorsStats.php",
		"addDomain"						: ServerURL+subfolder+"/addDomain.php",
		"editDomain"						: ServerURL+subfolder+"/editDomain.php",
		"addECSFRole"						: ServerURL+subfolder+"/addECSFRole.php",
		"editECSFRole"						: ServerURL+subfolder+"/editECSFRole.php",
		"archiveDomain"						: ServerURL+subfolder+"/archiveDomain.php",
		"archiveECSFRole"					: ServerURL+subfolder+"/archiveECSFRole.php",
		"archiveCertification"				: ServerURL+subfolder+"/archiveCertification.php",
		"archiveTraining"					: ServerURL+subfolder+"/archiveTraining.php",
		"archiveStudyProgram"				: ServerURL+subfolder+"/archiveStudyProgram.php",
		"archiveSkillsGroup"				: ServerURL+subfolder+"/archiveSkillsGroup.php",
		"archiveProfile"					: ServerURL+subfolder+"/archiveProfile.php",
	}
}
