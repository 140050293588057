import React from 'react'
import { Col, Row, Typography } from 'antd'

import logo from '../../assets/logo_dark.jpg'
import './Home.scss'

const { Paragraph, Title } = Typography


const Home = () => {

  return (
    <section id="home">
      <div className="banner">
        <div className="header-text">CyberABILITY Platform</div>
      </div>
      <div className="section-container">
        <div className="text-content">

        <Title level={1} className="pageTitle">Welcome to the REWIRE CyberABILITY Platform</Title>
          <Row>
            <Col span={6} className="left-col">
              <img src={logo} alt="rewire_logo_big"/>
            </Col>
            <Col span={18} className="right-col">

              <Paragraph>
                CyberABILITY, offered by the REWIRE Project, is a digital platform, trying to address the evolving needs of the cybersecurity sector. As a comprehensive Cybersecurity Skills Digital Observatory, it platform offers an overview of the current job market, essential competences, training courses, and certifications. CyberABILITY caters to professionals, organizations, and academic institutions, ensuring they are equipped with insights on the cybersecurity landscape.
              </Paragraph>

              <Paragraph>
                Drawing from the deliverables of the REWIRE project, CyberABILITY employs a methodology to map existing courses and certifications to a comprehensive framework.
              </Paragraph>
            </Col>
          </Row>

          <Paragraph>
            <Title level={5}>In the platform, you may find the following tools:</Title>
            <ul>
            <li>
              Cybersecurity Jobs Analyzer. Its primary function is to identify and analyze the specific cybersecurity skills required for various work roles. The tool offers a database for adding and filtering job advertisements, a visual map representation of the database, and a feature that uses Machine Learning to display the identified cybersecurity skills from selected job ads. Additionally, it integrates with the ENISA framework, allowing for a comparison of skills between the framework and actual job market demands.
            </li>
            <li>
              Cybersecurity Profiler. It is designed to map existing curricula, trainings, and certifications to specific cybersecurity roles. Built upon the foundation of the Cybersecurity Curricula Designer, the Cybersecurity Profiler extends its capabilities by integrating cybersecurity trainings and certifications in compliance with ENISA standards. It serves as a comprehensive tool, allowing users to identify recommended courses for specific roles, design study programs, and maintain a database of existing cybersecurity educational resources.
            </li>
            <li>
              ECSF Explorer. It is a tool designed to explore the ECSF (European Cybersecurity Framework), in a more interactive way. The tool allows one to explore the 12 ECSF profiles, to see their alternative titles, summary statement, mission, deliverables, main tasks, key skills, key knowledge and e-competences.
            </li>
            </ul>
          </Paragraph>


        </div>
      </div>

    </section>
  )
}

export default Home
