import { useEffect, useState } from "react";
import { Modal, Button, Checkbox, Table } from "antd";
import "../Admin.scss";

const REWIREskillSelectionModalForm = ({ skillModalShown, initialData, onCancel, saveFormData, rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills }) => {
  const [formData, setFormData] = useState({
    skills_group: [],
    specific_skills: [],
    specific_knowledge: [],
  });

  const resetSkills = () => {
    setFormData({
      skills_group: [],
      specific_skills: [],
      specific_knowledge: [],
    });
  };

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const hideModal = () => {
    saveFormData(formData);
    onCancel();
  };

  const updateArray = (inputArray, value) => {
    return inputArray.includes(value) ? inputArray.filter((item) => item !== value) : [...inputArray, value];
  };

  const changeSkills = (target, id, groupId) => {
    let newData = { ...formData };
    if (target === "skills_group") {
      if (newData.skills_group.includes(groupId)) {
        const group = rsSkillsGroup.find((skillgroup) => skillgroup.id === groupId);
        newData.specific_skills = newData.specific_skills.filter((skill) => !group.specific_skills.includes(skill));
        newData.specific_knowledge = newData.specific_knowledge.filter((knowledge) => !group.specific_knowledge.includes(knowledge));
      }
      newData.skills_group = updateArray(newData.skills_group, groupId);
    } else if (target === "skills") {
      if (!newData.skills_group.includes(groupId)) {
        newData.skills_group = updateArray(newData.skills_group, groupId);
      }
      newData.specific_skills = updateArray(newData.specific_skills, id);
    } else if (target === "knowledge") {
      if (!newData.skills_group.includes(groupId)) {
        newData.skills_group = updateArray(newData.skills_group, groupId);
      }
      newData.specific_knowledge = updateArray(newData.specific_knowledge, id);
    }

    setFormData(newData);
  };

  const tableData = rsSkillsGroup.filter((skillGroup) => skillGroup.archived === null).flatMap((skillGroup) => {
    const rows = Math.max(skillGroup.specific_knowledge.length || 1, skillGroup.specific_skills.length || 1);

    return Array.from({ length: rows }).map((_, index) => ({
      key: `${skillGroup.id}-${index}`,
      skillGroup,
      skill: skillGroup.specific_skills[index] ? rsSpecificSkills.find((skill) => skill.id === skillGroup.specific_skills[index]) : null,
      knowledge: skillGroup.specific_knowledge[index] ? rsSpecificKnowledge.find((knowledge) => knowledge.id === skillGroup.specific_knowledge[index]) : null,
    }));
  });

  const columns = [
    {
      title: "REWIRE Skill Group",
      dataIndex: "skillGroup",
      render: (_, record, index) => {
        const sameGroupRows = tableData.filter((row) => row.skillGroup && row.skillGroup.id === record.skillGroup?.id);
        const firstOccurrenceIndex = tableData.findIndex((row) => row.skillGroup && row.skillGroup.id === record.skillGroup?.id);
        const rowSpan = index === firstOccurrenceIndex ? sameGroupRows.length : 0;

        return rowSpan > 0 ? (
          <Checkbox checked={formData.skills_group.includes(record.skillGroup.id)} onChange={() => changeSkills("skills_group", null, record.skillGroup.id)}>
            {record.skillGroup.name}
          </Checkbox>
        ) : null;
      },
      onCell: (record, index) => {
        const firstOccurrenceIndex = tableData.findIndex((row) => row.skillGroup && row.skillGroup.id === record.skillGroup?.id);
        const rowSpan = index === firstOccurrenceIndex ? tableData.filter((row) => row.skillGroup && row.skillGroup.id === record.skillGroup?.id).length : 0;

        return {
          rowSpan,
          style: {
            backgroundColor: record.skillGroup ? record.skillGroup.color : "#ffffff",
          },
        };
      },
      width: "30%",
    },
    {
      title: "ENISA Skills",
      dataIndex: "skill",
      render: (skill, record) =>
        skill ? (
          <Checkbox checked={formData.specific_skills.includes(skill.id)} onChange={() => changeSkills("skills", skill.id, record.skillGroup.id)}>
            {skill.name}
          </Checkbox>
        ) : null,
      onCell: (record) => ({
        style: {
          backgroundColor: record.skillGroup ? record.skillGroup.color : "#ffffff",
        },
      }),
      width: "35%",
    },
    {
      title: "ENISA Knowledge",
      dataIndex: "knowledge",
      render: (knowledge, record) =>
        knowledge ? (
          <Checkbox checked={formData.specific_knowledge.includes(knowledge.id)} onChange={() => changeSkills("knowledge", knowledge.id, record.skillGroup.id)}>
            {knowledge.name}
          </Checkbox>
        ) : null,
      onCell: (record) => ({
        style: {
          backgroundColor: record.skillGroup ? record.skillGroup.color : "#ffffff",
        },
      }),
      width: "35%",
    },
  ];

  return (
    <Modal
      open={skillModalShown}
      onCancel={()=>{
        setFormData(initialData);
        onCancel();
      }}
      centered
      width={850}
      title="REWIRE Skills Selection"
      footer={[
        <Button key="clear" onClick={resetSkills}>
          Reset
        </Button>,
        <Button key="submit" type="primary" onClick={hideModal}>
          Save
        </Button>,
      ]}
    >
      <Table dataSource={tableData} columns={columns} rowClassName={(record) => (record.skillGroup ? "group-row" : "")} pagination={false} bordered />
    </Modal>
  );
};

export default REWIREskillSelectionModalForm;
