import { InfoCircleOutlined } from "@ant-design/icons"
import { debounce } from "@material-ui/core"
import {
	Button,
	Card,
	Empty,
	Popover,
	Spin,
	Table,
	Tag,
	Typography,
} from "antd"
import Axios from "axios"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { csSkillsSet, itSkillsSet, softSkillsSet } from "../../Utils"
import { Config } from "../config_section"

const isOutdatedTraining = ( training ) => {
	if ( training.timing === "Available online" || training.timing === "On demand" ) {
		return false
	}
	const now = moment()

	return training.dates.every( strDate => {
		return moment( strDate, "YYYY-MM-DD" ).isBefore( now, "date" )
	} )
}

const getTrainingFocuses = ( training ) => {
	let itCount = 0
	let softCount = 0
	let csCount = 0
	training.skills_group.forEach( group => {
		itCount = itCount + ( itSkillsSet.includes( group ) ? 1 : 0 )
		softCount = softCount + ( softSkillsSet.includes( group ) ? 1 : 0 )
		csCount = csCount + ( csSkillsSet.includes( group ) ? 1 : 0 )
	} )

	const focuses = []

	itCount >= softCount && itCount >= csCount && focuses.push( "IT" )
	softCount >= itCount && softCount >= csCount && focuses.push( "Soft" )
	csCount >= softCount && csCount >= itCount && focuses.push( "CS" )

	return focuses
}

const filterTrainings = ( trainings, filter ) => {
	const skillsGroup = [ ...filter.itSkillsGroup, ...filter.csSkillsGroup, ...filter.sfSkillsGroup ]

	return trainings.filter( ( training ) => {
		if ( !filter.showOutdated && isOutdatedTraining( training ) ) {
			return false
		}
		if ( filter.countries.length && !filter.countries.includes( training.country ) ) {
			return false
		}
		if ( filter.languages.length && !training.language.some( ( lang ) => filter.languages.includes( lang ) ) ) {
			return false
		}
		if ( filter.organizers.length && !filter.organizers.includes( training.organizer ) ) {
			return false
		}
		if ( filter.typeFormats.length && !filter.typeFormats.includes( training.type_format ) ) {
			return false
		}
		if ( filter.contentTypes.length && !filter.contentTypes.includes( training.content_type ) ) {
			return false
		}
		if ( filter.canLeadToCertifications.length && !filter.canLeadToCertifications.includes( training.can_lead_to_certification ) ) {
			return false
		}
		if ( filter.includesExamsForCertifications.length && !filter.includesExamsForCertifications.includes( training.includes_exams_for_certification ) ) {
			return false
		}
		if ( filter.trainingFocus.length && !getTrainingFocuses( training ).some( focus => filter.trainingFocus.includes( focus ) ) ) {
			return false
		}
		if ( !( Number( training.price ) >= filter.price[0] && Number( training.price ) <= filter.price[1] ) ) {
			return false
		}
		if ( !( Number( training.duration ) >= filter.duration[0] && Number( training.duration ) <= filter.duration[1] ) ) {
			return false
		}
		if ( skillsGroup.length && !training.skills_group.some( ( skillGroup ) => skillsGroup.includes( skillGroup ) ) ) {
			return false
		}
		return true
	} )
}

const TrainingData = ( { training } ) => {
	const dataSource = [
		{ label: "Organizer", value: training.organizer },
		{ label: "Country", value: training.country },
		{ label: "Duration", value: training.duration + " hours" },
		{ label: "Timing", value: training.timing },
		{ label: "Type/Format", value: training.type_format },
		{ label: "Price", value: training.price + " €" },
		{ label: "Can lead to certification", value: training.can_lead_to_certification },
		{ label: "Includes exams for certification", value: training.includes_exams_for_certification },
		{ label: "Content type", value: training.content_type },
		{ label: "Language", value: training.language.join( ", " ) },
		{ label: "Dates", value: training.dates.join( ", " ) },
	]

	const columns = [
		{
			title: "Label",
			dataIndex: "label",
			key: "label",
		},
		{
			title: "Value",
			dataIndex: "value",
		},
	]

	return <Table
		showHeader={ false }
		size={ "small" }
		pagination={ false }
		columns={ columns }
		dataSource={ dataSource }
		rowKey={ "label" }
	/>
}

const suggestTrainings = debounce(
	( {
		filter,
		filteredTrainings,
		setSuggestedTrainings,
		setSuggestionsLoading,
		showMessage,
	} ) => {
		if ( filteredTrainings.length ) {
			const selectedSkills = [ ...filter.sfSkillsGroup, ...filter.csSkillsGroup, ...filter.itSkillsGroup ]
			if ( selectedSkills.length ) {
				void Axios.post( Config.server.suggestTrainings, {
					price: filter.price,
					duration: filter.duration,
					weight: 7,
					selectedSkills: selectedSkills,
					requiredNumOfResults: 1,
					filteredTrainings: filteredTrainings.map( training => training.id ),
				}, { headers: { "Content-Type": "application/json" } } )
					.then( ( response ) => {
						if ( response.data.data ) {
							setSuggestedTrainings( [ ...response.data.data ].flat() )
						} else {
							setSuggestedTrainings( [] )
							showMessage( { type: "error", content: "Something went wrong!" } )
						}
					} )
					.catch( ( _error ) => {
						showMessage( { type: "error", content: "Something went wrong!" } )
					} )
					.finally( () => {
						setSuggestionsLoading( false )
					} )
			} else {
				setSuggestedTrainings( [] )
				setSuggestionsLoading( false )
			}
		} else {
			setSuggestedTrainings( [] )
			setSuggestionsLoading( false )
		}
	},
	500,
)

export const Trainings = ( {
	trainings,
	filter,
	showMessage,
	rsSkillsGroup,
} ) => {
	const [ suggestedTrainings, setSuggestedTrainings ] = useState( [] )
	const [ suggestionsLoading, setSuggestionsLoading ] = useState( false )
	const filteredTrainings = useMemo( () => filterTrainings( trainings, filter ), [ trainings, filter ] )
	useEffect(
		() => {
			if ( filter.disableSuggestions ) {
				suggestTrainings.clear()
				setSuggestionsLoading( false )
				return () => {
					setSuggestionsLoading( false )
					setSuggestedTrainings( [] )
				}
			} else {
				setSuggestionsLoading( true )
				suggestTrainings( {
					filter,
					filteredTrainings,
					setSuggestedTrainings,
					showMessage,
					setSuggestionsLoading,
				} )
				return () => {
					setSuggestionsLoading( false )
					setSuggestedTrainings( [] )
				}
			}
		},
		[ filter, filteredTrainings, showMessage ],
	)
	const sortedTrainings = useMemo( () => {
		const skillGroupsFromFilter = [ ...filter.sfSkillsGroup, ...filter.csSkillsGroup, ...filter.itSkillsGroup ]

		return [ ...filteredTrainings ].sort( ( a, b ) => {
			return ( suggestedTrainings.includes( a.id ) ? 0 : 1 ) - ( suggestedTrainings.includes( b.id ) ? 0 : 1 )
		} ).map( training => {
			return {
				training,
				searchedSkillGroup: training.skills_group.filter( id => skillGroupsFromFilter.includes( id ) ),
				otherSkillGroup: training.skills_group.filter( id => !skillGroupsFromFilter.includes( id ) ),
			}
		} )
	}, [ filteredTrainings, suggestedTrainings, filter ] )

	return <div className={ "trainings-list content-scroll" }>
		{ sortedTrainings.length
			? sortedTrainings.map( ( { searchedSkillGroup, otherSkillGroup, training } ) =>
				<Card
					key={ training.id }
					size={ "small" }
					title={ 
						<div>
							<Typography.Title
								level={ 5 }
							>
								{ training.name }
							</Typography.Title>
						</div>
					 }
					extra={ 
						<div>
							<Popover
								placement={ "leftBottom" }
								arrowPointAtCenter
								content={ 
									<div className={ "training-additional-info" }>
										<div className={ "training-description" }>
											<Typography.Text>
												{ training.description }
											</Typography.Text>
										</div>

										<div className={ "training-data-content" }>
											<TrainingData training={ training } />
										</div>
										<div className={ "training-data-link" }>
											<Button type={ "link" } href={ training.link }>{ "Website" }</Button>
										</div>
									</div>
								 }
							>
								<Button
									type={ "text" }
									className={ "training-info-icon" }
									icon={ <InfoCircleOutlined /> }
									size={ "small" }
								/>
							</Popover>
						</div>
					 }
				>
					<ul>
						{ searchedSkillGroup.map( skillGroupId =>
							<li
								key={ `searched_skill_group${skillGroupId}` }
							>
								{ rsSkillsGroup.find( skill => skill.id === skillGroupId ).name }
							</li>
						) }
						{ otherSkillGroup.map( skillGroupId =>
							<li
								key={ `other_skill_group${skillGroupId}` }
								className={ "other-skill-group" }
							>
								{ rsSkillsGroup.find( skill => skill.id === skillGroupId ).name }
							</li>
						) }
					</ul>
					{ filter.showOutdated && isOutdatedTraining( training ) && <Tag color={ "orange" }>{ "Outdated" }</Tag> }
					{ suggestedTrainings.includes( training.id ) && <Tag color={ "blue" }>{ "Suggested" }</Tag> }
					{ suggestionsLoading && <Spin size={ "small" } /> }
				</Card>
			)
			: <Empty description={ "No trainings found" } /> }
	</div>
}
