import { useState, useEffect } from "react";
import { Config } from "../config_section.js";
import Axios from "axios";
import { Checkbox, Form, Input, InputNumber, Modal, Select, Button, Space, Badge } from "antd";
import "../Admin.scss";
import { languages } from "countries-list";
import REWIREskillSelectionModalForm from "./REWIREskillSelectionModalForm";

const { TextArea } = Input;

const AddEditCertificationModalForm = ({ editRecord, refresh, onCancel, showMessage, authData, rsSkillsGroup, rsProfiles, usersData, dbCertifications, rsSpecificKnowledge, rsSpecificSkills, dbDomains, dbECSFRoles }) => {
  const [modalLoading, setModalLoading] = useState(false);
  const [form] = Form.useForm();
  const [isNew, setIsNew] = useState(false);
  const [skillModalShown, setSkillModalShown] = useState(false);
  const [skillFromData, setSkillFromData] = useState();

  useEffect(() => {
    if (typeof editRecord === "object" && editRecord !== null && form.getFieldValue("id") !== editRecord.id) {
      let initialData = JSON.parse(JSON.stringify(editRecord));
      delete initialData.key;
      form.setFieldsValue(initialData);
      setIsNew(false);

      let initialSkillFormData = {
        skills_group: editRecord.skills_group || [],
        specific_skills: editRecord.specific_skills || [],
        specific_knowledge: editRecord.specific_knowledge || [],
      };
      setSkillFromData(initialSkillFormData);
    } else if (editRecord === "new") {
      setIsNew(true);
      setSkillFromData({
        skills_group: [],
        specific_skills: [],
        specific_knowledge: [],
      });
    }
  }, [editRecord, form]);

  function getUserName(id) {
    let allUsers = [...usersData.newUsers, ...usersData.adminUsers, ...usersData.blockedUsers, ...usersData.registeredUsers];
    let user = allUsers.find((u) => u.id === id);
    let output = user.admin_level > 1 ? "ADMIN " : "";
    output += user.name + " (ID " + user.id + ")";
    return output;
  }
  function nameExists(value) {
    return dbCertifications.find((e) => e.name === value) ? true : false;
  }
  const onCheck = (e) => {
    form.setFieldsValue({ iso_17024: e.target.checked ? 1 : 0 });
  };

  return (
    <Modal
      getContainer={false}
      centered
      open={editRecord !== null}
      title={isNew ? "Add a new certification" : "Edit certification"}
      width={850}
      okText="Save"
      confirmLoading={modalLoading}
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      className={isNew ? "add-certification-modal" : "edit-certification-modal"}
      onOk={() => {
        form.validateFields().then((values) => {
          setModalLoading(true);
          delete values.created;
          delete values.created_by;
          delete values.updated;
          delete values.updated_by;

          values.specific_skills = skillFromData.specific_skills || [];
          values.specific_knowledge = skillFromData.specific_knowledge || [];
          values.skills_group = skillFromData.skills_group || [];

          const reqData = {
            name: values.name,
            organization: values.organization || "",
            description: values.description || "",
            duration: values.duration || "",
            price: values.price || "",
            type_format: values.type_format || "",
            role_of_the_ecsf: values.role_of_the_ecsf || [],
            domain: values.domain || [],
            main_topics: values.main_topics || "",
            level: values.level || "",
            prerequisites: values.prerequisites || "",
            iso_17024: values.iso_17024 ? true : false,
            skills_group: values.skills_group || [],
            specific_skills: values.specific_skills || [],
            specific_knowledge: values.specific_knowledge || [],
            link: values.link || "",
            language: values.language || [],
          };
          if (isNew) {
            Axios.post(Config.server.addCertification, { ...authData, ...reqData }, { headers: { "Content-Type": "application/json" } })
              .then((response) => {
                setModalLoading(false);
                if (response && response.data.error === false) {
                  form.resetFields();
                  showMessage({ type: "success", content: "New certification successfully added." });
                  refresh();
                } else {
                  console.log("error: " + response.data.message);
                  showMessage({ type: "error", content: response.data.message.includes("exists") ? response.data.message : "Something went wrong!" });
                }
              })
              .catch((error) => {
                setModalLoading(false);
                console.log(error);
                showMessage({ type: "error", content: "Something went wrong!" });
              });
          } else {
            Axios.post(Config.server.editCertification, { ...authData, ...values }, { headers: { "Content-Type": "application/json" } })
              .then((response) => {
                setModalLoading(false);
                if (response && response.data.error === false) {
                  form.resetFields();
                  showMessage({ type: "success", content: "Updated successfully." });
                  refresh();
                } else {
                  console.log("error: " + response.data);
                  showMessage({ type: "error", content: "Something went wrong!" });
                }
              })
              .catch((error) => {
                setModalLoading(false);
                console.log(error);
                showMessage({ type: "error", content: "Something went wrong!" });
              });
          }
        });
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        name={isNew ? "add-certification-modal" : "edit-certification-modal"}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 15 }}
        labelWrap
        colon={false}
        className="content-scroll"
      >
        <Form.Item
          name="name"
          label="Title"
          rules={[
            { required: true, message: "Please enter the title" },
            { type: "string", min: 2, max: 1023 },
            () => ({
              validator(_, value) {
                if (!value || !nameExists(value) || !isNew) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("This certification is already stored in the database!"));
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="organization" label="Organization" rules={[{ required: true, message: "Please enter name of the organization.", type: "string", max: 1023 }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ type: "string", max: 1023, required: true, message: "Please enter the decription." }]}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="duration"
          label="Duration (hours)"
          rules={[
            { required: true, message: "Please enter the duration." },
            { type: "int", min: 1, max: 1023 },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="type_format" label="Format" rules={[{ type: "string" }]}>
          <Select showSearch showArrow allowClear>
            <Select.Option value="Face-to-face" label="Face-to-face" />
            <Select.Option value="Online" label="Online" />
            <Select.Option value="Hybrid" label="Hybrid" />
          </Select>
        </Form.Item>
        <Form.Item name="role_of_the_ecsf" label="e-Competences (from e-CF)" rules={[{ type: "array", max: 255 }]}>
          <Select showSearch showArrow allowClear mode="multiple" optionFilterProp={"label"} options={dbECSFRoles.filter((role) => role.archived === null).map((role) => ({ value: role.id, label: role.name }))} />
        </Form.Item>
        <Form.Item name="domain" label="Domain" rules={[{ type: "array", max: 255 }]}>
          <Select showSearch showArrow allowClear mode="multiple" optionFilterProp={"label"} options={dbDomains.filter((domain) => domain.archived === null).map((domain) => ({ value: domain.id, label: domain.name }))} />
        </Form.Item>
        <Form.Item name="main_topics" label="Main topics" rules={[{ type: "string" }]}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="level" label="Level" rules={[{ type: "string" }]}>
          <Select showSearch showArrow allowClear>
            <Select.Option value="Beginner / Novice" label="Beginner / Novice" />
            <Select.Option value="Intermediate" label="Intermediate" />
            <Select.Option value="Advanced" label="Advanced" />
            <Select.Option value="Expert" label="Expert" />
          </Select>
        </Form.Item>
        <Form.Item name="prerequisites" label="Prerequisities" rules={[{ type: "string", max: 1023 }]}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="iso_17024" label="ISO 17024" valuePropName="checked">
          <Checkbox checked={form.iso_17024 === 1 ? true : false} onChange={onCheck} />
        </Form.Item>
        <Form.Item name="language" label="Language">
          <Select showSearch showArrow allowClear mode="multiple">
            {Object.values(languages).map((language, index) => (
              <Select.Option key={index} value={language.name}>
                {language.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="price"
          label="Price"
          rules={[
            { required: true, message: "Please provide price." },
            { type: "int", min: 0 },
          ]}
        >
          <InputNumber addonAfter="€" min={0} />
        </Form.Item>
        <Form.Item name="skills_group" label="REWIRE Skills">
          <Space direction="vertical" size={16}>
            <Space wrap className="skillBadges">
              {skillFromData?.skills_group.map((skillGroup) => (
                <Badge count={rsSkillsGroup.find((x) => x.id === skillGroup).name} color={rsSkillsGroup.find((x) => x.id === skillGroup).color || "white"} key={skillGroup} className="skillBadge" />
              ))}
            </Space>
            <Button onClick={() => setSkillModalShown(true)}>
              Choose
            </Button>
          </Space>
        </Form.Item>
        <Form.Item name="link" label="Link" rules={[{ type: "string" }]}>
          <Input />
        </Form.Item>

        {typeof editRecord === "object" && editRecord !== null ? (
          <>
            <Form.Item label="Updated">
              {editRecord.updated} [CET] by {getUserName(editRecord.updated_by)}
            </Form.Item>

            <Form.Item label="Created">
              {editRecord.created} [CET] by {getUserName(editRecord.created_by)}
            </Form.Item>
          </>
        ) : null}

        <Form.Item name="id" label="Id" hidden>
          <Input />
        </Form.Item>
      </Form>

      <REWIREskillSelectionModalForm
        skillModalShown={skillModalShown}
        initialData={skillFromData}
        onCancel={() => setSkillModalShown(false)}
        saveFormData={(data) => setSkillFromData(data)}
        rsSkillsGroup={rsSkillsGroup}
        rsSpecificKnowledge={rsSpecificKnowledge}
        rsSpecificSkills={rsSpecificSkills}
      />
    </Modal>
  );
};

export default AddEditCertificationModalForm;