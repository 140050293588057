const title = 'Cybersecurity Educator';

// const alternativeTitles = [
//     'Cybersecurity Awareness Specialist',
//     'Cybersecurity Trainer',
//     'Faculty in Cybersecurity (Professor, Lecturer)'
// ];

const alternativeTitles = [
    { key: 1, title: 'Cybersecurity Awareness Specialist' },
    { key: 2, title: 'Cybersecurity Trainer' },
    { key: 3, title: 'Faculty in Cybersecurity (Professor, Lecturer)' }
];

// const summaryStatement = [
//     'Improves cybersecurity knowledge, skills and competencies of humans.'
// ];

const summaryStatement = [{ key: 1, summaryStatement: 'Improves cybersecurity knowledge, skills and competencies of humans.' }];

// const mission = [
//     'Designs, develops and conducts awareness, training and educational programmes in cybersecurity and data protection-related topics.',
//     'Uses appropriate teaching and training methods, techniques and instruments to communicate and enhance the cybersecurity culture, capabilities, knowledge and skills of human resources.',
//     'Promotes the importance of cybersecurity and consolidates it into the organisation.'
// ];

const mission = [
    { key: 1, mission: 'Designs, develops and conducts awareness, training and educational programmes in cybersecurity and data protection-related topics.' },
    { key: 2, mission: 'Uses appropriate teaching and training methods, techniques and instruments to communicate and enhance the cybersecurity culture, capabilities, knowledge and skills of human resources.' },
    { key: 3, mission: 'Promotes the importance of cybersecurity and consolidates it into the organisation.' }
];

// const deliverables = [
//     'Cybersecurity Awareness Program',
//     'Cybersecurity Training Material'
// ];

const deliverables = [
    { key: 1, deliverable: 'Cybersecurity Awareness Program', description: 'A program of activities to raise awareness on cybersecurity-related issues (e.g. lectures on attacks and threats) helping organisations prevent and mitigate related cybersecurity risks.' },
    { key: 2, deliverable: 'Cybersecurity Training Material', description: 'Material providing explaining cybersecurity-related concepts, methodologies and tools for training or upskilling individuals. It might include Handbooks for teachers, Toolsets for students and/or Virtual Images to support hands on training sessions.'}
];

const mainTasks = [
    {
        key: 1,
        task: 'Develop, update and deliver cybersecurity and data protection curricula and educational material for training and awareness based on content, method, tools, trainees need'
    },
    {
        key: 2,
        task: 'Organise, design and deliver cybersecurity and data protection awareness-raising activities, seminars, courses, practical training'
    },
    {
        key: 3,
        task: 'Monitor, evaluate and report training effectiveness'
    },
    {
        key: 4,
        task: 'Evaluate and report trainee’s performance'
    },
    {
        key: 5,
        task: 'Finding new approaches for education, training and awareness-raising'
    },
    {
        key: 6,
        task: 'Design, develop and deliver cybersecurity simulations, virtual labs or cyber range environments'
    },
    {
        key: 7,
        task: 'Provide guidance on cybersecurity certification programs for individuals'
    },
    {
        key: 8,
        task: 'Continuously maintain and enhance expertise; encourage and empower continuous enhancement of cybersecurity capacities and capabilities building'
    }
];

const keySkills = [
    {
        key: 1,
        skill: 'Identify needs in cybersecurity awareness, training and education'
    },
    {
        key: 2,
        skill: 'Design, develop and deliver learning programmes to cover cybersecurity needs'
    },
    {
        key: 3,
        skill: 'Develop cybersecurity exercises including simulations using cyber range environments'
    },
    {
        key: 4,
        skill: 'Provide training towards cybersecurity and data protection professional certifications'
    },
    {
        key: 5,
        skill: 'Utilise existing cybersecurity-related training resources'
    },
    {
        key: 6,
        skill: 'Develop evaluation programs for the awareness, training and education activities'
    },
    {
        key: 7,
        skill: 'Communicate, present and report to relevant stakeholders'
    },
    {
        key: 8,
        skill: 'Identify and select appropriate pedagogical approaches for the intended audience'
    },
    {
        key: 9,
        skill: 'Motivate and encourage people'
    }
];

const keyKnowledge = [
    {
        key: 1,
        knowledge: 'Pedagogical standards, methodologies and frameworks'
    },
    {
        key: 2,
        knowledge: 'Cybersecurity awareness, education and training programme development'
    },
    {
        key: 3,
        knowledge: 'Cybersecurity-related certifications'
    },
    {
        key: 4,
        knowledge: 'Cybersecurity education and training standards, methodologies and frameworks'
    },
    {
        key: 5,
        knowledge: 'Cybersecurity related laws, regulations and legislations'
    },
    {
        key: 6,
        knowledge: 'Cybersecurity recommendations and best practices'
    },
    {
        key: 7,
        knowledge: 'Cybersecurity standards, methodologies and frameworks'
    },
    {
        key: 8,
        knowledge: 'Cybersecurity controls and solutions'
    }
];

const eCompetences = [
    {
        key: '1',
        competence: 'D.3. Education and Training Provision',
        level: ['Level 3']
    },
    {
        key: '2',
        competence: 'D.9. Personnel Development',
        level: ['Level 3']
    },
    {
        key: '3',
        competence: 'E.8. Information Security Management',
        level: ['Level 3']
    }
];

export const EDUData = {
    title,
    alternativeTitles,
    summaryStatement,
    mission,
    deliverables,
    mainTasks,
    keySkills,
    keyKnowledge,
    eCompetences
}