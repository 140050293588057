// const alternativeTitles = [
//     'Data Protection Officer (DPO)',
//     'Privacy Protection Officer',
//     'Cyber Law Consultant',
//     'Cyber Legal Advisor',
//     'Information Governance Officer',
//     'Data Compliance Officer',
//     'Cybersecurity Legal Officer',
//     'IT/ICT Compliance Manager',
//     'Governance Risk Compliance (GRC) Consultant'
// ];

// Cyber Intelligence Analyst
// Cyber Threat Modeller

const title = 'Cyber Threat Intelligence Specialist';

// const alternativeTitles = [
//     'Cyber Intelligence Analyst',
//     'Cyber Threat Modeller'
// ];

const alternativeTitles = [
    { key: 1, title: 'Cyber Intelligence Analyst' },
    { key: 2, title: 'Cyber Threat Modeller' }
];

//
// const summaryStatement = [
//     'Manages compliance with cybersecurity-related standards, legal and regulatory frameworks based on the organisation’s strategy and legal requirements.'
// ];

// Collect, process, analyse data and information to produce actionable intelligence reports and disseminate them to target stakeholders.

// const summaryStatement = [
//     'Collect, process, analyse data and information to produce actionable intelligence reports and disseminate them to target stakeholders.'
// ];

const summaryStatement = [{ key: 1, statement: 'Collect, process, analyse data and information to produce actionable intelligence reports and disseminate them to target stakeholders.' }];

//
// const mission = [
//     'Oversees and assures compliance with cybersecurity- and data-related legal, regulatory frameworks and policies in line with the organisation’s strategy and legal requirements. Contributes to the organisation’s data protection related actions. Provides legal advice in the development of the organisation’s cybersecurity governance processes and recommended remediation strategies/solutions to ensure compliance.'
// ];

// Manages cyber threat intelligence life cycle including cyber threat information collection, analysis and production of actionable intelligence and dissemination to security stakeholders and the CTI community, at a tactical, operational and strategic level. Identifies and monitors the Tactics, Techniques and Procedures (TTPs) used by cyber threat actors and their trends, track threat actors’ activities and observe how non-cyber events can influence cyber-related actions.

// const mission = [
//     'Manages cyber threat intelligence life cycle including cyber threat information collection, analysis and production of actionable intelligence and dissemination to security stakeholders and the CTI community, at a tactical, operational and strategic level.',
//     'Identifies and monitors the Tactics, Techniques and Procedures (TTPs) used by cyber threat actors and their trends, track threat actors’ activities and observe how non-cyber events can influence cyber-related actions.'
// ];

const mission = [
    { key: 1, mission: 'Manages cyber threat intelligence life cycle including cyber threat information collection, analysis and production of actionable intelligence and dissemination to security stakeholders and the CTI community, at a tactical, operational and strategic level.' },
    { key: 2, mission: 'Identifies and monitors the Tactics, Techniques and Procedures (TTPs) used by cyber threat actors and their trends, track threat actors’ activities and observe how non-cyber events can influence cyber-related actions.' }
];

//
// const deliverables = [
//     'Compliance Manual',
//     'Compliance Report'
// ];

// • Cyber Threat Intelligence Manual • Cyber Threat Report

// const deliverables = [
//     'Cyber Threat Intelligence Manual',
//     'Cyber Threat Report'
// ];

const deliverables = [
    { key: 1, deliverable: 'Cyber Threat Intelligence Manual (or Handbook)', description: 'A manual presenting tools and/or methodologies for cyber threat intelligence gathering and/or sharing' },
    { key: 2, deliverable: 'Cyber Threat Report', description: 'A report identifying prime threats, major trends observed with respect to threats, threat actors and/or attack techniques. The report may also include relevant mitigation measures.'}
];


//
// const mainTasks = [
//     {
//         key: 1,
//         task: 'Ensure compliance with and provide legal advice and guidance on data privacy and data protection standards, laws and regulations'
//     },
//     {
//         key: 2,
//         task: 'Identify and document compliance gaps'
//     },
//     {
//         key: 3,
//         task: 'Conduct privacy impact assessments and develop, maintain, communicate and train upon the privacy policies, procedures'
//     },
//     {
//         key: 4,
//         task: 'Enforce and advocate organisation’s data privacy and protection program'
//     },
//     {
//         key: 5,
//         task: 'Ensure that data owners, holders, controllers, processors, subjects, internal or external partners and entities are informed about their data protection rights, obligations and responsibilities'
//     },
//     {
//         key: 6,
//         task: 'Act as a key contact point to handle queries and complaints regarding data processing'
//     },
//     {
//         key: 7,
//         task: 'Assist in designing, implementing, auditing and compliance testing activities in order to ensure cybersecurity and privacy compliance'
//     },
//     {
//         key: 8,
//         task: 'Monitor audits and data protection related training activities'
//     },
//     {
//         key: 9,
//         task: 'Cooperate and share information with authorities and professional groups'
//     },
//     {
//         key: 10,
//         task: 'Contribute to the development of the organisation’s cybersecurity strategy, policy and procedures'
//     },
//     {
//         key: 11,
//         task: 'Develop and propose staff awareness training to achieve compliance and foster a culture of data protection within the organization'
//     },
//     {
//         key: 12,
//         task: 'Manage legal aspects of information security responsibilities and third-party relations'
//     }
// ];


// • Develop, implement and manage the organisation's cyber threat intelligence strategy
// • Develop plans and procedures to manage threat intelligence
// • Translate business requirements into Intelligence Requirements
// • Implement threat intelligence collection, analysis and production of actionable intelligence and dissemination to security stakeholders
// • Identify and assess cyber threat actors targeting the organisation
// • Identify, monitor and assess the Tactics, Techniques and Procedures (TTPs) used by cyber threat actors by analysing open-source and proprietary data, information and intelligence
// • Produce actionable reports based on threat intelligence data
// • Elaborate and advise on mitigation plans at the tactical, operational and strategic level
// • Coordinate with stakeholders to share and consume intelligence on relevant cyber threats
// • Leverage intelligence data to support and assist with threat modelling, recommendations for Risk Mitigation and cyber threat hunting
// • Articulate and communicate intelligence openly and publicly at all levels
// • Convey the proper security severity by explaining the risk exposure and its consequences to non-technical stakeholders

const mainTasks = [
    {
        key: 1,
        task: 'Develop, implement and manage the organisation\'s cyber threat intelligence strategy'
    },
    {
        key: 2,
        task: 'Develop plans and procedures to manage threat intelligence'
    },
    {
        key: 3,
        task: 'Translate business requirements into Intelligence Requirements'
    },
    {
        key: 4,
        task: 'Implement threat intelligence collection, analysis and production of actionable intelligence and dissemination to security stakeholders'
    },
    {
        key: 5,
        task: 'Identify and assess cyber threat actors targeting the organisation'
    },
    {
        key: 6,
        task: 'Identify, monitor and assess the Tactics, Techniques and Procedures (TTPs) used by cyber threat actors by analysing open-source and proprietary data, information and intelligence'
    },
    {
        key: 7,
        task: 'Produce actionable reports based on threat intelligence data'
    },
    {
        key: 8,
        task: 'Elaborate and advise on mitigation plans at the tactical, operational and strategic level'
    },
    {
        key: 9,
        task: 'Coordinate with stakeholders to share and consume intelligence on relevant cyber threats'
    },
    {
        key: 10,
        task: 'Leverage intelligence data to support and assist with threat modelling, recommendations for Risk Mitigation and cyber threat hunting'
    },
    {
        key: 11,
        task: 'Articulate and communicate intelligence openly and publicly at all levels'
    },
    {
        key: 12,
        task: 'Convey the proper security severity by explaining the risk exposure and its consequences to non-technical stakeholders'
    }
];

//
// const keySkills = [
//     {
//         key: 1,
//         skill: 'Comprehensive understanding of the business strategy, models and products and ability to factor into legal, regulatory and standards’ requirements'
//     },
//     {
//         key: 2,
//         skill: 'Carry out working-life practices of the data protection and privacy issues involved in the implementation of the organisational processes, finance and business strategy'
//     },
//     {
//         key: 3,
//         skill: 'Lead the development of appropriate cybersecurity and privacy policies and procedures that complement the business needs and legal requirements; further ensure its acceptance, comprehension and implementation and communicate it between the involved parties'
//     },
//     {
//         key: 4,
//         skill: 'Conduct, monitor and review privacy impact assessments using standards, frameworks, acknowledged methodologies and tools'
//     },
//     {
//         key: 5,
//         skill: 'Explain and communicate data protection and privacy topics to stakeholders and users'
//     },
//     {
//         key: 6,
//         skill: 'Understand, practice and adhere to ethical requirements and standards'
//     },
//     {
//         key: 7,
//         skill: 'Understand legal framework modifications implications to the organisation’s cybersecurity and data protection strategy and policies'
//     },
//     {
//         key: 8,
//         skill: 'Collaborate with other team members and colleagues'
//     }
// ];

// • Collaborate with other team members and colleagues
// • Collect, analyse and correlate cyber threat information originating from multiple sources
// • Identify threat actors TTPs and campaigns
// • Automate threat intelligence management procedures
// • Conduct technical analysis and reporting
// • Identify non-cyber events with implications on cyber-related activities
// • Model threats, actors and TTPs
// • Communicate, coordinate and cooperate with internal and external stakeholders • Communicate, present and report to relevant stakeholders
// • Use and apply CTI platforms and tools

const keySkills = [
    {
        key: 1,
        skill: 'Collaborate with other team members and colleagues'
    },
    {
        key: 2,
        skill: 'Collect, analyse and correlate cyber threat information originating from multiple sources'
    },
    {
        key: 3,
        skill: 'Identify threat actors TTPs and campaigns'
    },
    {
        key: 4,
        skill: 'Automate threat intelligence management procedures'
    },
    {
        key: 5,
        skill: 'Conduct technical analysis and reporting'
    },
    {
        key: 6,
        skill: 'Identify non-cyber events with implications on cyber-related activities'
    },
    {
        key: 7,
        skill: 'Model threats, actors and TTPs'
    },
    {
        key: 8,
        skill: 'Communicate, coordinate and cooperate with internal and external stakeholders'
    },
    {
        key: 9,
        skill: 'Communicate, present and report to relevant stakeholders'
    },
    {
        key: 10,
        skill: 'Use and apply CTI platforms and tools'
    }
];

//
// const keyKnowledge = [
//     {
//         key: 1,
//         knowledge: 'Cybersecurity related laws, regulations and legislations'
//     },
//     {
//         key: 2,
//         knowledge: 'Cybersecurity standards, methodologies and frameworks'
//     },
//     {
//         key: 3,
//         knowledge: 'Cybersecurity policies'
//     },
//     {
//         key: 4,
//         knowledge: 'Legal, regulatory and legislative compliance requirements, recommendations and best practices'
//     },
//     {
//         key: 5,
//         knowledge: 'Privacy impact assessment standards, methodologies and frameworks'
//     }
// ];

// • Operating systems security
// • Computer networks security
// • Cybersecurity controls and solutions
// • Computer programming
// • Cyber Threat Intelligence (CTI) sharing standards, methodologies and frameworks • Responsible information disclosure procedures
// • Cross-domain and border-domain knowledge related to cybersecurity
// • Cyber threats
// • Cyber threat actors
// • Cybersecurity attack procedures
// • Advanced and persistent cyber threats (APT)
// • Threat actors Tactics, Techniques and Procedures (TTPs)
// • Cybersecurity-related certifications

const keyKnowledge = [
    {
        key: 1,
        knowledge: 'Operating systems security'
    },
    {
        key: 2,
        knowledge: 'Computer networks security'
    },
    {
        key: 3,
        knowledge: 'Cybersecurity controls and solutions'
    },
    {
        key: 4,
        knowledge: 'Computer programming'
    },
    {
        key: 5,
        knowledge: 'Cyber Threat Intelligence (CTI) sharing standards, methodologies and frameworks'
    },
    {
        key: 6,
        knowledge: 'Responsible information disclosure procedures'
    },
    {
        key: 7,
        knowledge: 'Cross-domain and border-domain knowledge related to cybersecurity'
    },
    {
        key: 8,
        knowledge: 'Cyber threats'
    },
    {
        key: 9,
        knowledge: 'Cyber threat actors'
    },
    {
        key: 10,
        knowledge: 'Cybersecurity attack procedures'
    },
    {
        key: 11,
        knowledge: 'Advanced and persistent cyber threats (APT)'
    },
    {
        key: 12,
        knowledge: 'Threat actors Tactics, Techniques and Procedures (TTPs)'
    },
    {
        key: 13,
        knowledge: 'Cybersecurity-related certifications'
    }
];

// const eCompetences = [
//     {
//         key: '1',
//         competence: 'A.1. Information Systems and Business Strategy Alignment',
//         level: ['Level 4']
//     },
//     {
//         key: '2',
//         competence: 'D.1. Information Security Strategy Development',
//         level: ['Level 4']
//     },
//     {
//         key: '3',
//         competence: 'E.8. Information Security Management',
//         level: ['Level 3']
//     },
//     {
//         key: '4',
//         competence: 'E.9. Information Systems Governance',
//         level: ['Level 4']
//     }
// ];
//

//     e-Competences (from e-CF)
// B.5. Documentation Production
// D.7. Data Science and Analytics
// D.10. Information and Knowledge Management E.4. Relationship Management
// E.8. Information Security Management
// Level 3 Level 4 Level 4 Level 3 Level 4

const eCompetences = [
    {
        key: '1',
        competence: 'B.5. Documentation Production',
        level: ['Level 3']
    },
    {
        key: '2',
        competence: 'D.7. Data Science and Analytics',
        level: ['Level 4']
    },
    {
        key: '3',
        competence: 'D.10. Information and Knowledge Management',
        level: ['Level 4']
    },
    {
        key: '4',
        competence: 'E.4. Relationship Management',
        level: ['Level 3']
    },
    {
        key: '5',
        competence: 'E.8. Information Security Management',
        level: ['Level 4']
    }
];
// export const LPCData = {
//     alternativeTitles,
//     summaryStatement,
//     mission,
//     deliverables,
//     mainTasks,
//     keySkills,
//     keyKnowledge,
//     eCompetences
// }

export const CTIData = {
    title,
    alternativeTitles,
    summaryStatement,
    mission,
    deliverables,
    mainTasks,
    keySkills,
    keyKnowledge,
    eCompetences
}
