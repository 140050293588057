import { Button, Popover, Table, Typography } from "antd"
import React from "react"

const { Title } = Typography

const percentageLevel = ( percent ) => {
	return (
		percent > 67
			? "High"
			: percent > 33
			? "Medium"
			: percent >= 0
			? "Low"
			: "Error"
	)
}

export const Statistics = ( {
	skills,
	rsProfiles,
	rsSkillsGroup,
	onTrainingsSearchClick,
} ) => {
	const columns = [
		{
			title: "Profile",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Coverage",
			dataIndex: "coverage",
			key: "coverage",
			align: "right",
			width: "90px",
			render: ( val, data ) => {
				return <Popover
					placement={ "leftBottom" }
					arrowPointAtCenter
					content={ 
						<div className={ "skills-popover" }>
							{ /*<Title className={"skills-popover-title"} level={5}>{data.name}</Title>*/ }
							<Title level={ 5 }>{ "Included Skills" }</Title>
							<ul>
								{ data.included.length
									? data.included.map( ( skill, k ) =>
										<li key={ k }>
											{ skill.name }
											<br />
										</li>
									)
									: <span className={ "empty" }>{ "none" }</span> }
							</ul>
							<Title level={ 5 }>{ "Missing Skills" }</Title>
							<ul>
								{ data.missing.length
									? data.missing.map( ( skill, k ) =>
										<li key={ k }>
											{ skill.name }
											<br />
										</li>
									)
									: <span className={ "empty" }>{ "none" }</span> }
							</ul>
							<Button onClick={ e => onTrainingsSearchClick( e, data.missing.map( ( { id } ) => id ) ) }>
								{ "Find Trainings" }
							</Button>
						</div>
					 }
				>
					<span>
						<Button
							type={ "link" }
						>
							{ percentageLevel( val ) }
						</Button>
					</span>
				</Popover>
			},
		},
	]

	const enisaProfileCoverage = () => {
		return rsProfiles.map( profile => {
			let included = []
			let missing = []
			const selectedSkills = selectedSkillIds()
			profile.skills_group.forEach( skillId => {
				if ( selectedSkills.includes( skillId ) ) {
					included.push( rsSkillsGroup.find( skill => skill.id === skillId ) )
				} else {
					missing.push( rsSkillsGroup.find( skill => skill.id === skillId ) )
				}
			} )
			return {
				coverage: parseInt( ( included.length / profile.skills_group.length * 100 ).toFixed( 0 ) ),
				name: profile.name,
				profileId: profile.id,
				included: included,
				missing: missing,
			}
		} )
	}

	const selectedSkillIds = () => {
		let tmp = []
		skills.forEach( course => {
			course.skills_group.forEach( skillId => {
				tmp = tmp.concat( skillId )
			} )
		} )
		return [ ...new Set( tmp ) ].sort( ( a, b ) => {
			return a - b
		} )
	}

	return (
		<div className={ "statistics" }>
			<div className={ "content" }>
				{ skills.length > -1
					? (
						<div className={ "content-scroll" }>
							<Table
								size={ "small" }
								pagination={ false }
								columns={ columns }
								dataSource={ enisaProfileCoverage() }
								rowKey={ "profileId" }
							/>
						</div>
					)
					: (
						<div className={ "empty-data" }>
							<font className={ "header" }>{ "There are no courses yet." }</font>
						</div>
					) }
			</div>
		</div>
	)
}
