export const getFilters = ( trainings ) => {
	const filters = trainings.reduce( ( carry, training ) => {
		if ( !carry.countries.includes( training.country ) ) {
			carry.countries.push( training.country )
		}

		training.language.forEach( ( language ) => {
			if ( !carry.languages.includes( language ) ) {
				carry.languages.push( language )
			}
		} )

		if ( !carry.typeFormats.includes( training.type_format ) ) {
			carry.typeFormats.push( training.type_format )
		}
		if ( !carry.organizers.includes( training.organizer ) ) {
			carry.organizers.push( training.organizer )
		}

		if ( !carry.canLeadToCertifications.includes( training.can_lead_to_certification ) ) {
			carry.canLeadToCertifications.push( training.can_lead_to_certification )
		}

		if ( !carry.includesExamsForCertifications.includes( training.includes_exams_for_certification ) ) {
			carry.includesExamsForCertifications.push( training.includes_exams_for_certification )
		}

		if ( !carry.contentTypes.includes( training.content_type ) ) {
			carry.contentTypes.push( training.content_type )
		}

		const duration = Number( training.duration )

		if ( carry.durationMin === null || carry.durationMin > duration ) {
			carry.durationMin = duration
		}
		if ( carry.durationMax === null || carry.durationMax < duration ) {
			carry.durationMax = duration
		}

		const price = Number( training.price )
		if ( carry.priceMin === null || carry.priceMin > price ) {
			carry.priceMin = price
		}

		if ( carry.priceMax === null || carry.priceMax < price ) {
			carry.priceMax = price
		}

		return carry
	}, {
		countries: [],
		languages: [],
		typeFormats: [],
		organizers: [],
		canLeadToCertifications: [],
		includesExamsForCertifications: [],
		contentTypes: [],
		durationMin: null,
		durationMax: null,
		priceMax: null,
		priceMin: null,
	} )
	const mapCb = value => ( { value, label: value || "Unknown" } )
	const sortCb = ( a, b ) => a.label.localeCompare( b.label )

	filters.countries = filters.countries.map( mapCb ).sort( sortCb )
	filters.languages = filters.languages.map( mapCb ).sort( sortCb )
	filters.typeFormats = filters.typeFormats.map( mapCb ).sort( sortCb )
	filters.organizers = filters.organizers.map( mapCb ).sort( sortCb )
	filters.canLeadToCertifications = filters.canLeadToCertifications.map( mapCb ).sort( sortCb )
	filters.includesExamsForCertifications = filters.includesExamsForCertifications.map( mapCb ).sort( sortCb )
	filters.contentTypes = filters.contentTypes.map( mapCb ).sort( sortCb )

	return filters
}
