const title = 'Cyber Incident Responder';

// Transform alternativeTitles into a key title pair, mission and deliverables into key mission and key deliverable pairs
// const alternativeTitles = [
//     'Cyber Incident Handler',
//     'Cyber Crisis Expert',
//     'Incident Response Engineer',
//     'Security Operations Center (SOC) Analyst Cyber Fighter /Defender',
//     'Security Operation Analyst (SOC Analyst) Cybersecurity SIEM Manager'
// ];

const alternativeTitles = [
    { key: 1, title: 'Cyber Incident Handler' },
    { key: 2, title: 'Cyber Crisis Expert' },
    { key: 3, title: 'Incident Response Engineer' },
    { key: 4, title: 'Security Operations Center (SOC) Analyst Cyber Fighter /Defender' },
    { key: 5, title: 'Security Operation Analyst (SOC Analyst) Cybersecurity SIEM Manager' }
];

// const summaryStatement = 'Monitors the organisation’s cybersecurity state, ' +
//     'handles incidents during cyber-attacks and assures the continued operations of ICT systems.';

const summaryStatement = [{ key: 1, summary: 'Monitors the organisation’s cybersecurity state, handles incidents during cyber-attacks and assures the continued operations of ICT systems.' }];


// const mission = ['Monitors and assesses systems’ cybersecurity state.',
//     'Analyses, evaluates and mitigates the impact of cybersecurity incidents.',
//     'Identifies cyber incidents root causes and malicious actors.',
//     'According to the organisation’s Incident Response Plan, restores systems’ and processes’ functionalities to an operational state, collecting evidences and documenting actions taken.'
// ];

const mission = [
    { key: 1, mission: 'Monitors and assesses systems’ cybersecurity state.' },
    { key: 2, mission: 'Analyses, evaluates and mitigates the impact of cybersecurity incidents.' },
    { key: 3, mission: 'Identifies cyber incidents root causes and malicious actors.' },
    { key: 4, mission: 'According to the organisation’s Incident Response Plan, restores systems’ and processes’ functionalities to an operational state, collecting evidences and documenting actions taken.' }
];

// const deliverables = ['Incident Response Plan',
//     'Cyber Incident Report'
// ];

const deliverables = [
    { key: 1, deliverable: 'Incident Response Plan', description: 'A set of documented procedures detailing the steps that should be taken in each phase of an incident response (Preparation, Detection and Analysis, Containment, Eradication and Recovery, Post-Incident Activity).'},
    { key: 2, deliverable: 'Cyber Incident Report', description: 'A report providing details on one or more cyber incidents.'}
];

const mainTasks = [
    {
        key: 1,
        task: 'Contribute to the development, maintenance and assessment of the Incident Response Plan',
    },
    {
        key: 2,
        task: 'Develop, implement and assess procedures related to incident handling',
    },
    {
        key: 3,
        task: 'Identify, analyse, mitigate and communicate cybersecurity incidents',
    },
    {
        key: 4,
        task: 'Assess and manage technical vulnerabilities',
    },
    {
        key: 5,
        task: 'Measure cybersecurity incidents detection and response effectiveness',
    },
    {
        key: 6,
        task: 'Evaluate the resilience of the cybersecurity controls and mitigation actions taken after a cybersecurity or data breach incident',
    },
    {
        key: 7,
        task: 'Adopt and develop incident handling testing techniques',
    },
    {
        key: 8,
        task: 'Establish procedures for incident results analysis and incident handling reporting',
    },
    {
        key: 9,
        task: 'Document incident results analysis and incident handling actions',
    },
    {
        key: 10,
        task: 'Cooperate with Secure Operation Centres (SOCs) and Computer Security Incident Response Teams (CSIRTs)',
    },
    {
        key: 11,
        task: 'Cooperate with key personnel for reporting of security incidents according to applicable legal framework',
    },
];

const keySkills = [
    {
        key: 1,
        skill: 'Practice all technical, functional and operational aspects of cybersecurity incident handling and response',
    },
    {
        key: 2,
        skill: 'Collect, analyse and correlate cyber threat information originating from multiple sources',
    },
    {
        key: 3,
        skill: 'Work on operating systems, servers, clouds and relevant infrastructures',
    },
    {
        key: 4,
        skill: 'Work under pressure',
    },
    {
        key: 5,
        skill: 'Communicate, present and report to relevant stakeholders',
    },
    {
        key: 6,
        skill: 'Manage and analyse log files',
    },
];

const keyKnowledge = [
    {
        key: 1,
        knowledge: 'Incident handling standards, methodologies and frameworks',
    },
    {
        key: 2,
        knowledge: 'Incident handling recommendations and best practices',
    },
    {
        key: 3,
        knowledge: 'Incident handling tools',
    },
    {
        key: 4,
        knowledge: 'Incident handling communication procedures',
    },
    {
        key: 5,
        knowledge: 'Operating systems security',
    },
    {
        key: 6,
        knowledge: 'Computer networks security',
    },
    {
        key: 7,
        knowledge: 'Cyber threats',
    },
    {
        key: 8,
        knowledge: 'Cybersecurity attack procedures',
    },
    {
        key: 9,
        knowledge: 'Computer systems vulnerabilities',
    },
    {
        key: 10,
        knowledge: 'Cybersecurity-related certifications',
    },
    {
        key: 11,
        knowledge: 'Cybersecurity related laws, regulations and legislations',
    },
    {
        key: 12,
        knowledge: 'Secure Operation Centres (SOCs) operation',
    },
    {
        key: 13,
        knowledge: 'Computer Security Incident Response Teams (CSIRTs) operation',
    }
];

const eCompetences = [
    {
        key: 1,
        competence: 'A.7. Technology Trend Monitoring',
        level: ['Level 3']
    },
    {
        key: 2,
        competence: 'B.2. Component Integration',
        level: ['Level 2']
    },
    {
        key: 3,
        competence: 'B.3. Testing',
        level: ['Level 3']
    },
    {
        key: 4,
        competence: 'B.5. Documentation Production',
        level: ['Level 3']
    },
    {
        key: 5,
        competence: 'C.4. Problem Management',
        level: ['Level 4']
    }
];

export const CIRData = {
    title,
    alternativeTitles,
    summaryStatement,
    mission,
    deliverables,
    mainTasks,
    keySkills,
    keyKnowledge,
    eCompetences
};
