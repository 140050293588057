import {eCF_data} from './eCF-data'

const eCF = eCF_data.eCF;
function getECFByTitle(title) {
    const matchingECF = eCF.find(ecf => ecf.title === title);

    // Return the description and levels if a match is found, otherwise return null
    if (matchingECF) {
        return {
            description: matchingECF.description,
            levels: matchingECF.levels
        };
    } else {
        return {
            description: "description not found",
            levels: "levels not found"
        }
    }
}

export {getECFByTitle}