import {Col, Typography} from "antd";
import ProfileImage from "./ProfileImage";

import CISO from './role-icons/CISO.png'
import incident_responder from './role-icons/incident_responder.png'
import legal_policy_compliance from './role-icons/legal_policy_compliance.png'
import cyber_threat_intelligence from './role-icons/cyber_threat_intelligence.png'
import architect from './role-icons/architect.png'
import auditor from './role-icons/auditor.png'
import educator from './role-icons/educator.png'
import implementer from './role-icons/implementer.png'
import researcher from './role-icons/researcher.png'
import risk_manager from './role-icons/risk_manager.png'
import forensics from './role-icons/forensics.png'
import pentester from './role-icons/pentester.png'

import RoleInfo from "./RoleInfo";

const RoleRow = ( {onChildsChildRendered, handleImageClick, roleClicked, isAnyImageClicked, isImageClicked, getRoleData, items, leftRoleProfile} ) => {

    var leftRole = {};
    var middleRole = {};
    var rightRole = {};

    if (leftRoleProfile === "CISO") {

        leftRole.acronym = "CISO";
        leftRole.image = CISO;
        leftRole.title = "CHIEF INFORMATION SECURITY OFFICER (CISO)";

        middleRole.acronym = "CIR";
        middleRole.image = incident_responder;
        middleRole.title = "CYBER INCIDENT RESPONDER";

        rightRole.acronym = "LPC";
        rightRole.image = legal_policy_compliance;
        rightRole.title = "CYBER LEGAL, POLICY & COMPLIANCE OFFICER";
    }
    else if (leftRoleProfile === "CTI") {
        leftRole.acronym = "CTI";
        leftRole.image = cyber_threat_intelligence;
        leftRole.title = "CYBER THREAT INTELLIGENCE SPECIALIST";

        middleRole.acronym = "ARCH";
        middleRole.image = architect;
        middleRole.title = "CYBERSECURITY ARCHITECT";

        rightRole.acronym = "AUD";
        rightRole.image = auditor;
        rightRole.title = "CYBERSECURITY AUDITOR";
    }
    else if (leftRoleProfile === "EDU") {
        leftRole.acronym = "EDU";
        leftRole.image = educator;
        leftRole.title = "CYBERSECURITY EDUCATOR";

        middleRole.acronym = "CIMPL";
        middleRole.image = implementer;
        middleRole.title = "CYBERSECURITY IMPLEMENTER";

        rightRole.acronym = "CYRESEARCH";
        rightRole.image = researcher;
        rightRole.title = "CYBERSECURITY RESEARCHER";
    }
    else if (leftRoleProfile === "RISKMANAGER") {
        leftRole.acronym = "RISKMANAGER";
        leftRole.image = risk_manager;
        leftRole.title = "CYBERSECURITY RISK MANAGER";

        middleRole.acronym = "FOREN";
        middleRole.image = forensics;
        middleRole.title = "DIGITAL FORENSICS INVESTIGATOR";

        rightRole.acronym = "PENTEST";
        rightRole.image = pentester;
        rightRole.title = "PENETRATION TESTER";
    }

    return(
        <div>
            <div className={"rolesRow"}>
                <Col span={8}><ProfileImage src={leftRole.image} onClick={handleImageClick} profile={leftRole.acronym} roleClicked={roleClicked} anyClicked={isAnyImageClicked}/></Col>
                <Col span={8}><ProfileImage src={middleRole.image} onClick={handleImageClick} profile={middleRole.acronym} roleClicked={roleClicked} anyClicked={isAnyImageClicked}/></Col>
                <Col span={8}><ProfileImage src={rightRole.image} onClick={handleImageClick} profile={rightRole.acronym} roleClicked={roleClicked} anyClicked={isAnyImageClicked}/></Col>

            </div>
            <div className={"rolesRow"}>
                <Col span={8}><Typography className={"roleTitle"}>{leftRole.title}</Typography></Col>
                <Col span={8}><Typography className={"roleTitle"}>{middleRole.title}</Typography></Col>
                <Col span={8}><Typography className={"roleTitle"}>{rightRole.title}</Typography></Col>
            </div>

            {isImageClicked && (
                <RoleInfo onRendered={onChildsChildRendered} roleClicked={roleClicked} roleData={getRoleData(roleClicked)}
                          items={items}/>
            )}

    </div>
    );
}

export default RoleRow;