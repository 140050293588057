const title = "Cybersecurity Risk Manager";

// const alternativeTitles = [
//     "Risk Manager",
//     "Risk Analyst",
//     "Security Risk Manager"
// ];

const alternativeTitles = [
    { key: 1, title: "Risk Manager" },
    { key: 2, title: "Risk Analyst" },
    { key: 3, title: "Security Risk Manager" }
];

// const summaryStatement = [
//     "Identify, assess and manage risks to information security. Develop policies and procedures to mitigate risks."
// ];

const summaryStatement = [{ key: 1, summary: "Identify, assess and manage risks to information security. Develop policies and procedures to mitigate risks."}];

// const mission = [
//     "Identify, assess and manage risks to information security. Develop policies and procedures to mitigate risks."
// ];
//
// const deliverables = [
//     "Risk assessment reports",
//     "Risk management plans",
//     "Security policies and procedures"
// ];

const mission = [
    { key: 1, mission: "Identify, assess and manage risks to information security." },
    { key: 2, mission: "Develop policies and procedures to mitigate risks." }
];

const deliverables = [
    { key: 1, deliverable: "Cybersecurity Risk Remediation Action Plan", description: "An action plan listing activities related to the implementation of mitigation measures aiming at reducing cybersecurity risks."},
    { key: 2, deliverable: "Risk assessment report", description:'A report listing the results of the identification, analysis, and evaluation of cybersecurity risks of a system. It might also include controls to mitigate or reduce identified risks to an acceptable level.' },
    { key: 3, deliverable: "Risk management plan" },
    { key: 4, deliverable: "Security policies and procedures" }
];


const mainTasks = [
    { key: 1, task: "Develop an organisation’s cybersecurity risk management strategy" },
    { key: 2, task: "Manage an inventory of organisation’s assets" },
    { key: 3, task: "Identify and assess cybersecurity-related threats and vulnerabilities of ICT systems" },
    { key: 4, task: "Identification of threat landscape including attackers’ profiles and estimation of attacks’ potential" },
    { key: 5, task: "Assess cybersecurity risks and propose most appropriate risk treatment options, including security controls and risk mitigation and avoidance that best address the organisation’s strategy" },
    { key: 6, task: "Monitor effectiveness of cybersecurity controls and risk levels" },
    { key: 7, task: "Ensure that all cybersecurity risks remain at an acceptable level for the organisation’s assets" },
    { key: 8, task: "Develop, maintain, report and communicate complete risk management cycle" }
];

const keySkills = [
    { key: 1, skill: "Implement cybersecurity risk management frameworks, methodologies and guidelines and ensure compliance with regulations and standards" },
    { key: 2, skill: "Analyse and consolidate organisation’s quality and risk management practices" },
    { key: 3, skill: "Enable business assets owners, executives and other stakeholders to make risk- informed decisions to manage and mitigate risks" },
    { key: 4, skill: "Build a cybersecurity risk-aware environment" },
    { key: 5, skill: "Communicate, present and report to relevant stakeholders" },
    { key: 6, skill: "Propose and manage risk-sharing options" }
];

const keyKnowledge = [
    { key: 1, knowledge: "Risk management standards, methodologies and frameworks" },
    { key: 2, knowledge: "Risk management tools" },
    { key: 3, knowledge: "Risk management recommendations and best practices" },
    { key: 4, knowledge: "Cyber threats" },
    { key: 5, knowledge: "Computer systems vulnerabilities" },
    { key: 6, knowledge: "Cybersecurity controls and solutions" },
    { key: 7, knowledge: "Cybersecurity risks" },
    { key: 8, knowledge: "Monitoring, testing and evaluating cybersecurity controls' effectiveness" },
    { key: 9, knowledge: "Cybersecurity-related certifications" },
    { key: 10, knowledge: "Cybersecurity-related technologies" }
];

const eCompetences = [
    { key: 1, competence: "E.3. Risk Management", level: ["Level 4"] },
    { key: 2, competence: "E.5. Process Improvement", level: ["Level 3"] },
    { key: 3, competence: "E.7. Business Change Management", level: ["Level 4"] },
    { key: 4, competence: "E.9. Information Systems Governance", level: ["Level 4"] }
];

export const RISKMANAGERData = {
    title,
    alternativeTitles,
    summaryStatement,
    mission,
    deliverables,
    mainTasks,
    keySkills,
    keyKnowledge,
    eCompetences
}