import React from 'react'
import Axios from 'axios'
import { Button, Modal, Space, Table, Tooltip, Typography  } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined, QuestionCircleOutlined , SyncOutlined} from '@ant-design/icons'

import AddEditTrainingModalForm from './modals/AddEditTrainingModalForm'

import {Config} from './config_section.js'

const { confirm } = Modal
const { Title } = Typography

const Trainings = ({ refresh, showMessage, authData, usersData, rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, dbTrainings }) => {
const [editRecord, setEditRecord] = React.useState(null)

  function showConfirmAction(id, isArchived) {
    confirm({
      title: isArchived ? "Are you sure you want to restore this training?" : "Are you sure you want to archive this training?",
      content: (
        <div>
          <p><b>Training:</b> {dbTrainings.find(obj => obj.id === id).name}<br/>
          <b>Organizer:</b> {dbTrainings.find(obj => obj.id === id).organizer}</p>
        </div>
      ),

      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          return await new Promise((resolve, reject) => {
            Axios.post(Config.server.archiveTraining, { ...authData, id: id }, { headers: { 'Content-Type': 'application/json' } })
              .then((response) => {
                if (response && response.data.error === false) {
                  showMessage({ type: "success", content: `Record successfully ${isArchived ? "restored" : "archived"}.` });
                  refresh()
                  resolve()
                } else {
                  showMessage({ type: 'error', content: "Something went wrong." })
                  reject()
                }
              })
              .catch((error) => {
                console.log(error)
                showMessage({ type: 'error', content: "Something went wrong." })
                reject()
              })
          })
        } catch {
          showMessage({ type: 'error', content: "Something went wrong." })
        }
      }
    })
    
  }

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '48px',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Organizer',
      dataIndex: 'organizer',
      key: 'organization',
      sorter:  (a, b) => a.organizer.localeCompare(b.organizer),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Training',
      dataIndex: 'name',
      key: 'name',
      sorter:  (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Updated [CET]',
      dataIndex: 'updated',
      key: 'updated',
      width: '160px',
      sorter:  (a, b) => a.updated.localeCompare(b.updated),
    },
    {
      title: 'Created [CET]',
      dataIndex: 'created',
      key: 'created',
      width: '160px',
      sorter:  (a, b) => a.created.localeCompare(b.created),
    },
    {
      title: "Archived [CET]",
      dataIndex: "archived",
      key: "archived",
      width: "160px",
      sorter: (a, b) => a.archived.localeCompare(b.archived),
    },
    {
      title: 'Action',
      key: 'action',
      width: "60px",
      render: (record) => (
        <Space size="small">
          <Tooltip title="Edit row"><EditOutlined className="editIcon" onClick={() => setEditRecord(dbTrainings.find(obj => obj.id === record.id))} /></Tooltip>
          <Tooltip title={record.archived ? "Restore row" : "Archive row"}>
            {record.archived ? (
              <SyncOutlined className="restoreIcon" onClick={() => showConfirmAction(record.id, true)} />
            ) : (
              <DeleteOutlined className="deleteIcon" onClick={() => showConfirmAction(record.id, false)} />
            )}
          </Tooltip>
        </Space>
      ),
    }
  ]

  return (
    <div id="trainings">
      <Title className="pageTitle">Trainings</Title>

      <div className="dataHandleButtons" >
        <Space>
          <Button onClick={()=>setEditRecord("new")} ><PlusOutlined className="icon-position-fix"/> Add Training</Button>
        </Space>
      </div>
      <Table size="small" className="data-table"
        dataSource={dbTrainings}
        pagination={false}
        columns={tableColumns}
        rowClassName={(training) => (training.archived ? "archived-row" : "")}
      />

      <AddEditTrainingModalForm editRecord={editRecord} refresh={refresh} showMessage={showMessage} onCancel={()=>setEditRecord(null)} authData={authData} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} usersData={usersData} dbTrainings={dbTrainings} />

    </div>
  )
}

export default Trainings
