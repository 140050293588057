// const alternativeTitles = [
//     'Cybersecurity Programme Director',
//     'Information Security Officer (ISO)',
//     'Information Security Manager',
//     'Head of Information Security',
//     'IT/ICT Security Officer'
// ];

// Data Protection Officer (DPO)
// Privacy Protection Officer
// Cyber Law Consultant
// Cyber Legal Advisor
// Information Governance Officer
// Data Compliance Officer
// Cybersecurity Legal Officer
// IT/ICT Compliance Manager
// Governance Risk Compliance (GRC) Consultant

// Transform alternativeTitles into a key title pair, mission and deliverables into key mission and key deliverable pairs


const title = 'Cybersecurity Legal, Policy & Compliance Officer';

// const alternativeTitles = [
//     'Data Protection Officer (DPO)',
//     'Privacy Protection Officer',
//     'Cyber Law Consultant',
//     'Cyber Legal Advisor',
//     'Information Governance Officer',
//     'Data Compliance Officer',
//     'Cybersecurity Legal Officer',
//     'IT/ICT Compliance Manager',
//     'Governance Risk Compliance (GRC) Consultant'
// ];

const alternativeTitles = [
    { key: 1, title: 'Data Protection Officer (DPO)' },
    { key: 2, title: 'Privacy Protection Officer' },
    { key: 3, title: 'Cyber Law Consultant' },
    { key: 4, title: 'Cyber Legal Advisor' },
    { key: 5, title: 'Information Governance Officer' },
    { key: 6, title: 'Data Compliance Officer' },
    { key: 7, title: 'Cybersecurity Legal Officer' },
    { key: 8, title: 'IT/ICT Compliance Manager' },
    { key: 9, title: 'Governance Risk Compliance (GRC) Consultant' }
];

// const summaryStatement = [
//     'Manages an organisation’s cybersecurity strategy and its ' +
//     'implementation to ensure that digital systems, services and assets ' +
//     'are adequately secure and protected.'
// ];

//Manages compliance with cybersecurity-related standards, legal and regulatory frameworks based on the organisation’s strategy and legal requirements.

// const summaryStatement = [
//     'Manages compliance with cybersecurity-related standards, legal and regulatory frameworks based on the organisation’s strategy and legal requirements.'
// ];

const summaryStatement = [{ key: 1, summary: 'Manages compliance with cybersecurity-related standards, legal and regulatory frameworks based on the organisation’s strategy and legal requirements.' }];

//
// const mission = [
//     'Defines, maintains and communicates the cybersecurity vision, strategy, policies and procedures. ',
//     'Manages the implementation of the cybersecurity policy across the organisation. Assures information exchange with external authorities and professional bodies.'
// ];

// Oversees and assures compliance with cybersecurity- and data-related legal, regulatory frameworks and policies in line with the organisation’s strategy and legal requirements. Contributes to the organisation’s data protection related actions. Provides legal advice in the development of the organisation’s cybersecurity governance processes and recommended remediation strategies/solutions to ensure compliance.

// const mission = [
//     'Oversees and assures compliance with cybersecurity- and data-related legal, regulatory frameworks and policies in line with the organisation’s strategy and legal requirements.',
//     'Contributes to the organisation’s data protection related actions. Provides legal advice in the development of the organisation’s cybersecurity governance processes and recommended remediation strategies/solutions to ensure compliance.'
// ];

const mission = [
    { key: 1, mission: 'Oversees and assures compliance with cybersecurity- and data-related legal, regulatory frameworks and policies in line with the organisation’s strategy and legal requirements.' },
    { key: 2, mission: 'Contributes to the organisation’s data protection related actions. Provides legal advice in the development of the organisation’s cybersecurity governance processes and recommended remediation strategies/solutions to ensure compliance.' }
];

// const deliverables = [
//     'Cybersecurity strategy',
//     'Cybersecurity policy'
// ];

// • Compliance Manual • Compliance Report

// const deliverables = [
//     'Compliance Manual',
//     'Compliance Report'
// ];

const deliverables = [
    { key: 1, deliverable: 'Compliance Manual', description: 'A manual providing a thorough understanding of the regulatory compliance obligations of an organisation. It may include internal policies or procedures to ensure compliance with laws, regulations and/or standards.'},
    { key: 2, deliverable: 'Compliance Report', description: 'A report presenting the current state of the compliance posture of an organisation.' }
];

//
// const mainTasks = [
//     {
//         key: 1,
//         task: 'Define, implement, communicate and maintain cybersecurity goals, requirements, strategies, policies, aligned with the business strategy to support the organisational objectives'
//     },
//     {
//         key: 2,
//         task: 'Prepare and present cybersecurity vision, strategies and policies for approval by the senior management of the organisation and ensure their execution'
//     },
//     {
//         key: 3,
//         task: 'Supervise the application and improvement of the Information Security Management System (ISMS)'
//     },
//     {
//         key: 4,
//         task: 'Educate senior management about cybersecurity risks, threats and their impact to the organisation'
//     },
//     {
//         key: 5,
//         task: 'Ensure the senior management approves the cybersecurity risks of the organisation'
//     },
//     {
//         key: 6,
//         task: 'Develop cybersecurity plans'
//     },
//     {
//         key: 7,
//         task: 'Develop relationships with cybersecurity-related authorities and communities'
//     },
//     {
//         key: 8,
//         task: 'Report cybersecurity incidents, risks, findings to the senior management'
//     },
//     {
//         key: 9,
//         task: 'Monitor advancement in cybersecurity'
//     },
//     {
//         key: 10,
//         task: 'Secure resources to implement the cybersecurity strategy'
//     },
//     {
//         key: 11,
//         task: 'Negotiate the cybersecurity budget with the senior management'
//     },
//     {
//         key: 12,
//         task: 'Ensure the organisation’s resiliency to cyber incidents'
//     },
//     {
//         key: 13,
//         task: 'Manage continuous capacity building within the organisation'
//     },
//     {
//         key: 14,
//         task: 'Review, plan and allocate appropriate cybersecurity resources'
//     }
// ];

// • Ensure compliance with and provide legal advice and guidance on data privacy and data protection standards, laws and regulations
// • Identify and document compliance gaps
// • Conduct privacy impact assessments and develop, maintain, communicate and train upon the privacy policies, procedures
// • Enforce and advocate organisation’s data privacy and protection program
// • Ensure that data owners, holders, controllers, processors, subjects, internal or external partners and entities are informed about their data protection rights, obligations and responsibilities
// • Act as a key contact point to handle queries and complaints regarding data processing
// • Assist in designing, implementing, auditing and compliance testing activities in order to ensure cybersecurity and privacy compliance
// • Monitor audits and data protection related training activities
// • Cooperate and share information with authorities and professional groups
// • Contribute to the development of the organisation’s cybersecurity strategy, policy and procedures
// • Develop and propose staff awareness training to achieve compliance and foster a culture of data protection within the organization
// • Manage legal aspects of information security responsibilities and third-party relations

const mainTasks = [
    {
        key: 1,
        task: 'Ensure compliance with and provide legal advice and guidance on data privacy and data protection standards, laws and regulations'
    },
    {
        key: 2,
        task: 'Identify and document compliance gaps'
    },
    {
        key: 3,
        task: 'Conduct privacy impact assessments and develop, maintain, communicate and train upon the privacy policies, procedures'
    },
    {
        key: 4,
        task: 'Enforce and advocate organisation’s data privacy and protection program'
    },
    {
        key: 5,
        task: 'Ensure that data owners, holders, controllers, processors, subjects, internal or external partners and entities are informed about their data protection rights, obligations and responsibilities'
    },
    {
        key: 6,
        task: 'Act as a key contact point to handle queries and complaints regarding data processing'
    },
    {
        key: 7,
        task: 'Assist in designing, implementing, auditing and compliance testing activities in order to ensure cybersecurity and privacy compliance'
    },
    {
        key: 8,
        task: 'Monitor audits and data protection related training activities'
    },
    {
        key: 9,
        task: 'Cooperate and share information with authorities and professional groups'
    },
    {
        key: 10,
        task: 'Contribute to the development of the organisation’s cybersecurity strategy, policy and procedures'
    },
    {
        key: 11,
        task: 'Develop and propose staff awareness training to achieve compliance and foster a culture of data protection within the organization'
    },
    {
        key: 12,
        task: 'Manage legal aspects of information security responsibilities and third-party relations'
    }
];

// const keySkills = [
//     {
//         key: 1,
//         skill: 'Assess and enhance an organisation’s cybersecurity posture'
//     },
//     {
//         key: 2,
//         skill: 'Analyse and implement cybersecurity policies, certifications, standards, methodologies and frameworks'
//     },
//     {
//         key: 3,
//         skill: 'Analyse and comply with cybersecurity-related laws, regulations and legislations'
//     },
//     {
//         key: 4,
//         skill: 'Implement cybersecurity recommendations and best practices'
//     },
//     {
//         key: 5,
//         skill: 'Manage cybersecurity resources'
//     },
//     {
//         key: 6,
//         skill: 'Develop, champion and lead the execution of a cybersecurity strategy'
//     },
//     {
//         key: 7,
//         skill: 'Influence an organisation’s cybersecurity culture'
//     },
//     {
//         key: 8,
//         skill: 'Design, apply, monitor and review Information Security Management System (ISMS) either directly or by leading its outsourcing'
//     },
//     {
//         key: 9,
//         skill: 'Review and enhance security documents, reports, SLAs and ensure the security objectives'
//     },
//     {
//         key: 10,
//         skill: 'Identify and solve cybersecurity-related issues'
//     },
//     {
//         key: 11,
//         skill: 'Establish a cybersecurity plan'
//     },
//     {
//         key: 12,
//         skill: 'Communicate, coordinate and cooperate with internal and external stakeholders'
//     },
//     {
//         key: 13,
//         skill: 'Anticipate required changes to the organisation’s information security strategy and formulate new plans'
//     },
//     {
//         key: 14,
//         skill: 'Define and apply maturity models for cybersecurity management'
//     },
//     {
//         key: 15,
//         skill: 'Anticipate cybersecurity threats, needs and upcoming challenges'
//     },
//     {
//         key: 16,
//         skill: 'Motivate and encourage people'
//     }
// ];
//

// • Comprehensive understanding of the business strategy, models and products and ability to factor into legal, regulatory and standards’ requirements
// • Carry out working-life practices of the data protection and privacy issues involved in the implementation of the organisational processes, finance and business strategy
// • Lead the development of appropriate cybersecurity and privacy policies and procedures that complement the business needs and legal requirements; further ensure its acceptance, comprehension and implementation and communicate it between the involved parties
// • Conduct, monitor and review privacy impact assessments using standards, frameworks, acknowledged methodologies and tools
// • Explain and communicate data protection and privacy topics to stakeholders and users
// • Understand, practice and adhere to ethical requirements and standards
// • Understand legal framework modifications implications to the organisation’s cybersecurity and data protection strategy and policies
// • Collaborate with other team members and colleagues

const keySkills = [
    {
        key: 1,
        skill: 'Comprehensive understanding of the business strategy, models and products and ability to factor into legal, regulatory and standards’ requirements'
    },
    {
        key: 2,
        skill: 'Carry out working-life practices of the data protection and privacy issues involved in the implementation of the organisational processes, finance and business strategy'
    },
    {
        key: 3,
        skill: 'Lead the development of appropriate cybersecurity and privacy policies and procedures that complement the business needs and legal requirements; further ensure its acceptance, comprehension and implementation and communicate it between the involved parties'
    },
    {
        key: 4,
        skill: 'Conduct, monitor and review privacy impact assessments using standards, frameworks, acknowledged methodologies and tools'
    },
    {
        key: 5,
        skill: 'Explain and communicate data protection and privacy topics to stakeholders and users'
    },
    {
        key: 6,
        skill: 'Understand, practice and adhere to ethical requirements and standards'
    },
    {
        key: 7,
        skill: 'Understand legal framework modifications implications to the organisation’s cybersecurity and data protection strategy and policies'
    },
    {
        key: 8,
        skill: 'Collaborate with other team members and colleagues'
    }
];

// const keyKnowledge = [
//     {
//         key: 1,
//         knowledge: 'Cybersecurity policies'
//     },
//     {
//         key: 2,
//         knowledge: 'Cybersecurity standards, methodologies and frameworks'
//     },
//     {
//         key: 3,
//         knowledge: 'Cybersecurity recommendations and best practices'
//     },
//     {
//         key: 4,
//         knowledge: 'Cybersecurity related laws, regulations and legislations'
//     },
//     {
//         key: 5,
//         knowledge: 'Cybersecurity-related certifications'
//     },
//     {
//         key: 6,
//         knowledge: 'Ethical cybersecurity organisation requirements'
//     },
//     {
//         key: 7,
//         knowledge: 'Cybersecurity maturity models'
//     },
//     {
//         key: 8,
//         knowledge: 'Cybersecurity procedures'
//     },
//     {
//         key: 9,
//         knowledge: 'Resource management'
//     },
//     {
//         key: 10,
//         knowledge: 'Management practices'
//     },
//     {
//         key: 11,
//         knowledge: 'Risk management standards, methodologies and frameworks'
//     }
// ];

// • Cybersecurity related laws, regulations and legislations
// • Cybersecurity standards, methodologies and frameworks
// • Cybersecurity policies
// • Legal, regulatory and legislative compliance requirements, recommendations and best practices
// • Privacy impact assessment standards, methodologies and frameworks

const keyKnowledge = [
    {
        key: 1,
        knowledge: 'Cybersecurity related laws, regulations and legislations'
    },
    {
        key: 2,
        knowledge: 'Cybersecurity standards, methodologies and frameworks'
    },
    {
        key: 3,
        knowledge: 'Cybersecurity policies'
    },
    {
        key: 4,
        knowledge: 'Legal, regulatory and legislative compliance requirements, recommendations and best practices'
    },
    {
        key: 5,
        knowledge: 'Privacy impact assessment standards, methodologies and frameworks'
    }
];

// const eCompetences = [
//     {
//         key: '1',
//         competence: 'A.7. Technology Trend Monitoring',
//         level: ['Level 4']
//     },
//     {
//         key: '2',
//         competence: 'D.1. Information Security Strategy Development',
//         level: ['Level 5']
//     },
//     {
//         key: '3',
//         competence: 'E.3. Risk Management',
//         level: ['Level 4']
//     },
//     {
//         key: '4',
//         competence: 'E.8. Information Security Management',
//         level: ['Level 4']
//     },
//     {
//         key: '5',
//         competence: 'E.9. Information Systems Governance',
//         level: ['Level 5']
//     }
// ];


const eCompetences = [
    {
        key: '1',
        competence: 'A.1. Information Systems and Business Strategy Alignment',
        level: ['Level 4']
    },
    {
        key: '2',
        competence: 'D.1. Information Security Strategy Development',
        level: ['Level 4']
    },
    {
        key: '3',
        competence: 'E.8. Information Security Management',
        level: ['Level 3']
    },
    {
        key: '4',
        competence: 'E.9. Information Systems Governance',
        level: ['Level 4']
    }
];

export const LPCData = {
    title,
    alternativeTitles,
    summaryStatement,
    mission,
    deliverables,
    mainTasks,
    keySkills,
    keyKnowledge,
    eCompetences
}

