import React from 'react'
import Axios from 'axios'
import { Col, Divider, Result, Popover, Row, Spin, Table, Typography } from 'antd'
import { LoadingOutlined, SaveTwoTone } from '@ant-design/icons'

import {Config as ConfigJAA} from '../job-ads-analyzer/config_section.js'
import { getSkillsCSVEdges, getSkillsCSVNodes, getProfilesCSVEdges, getProfilesCSVNodes, getProfilePairs } from '../job-ads-analyzer/utils.js'

const { Link, Title } = Typography


const JobAdsStatistics = () => {
  const [jobs, setJobs] = React.useState(null)
  const [rsSkillsGroup, setRsSkillsGroup] = React.useState(null)
  const [rsProfiles, setRsProfiles] = React.useState(null)
  const [jobStatistics, setJobStatistics] = React.useState(null)

  React.useEffect(() => {
    setJobs(null)
    setJobStatistics(null)
    setRsProfiles(null)
    setRsSkillsGroup(null)
    Axios.get(ConfigJAA.server.getJobs)
      .then((response) => {
        if (response && response.data.error === false) {
          const data = response.data.data
          setJobs(data.jobs.map(obj=> ({ ...obj, key: obj.id})))
          setJobStatistics(data.job_statistics.data)
          setRsProfiles(data.rs_profiles.map(obj=> ({ ...obj, key: obj.id})))
          setRsSkillsGroup(data.rs_skill_group.map(obj=> ({ ...obj, key: obj.id})))
        } else {
          console.log(response.data.message)
          setJobs(0)
          setJobStatistics(0)
          setRsProfiles(0)
          setRsSkillsGroup(0)
        }
      })
      .catch((error) => {
        console.log("Server is unavailable")
        console.log(error)
        setJobs(0)
        setJobStatistics(0)
        setRsProfiles(0)
        setRsSkillsGroup(0)
      })

  }, [])

  const dataStatus = React.useMemo(() => {
    if (
      jobs === null &&
      jobStatistics === null &&
      rsSkillsGroup === null &&      
      rsProfiles === null
    ) {
      return "loading"
    } else if (
      Array.isArray(jobs) &&
      typeof jobStatistics === 'object' && jobStatistics !== null &&
      Array.isArray(rsSkillsGroup) &&
      Array.isArray(rsProfiles)
    ) {
      return "ok"
    } else {
      return "error"
    }
  }, [jobs, jobStatistics, rsSkillsGroup, rsProfiles])

  // ---- exoport to CSV and JSON ----

  function exportToCSV (data, fileName) {
    if (data.length === 0) {
      return
    }

    const csvRows = []
    // Dynamicky generujeme záhlaví CSV na základě klíčů prvního objektu
    const headers = Object.keys(data[0])
    csvRows.push(headers.join(";"))

    // Procházení dat a přidání řádků CSV
    data.forEach((row) => {
      const values = headers.map(header => row[header])
      csvRows.push(values.join(";"))
    })

    // Vytvoření obsahu CSV jako Blob
    const csvString = csvRows.join("\n")
    const blob = new Blob([csvString], { type: "text/csv" })

    // Vytvoření odkazu ke stažení
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.setAttribute("href", url)
    a.setAttribute("download", `${fileName}.csv`)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a) // Odstranění odkazu po kliknutí
  }

  function exportToJSON (data, fileName) {
    if (data.length === 0) {
      return
    }

    // Převod dat na JSON řetězec
    const jsonString = JSON.stringify(data, null, 2)
    const blob = new Blob([jsonString], { type: "application/json" })

    // Vytvoření odkazu ke stažení
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.setAttribute("href", url)
    a.setAttribute("download", `${fileName}.json`)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a) // Odstranění odkazu po kliknutí
  }

  // ---- profiles data computation ----  
  
  const profilePairs = React.useMemo(() => {
    return getProfilePairs(jobs)
  }, [jobs])
     
  const profileMatchData = dataStatus !== "ok" ? [] : Array.from(profilePairs.keys()).map(profile => {
    const pairings = profilePairs.get(profile)
    return {
      // key: profile,
      profile,
      ...Array.from(profilePairs.keys()).reduce((acc, otherProfile) => {
        acc[otherProfile] = pairings[otherProfile] || 0
        return acc;
      }, {}),
    }
  }).filter(row => row.profile !== "")

  // ---- profiles tables data ----

  const profilesCSVNodesData = React.useMemo(() => {
    if (dataStatus !== "ok" || !(profilePairs instanceof Map)) return []
    return getProfilesCSVNodes(profilePairs, jobs)
  }, [dataStatus, profilePairs, jobs])   


  const profilesCSVEdgesData = React.useMemo(() => {
    if (dataStatus !== "ok" || !profilesCSVNodesData) return []
    return getProfilesCSVEdges(profilePairs, rsProfiles, profilesCSVNodesData)
  }, [dataStatus, rsProfiles, profilePairs, profilesCSVNodesData])

  // ---- skills tables data ----

  const skillsCSVNodesData = React.useMemo(() => {
    if (dataStatus !== "ok") return []
    return getSkillsCSVNodes(Object.values(jobStatistics).flat(), rsSkillsGroup)
  }, [jobStatistics, rsSkillsGroup, dataStatus])

  const skillsCSVEdgesData = React.useMemo(() => {
    if (dataStatus !== "ok") return []
    return getSkillsCSVEdges(Object.values(jobStatistics).flat(), rsSkillsGroup)
  }, [jobStatistics, rsSkillsGroup, dataStatus])
  
  // ---- table columns ----

  const profileMatchColumns = [
    {
      title: 'Profile',
      dataIndex: 'profile',
      key: 'profile',
      align: "center",
      className: "header-column",
      render: value => value === "" ? "Empty" : value
    },
    ...Array.from(profilePairs.keys()).filter(profile => profile !== "").map(profile => ({
      title: profile === "" ? "Empty" : profile,
      dataIndex: profile,
      key: profile,
      align: "center",
      className: "maybe-gray-cell",
      render: (value, row) => {
        const className = row.profile === profile ? 'gray' : '';
        return <span key={profile} className={className}>{className ? '' : row[profile]}</span>;
      }
    }))
  ]
/*
  const skillConnectionsColumns = [
    {
      title: 'Skill Group',
      dataIndex: 'skillId',
      key: 'skillId',
      align: "center",
      className: "header-column",
      render: value => value === "" ? "Empty" : value
    },
    ...Array.from(rsSkillsGroup.values()).map(skill => ({
      title: <div className="rotated-header">{skill.name === "" ? "Empty" : skill.name}</div>,
      dataIndex: skill.id,
      key: skill.id,
      align: "center",
      className: "maybe-gray-cell",
    }))
  ]
*/
  const csvNodesColumn = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'label',
      dataIndex: 'label',
      key: 'label',
      sorter:  (a, b) => a.label.localeCompare(b.label),
    },
    {
      title: 'weight',
      dataIndex: 'weight',
      key: 'weight',
      sorter:  (a, b) => a.weight - b.weight,
    }
  ]

  const csvEdgesColumn = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'type',
      dataIndex: 'type',
      key: 'type',
    },      
    {
      title: 'source',
      dataIndex: 'source',
      key: 'source',
      sorter:  (a, b) => a.source - b.source,
    },
    {
      title: 'target',
      dataIndex: 'target',
      key: 'target',
      sorter:  (a, b) => a.target - b.target,
    },
    {
      title: 'weight',
      dataIndex: 'weight',
      key: 'weight',
      sorter:  (a, b) => a.weight - b.weight,
    }
  ]

  // ---- return ----

  return (
    <div id="job-ads-statistics">
      <span><Title className="pageTitle">Job Ads Statistics</Title></span>
      {
        dataStatus === "loading" ? (
          <div className="vertCenter"><Spin tip="Loading..." indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /></div>
        ) : dataStatus === "error" ? (
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
          />
        ) : (
          <>
            <Divider className="first"><Title level={4}>Profiles Matched in the Ads</Title></Divider>

            <Row gutter={32}>
              <Col span={12}>

                <div className="divider-small">
                  <Title level={5}><>
                    <Link href="https://gephi.org/" target="_blank">Gephi</Link> - Nodes
                    &nbsp;
                    <Popover title="Save to a file" content={
                      <div>
                        --- all ---<br/>
                        <Link onClick={()=>exportToCSV(profilesCSVNodesData, "profiles - nodes")}>CSV</Link><br/>
                        <Link onClick={()=>exportToJSON(profilesCSVNodesData, "profiles - nodes")}>JSON</Link>
                      </div>
                    }>
                      <SaveTwoTone />
                    </Popover>
                  </></Title>
                </div>

                <Table
                  columns={csvNodesColumn}
                  dataSource={profilesCSVNodesData}
                  pagination={false}
                  size="small"
                  bordered
                  className="csv-data-table"
                  rowKey="id"
                />

              </Col>
              <Col span={12}>

                <div className="divider-small">
                  <Title level={5}><>
                    <Link href="https://gephi.org/" target="_blank">Gephi</Link> - Edges
                    &nbsp;
                    <Popover title="Save to a file" content={
                      <div>
                        --- all ---<br/>
                        <Link onClick={()=>exportToCSV(profilesCSVEdgesData, "profiles - edges")}>CSV</Link><br/>
                        <Link onClick={()=>exportToJSON(profilesCSVEdgesData, "profiles - edges")}>JSON</Link>
                        <br/>--- filtered (CSV) ---<br/>
                        {profilesCSVNodesData.map((profile, index) => <React.Fragment key={index}><Link onClick={()=>exportToCSV(profilesCSVEdgesData.filter(e => e.source === profile.id || e.target === profile.id), "profiles - edges - " + profile.id + " - " + profile.label)}>{profile.id + " - " + profile.label}</Link><br/></React.Fragment> )}
                      </div>
                    }>
                      <SaveTwoTone />
                    </Popover>
                  </></Title>
                </div>                    
                
                <Table
                  columns={csvEdgesColumn}
                  dataSource={profilesCSVEdgesData}
                  pagination={false}
                  size="small"
                  bordered
                  className="csv-data-table"
                  rowKey="id"
                />

              </Col>
            </Row>

            <Table
              columns={profileMatchColumns}
              dataSource={profileMatchData}
              pagination={false}
              size="small"
              bordered
              className="profile-match-table"
              rowKey="profile"
            />

            <Divider><Title level={4}>Skills Connection in the Ads</Title></Divider>
{/*
            <div className="vertCenter">
              <Row><Col>
                <Progress
                  type="dashboard"
                  strokeColor={{
                    '0%': '#108ee9',
                    '100%': '#87d068',
                  }}
                  percent={percent.num}
                />
              </Col></Row>
              <Row><Col>{percent.message}</Col></Row>
            </div>
*/}             
            <Row gutter={32}>
              <Col span={12}>

                <div className="divider-small">
                  <Title level={5}><>
                    <Link href="https://gephi.org/" target="_blank">Gephi</Link> - Nodes
                    &nbsp;
                    <Popover title="Save to a file" content={
                      <div>
                        --- all ---<br/>
                        <Link onClick={()=>exportToCSV(skillsCSVNodesData, "skills - nodes")}>CSV</Link><br/>
                        <Link onClick={()=>exportToJSON(skillsCSVNodesData, "skills - nodes")}>JSON</Link>
                      </div>
                    }>
                      <SaveTwoTone />
                    </Popover>
                  </></Title>
                </div>

                <Table
                  columns={csvNodesColumn}
                  dataSource={skillsCSVNodesData}
                  pagination={false}
                  size="small"
                  bordered
                  className="csv-data-table"
                  rowKey="id"
                />

              </Col>
              <Col span={12}>

                <div className="divider-small">
                  <Title level={5}><>
                    <Link href="https://gephi.org/" target="_blank">Gephi</Link> - Edges
                    &nbsp;
                    <Popover title="Save to a file" content={
                      <div>
                        --- all ---<br/>
                        <Link onClick={()=>exportToCSV(skillsCSVEdgesData, "skills - edges")}>CSV</Link><br/>
                        <Link onClick={()=>exportToJSON(skillsCSVEdgesData, "skills - edges")}>JSON</Link>
                        <br/>--- filtered (CSV) ---<br/>
                        {rsSkillsGroup.map((skill, index) => <React.Fragment key={index}><Link onClick={()=>exportToCSV(skillsCSVEdgesData.filter(e => e.source === skill.id || e.target === skill.id), "skills - edges - " + skill.id + " - " + skill.name)}>{skill.id + " - " + skill.name}</Link><br/></React.Fragment> )}
                      </div>
                    }>
                      <SaveTwoTone />
                    </Popover>
                  </></Title>
                </div>                    
                
                <Table
                  columns={csvEdgesColumn}
                  dataSource={skillsCSVEdgesData}
                  pagination={false}
                  size="small"
                  bordered
                  className="csv-data-table"
                  rowKey="id"
                />

              </Col>
            </Row>          
          </>
        )
      }
    </div>
  )
}

export default JobAdsStatistics