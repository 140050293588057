import React from 'react'
import {Config} from './config_section.js'
import Axios from 'axios'
import { Button, Divider, Form, Input, Popover, Spin, Table, Tooltip, Typography  } from 'antd'
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons'

const { TextArea } = Input
const { Text, Title } = Typography


const CreateYourAd = ({ showMessage, rsSkillsGroup, rsProfiles }) => {

  // state hook
  // null = empty; 0 = error; false = loading; [] = empty data; [...] = data
  const [profileCoverage, setProfileCoverage] = React.useState(null)
  const [genderBalance, setGenderBalance] = React.useState(null)
  const [neutralDescription, setNeutralDescription] = React.useState("null")
  const [form3] = Form.useForm()

  function processPrediction(values) {
    showMessage({type: 'loading', content: "Loading...", key: 'updatable', duration: 0})
    setProfileCoverage(false)
    Axios.post( Config.server.processDescription, values, {headers: { 'Content-Type': 'application/json' }})
      .then((response) => {
        showMessage({type: 'loading', content: "Loading...", key: 'updatable', duration: 0.2})
        if (response && response.data.error === false){
          showMessage({type: 'success', content: "Done."})
          setProfileCoverage({
            prediction: response.data.data.prediction.sort((a,b) => {
              if (rsSkillsGroup.find(e=>e.id === a.skillId).name > rsSkillsGroup.find(e=>e.id === b.skillId).name) return 1
        	    if (rsSkillsGroup.find(e=>e.id === a.skillId).name < rsSkillsGroup.find(e=>e.id === b.skillId).name) return -1
              else return 0
            }),
            profiles: calculateProfileCoverage(response.data.data.prediction)
          })
        } else {
          console.log(response.data.message)
          showMessage({type: 'error', content: "Something went wrong!"})
          setProfileCoverage(null)
        }
      })
      .catch((error) => {
        setProfileCoverage(null)
        console.log(error)
        showMessage({type: 'loading', content: "Loading...", key: 'updatable', duration: 0.2})
        showMessage({type: 'error', content: "Something went wrong!"})
      })
  }

  function analyzeGenderBalance() {
    form3.validateFields()
    .then((values) => {
      showMessage({type: 'loading', content: "Loading...", key: 'updatable', duration: 0})
      setGenderBalance(false)
      let userDescription = form3.getFieldValue("description")
      Axios.post( Config.server.analyzeGenderBalance, {description: userDescription, type: 'analyze'}, {headers: { 'Content-Type': 'application/json' }})
        .then((response) => {
          showMessage({type: 'loading', content: "Loading...", key: 'updatable', duration: 0.2})
          if (response && response.data.error === false){
            showMessage({type: 'success', content: "Gender balance analysis done."})
            setGenderBalance(response.data.data)
          } else {
            console.log(response.data.message)
            showMessage({type: 'error', content: "Something went wrong!"})
            setGenderBalance(null)
          }
        })
        .catch((error) => {
          setGenderBalance(null)
          console.log(error)
          showMessage({type: 'loading', content: "Loading...", key: 'updatable', duration: 0.2})
          showMessage({type: 'error', content: "Something went wrong!"})
        })
    })
  }

  function makeNeutralDescription() {
    form3.validateFields()
    .then((values) => {
      showMessage({type: 'loading', content: "Loading...", key: 'updatable', duration: 0})
      setNeutralDescription(false)
      let userDescription = form3.getFieldValue("description")
      Axios.post( Config.server.analyzeGenderBalance, {description: userDescription, type: 'make_neutral'}, {headers: { 'Content-Type': 'application/json' }})
        .then((response) => {
          showMessage({type: 'loading', content: "Loading...", key: 'updatable', duration: 0.2})
          if (response && response.data.error === false){
            showMessage({type: 'success', content: "Gender neutral description done."})
            setNeutralDescription(response.data.data)
          } else {
            console.log(response.data.message)
            showMessage({type: 'error', content: "Something went wrong!"})
            setNeutralDescription(null)
          }
        })
        .catch((error) => {
          setNeutralDescription(null)
          console.log(error)
          showMessage({type: 'loading', content: "Loading...", key: 'updatable', duration: 0.2})
          showMessage({type: 'error', content: "Something went wrong!"})
        })
    })
  }

  function calculateProfileCoverage(input) {
    let userSkills = input.map(item => item.skillId)
    let outputProfiles = rsProfiles.map((profile, i) => {
      profile.foundUserSkills = []
      userSkills.forEach(userSkill => {
        if (profile.skills_group.includes(userSkill)) profile.foundUserSkills.push(userSkill)
      })
      profile.userCoverage = round((profile.foundUserSkills.length/profile.skills_group.length)*100, 0)
      return profile
    })
    return outputProfiles
  }

  function round(num, precision) {
    return Number(Math.round(num + "e+" + precision) + "e-" + precision)
  }

  function changeFormDescription(newValue) {
    if (newValue) form3.setFieldsValue({description: newValue})
    setProfileCoverage(null)
    setGenderBalance(null)
    setNeutralDescription(null)
  }

  const predictionColumns = [
    {
      title: 'Skill Group',
      dataIndex: 'skillId',
      key: 'skillId',
      render: value => rsSkillsGroup.find(e => e.id === value).name
    },
  ]

  const profilesColumns = [
    {
      title: 'Profile',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Coverage',
      dataIndex: 'userCoverage',
      key: 'userCoverage',
      align: 'right',
      width: "90px",
      render:  (_, record) => {
        let includedSkills = record.foundUserSkills.map(skillId => <li key={skillId}>{rsSkillsGroup.find(e => e.id === skillId).name}</li>)
        let missingSkills = record.skills_group.filter(skillId => !record.foundUserSkills.includes(skillId)).map(skillId => <li key={skillId}>{rsSkillsGroup.find(e => e.id === skillId).name}</li>)
        return (
          <Popover className="coverage-number" content={<div className="skills-popover" >
            <Title level={5}>Included Skills</Title>
            <ul>
              {includedSkills.length ? includedSkills : <span className="empty">none</span>}
            </ul>
            <Title level={5}>Missing Skills</Title>
            <ul>
              {missingSkills.length ? missingSkills : <span className="empty">none</span>}
            </ul>
          </div>}><Button type="link">{record.userCoverage} %</Button>
          </Popover>
        )
      }
    }
  ]


  return (
    <section className="create-your-ad results">
      <span><Title className="pageTitle">Create Your Own Ad</Title></span>
      <p>Fill in a description of your job and analyze it.</p>

      <Form form={form3} layout="horizontal" name="descr" labelWrap colon={false} onFinish={processPrediction} >

        <Form.Item name={"description"} rules={[{required: true, message: 'Please fill in the description!'}]} >
          <TextArea placeholder="Your job description..." rows={8} onChange={()=>changeFormDescription()}/>
        </Form.Item>

        {genderBalance !== null ? ( <div className="gender-balance-result">
          {genderBalance === false ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
          ) : (
            <>
              <CheckCircleOutlined className="successIcon"/> Your job description
              {
                genderBalance.includes("male") ? " is "+genderBalance+" oriented." :
                genderBalance.includes("neutral") ? " has neutral gender orientation." :
                " has unknown gender orientation."
              }
              {genderBalance.includes("male") ? ( <div className="neutral-description">
                {neutralDescription === false ? (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                ) : neutralDescription === null || neutralDescription === 0 ? (
                  <Button size="small" onClick={makeNeutralDescription} loading={neutralDescription === false} >Make it neutral</Button>
                ) : (
                  <div className="suggested-text">
                    <p><Text underline>Suggested gender neutral text:</Text><br/>
                    <Text italic>{neutralDescription}</Text></p>
                    <Button size="small" type="primary" onClick={()=>changeFormDescription(neutralDescription)}>Accept changes</Button>
                  </div>
                )}
              </div>) : null}
            </>
          )}
        </div>) : null}

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={profileCoverage}>
            Analyze
          </Button>
          <Tooltip title="In development">
            <Button disabled >
              Search
            </Button>
          </Tooltip>
          <Button onClick={analyzeGenderBalance} disabled={genderBalance}>
            BIAS
          </Button>
        </Form.Item>

      </Form>

      {profileCoverage ? (
        <div>

          <Divider><Title level={4}>List of Found Skills</Title></Divider>

          <Table size="small" pagination={false} columns={predictionColumns} dataSource={profileCoverage.prediction} className="resultTable profiles" rowKey="rank" />

          <Divider><Title level={4}>List of Enisa Profiles with Match</Title></Divider>

          <Table size="small" pagination={false} columns={profilesColumns} dataSource={profileCoverage.profiles} className="resultTable profiles" rowKey="profile"/>

        </div>
      ) : null}

    </section>
  )
}

export default CreateYourAd
